import React from "react";
import Toast from "../../UiComponent/Toast/Toast";
import apiServices from "../../services/RequestHandler";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import { useNavigate } from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import ForgotPassword from "./ForgotPassword";
import { defaultLabelColors } from "../../utils/constants";
import InputField from "../../UiComponent/TextFeild";
import {
  East,
  LockOpen,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CustomButton from "../../UiComponent/CustomButton";
import {
  LoginWithAppleIcon,
  LoginWithFacebookIcon,
  LoginWithGoogleIcon,
} from "../../assets/images/FrankIcons";
import { strings } from "../../Store/Localization";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";

function AuthAction(props: any) {
  const { handleCloseLoginPage } = props;
  const selector = useSelector((state: RootState) => state.mainApp);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      username: null,
      password: null,
      viewPassword: false,
      isForgotPassword: false,
    }
  );

  React.useEffect(() => {
    setState({});
  }, [selector.lang]);
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleLogin = async () => {
    try {
      if (!state?.username) {
        Toast.Error(strings?.email_error);
        return;
      }
      if (!state?.password) {
        Toast.Error(strings?.password_error);
        return;
      }
      let obj = {
        username: state?.username,
        password: state?.password,
      };
      let resp = await apiServices.postFromApi("c2c/login", obj, "");
      if (resp?.status === 201) {
        // Toast.showSuccess("Logged in succesfully");
        setState({ step: resp?.step + 1, username: "", password: "" });
        saveDataToLocalStorage("token", resp?.data?.token);
        saveDataToLocalStorage("user", resp?.data);
        let ship_item = getDataFromLocalStorage("shipment");
        if (ship_item) {
          handleCloseLoginPage();
          return;
        }

        if (props?.shouldNavigate === false) {
          handleCloseLoginPage();
          return;
        }
        navigate("/");
        return;
      } else {
        Toast.Error(
          resp?.data?.error ??
            resp?.error?.error ??
            resp?.data?.isEmail ??
            resp?.data?.isNotEmpty ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };
  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{ padding: "50px" }}
        className="display-flex"
      >
        {state?.isForgotPassword ? (
          <ForgotPassword
            {...state}
            {...props}
            handleBack={() => setState({ isForgotPassword: false })}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{ display: "flex" }}
            >
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    CONNEXION
                  </p>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Insérez votre email ainsi que votre mot de passe
                  </p>
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={strings?.user_or_email}
                    onchange={onInputChange}
                    startIcon={<MailOutline />}
                    input_value={state?.username}
                    input_name={"username"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                  />
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={strings?.password}
                    input_type={
                      state?.viewPassword === false ? "password" : "text"
                    }
                    startIcon={<LockOpen />}
                    input_value={state?.password}
                    onchange={onInputChange}
                    input_name={"password"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                    endIicon={
                      state?.viewPassword ? (
                        <Visibility
                          onClick={() => {
                            setState({
                              viewPassword: !state?.viewPassword,
                            });
                          }}
                          style={{ color: "gray", cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => {
                            setState({
                              viewPassword: !state?.viewPassword,
                            });
                          }}
                          style={{ color: "gray", cursor: "pointer" }}
                        />
                      )
                    }
                  />
                  <p
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    J’ai oublié mon{" "}
                    <span
                      style={{ fontWeight: "700" }}
                      onClick={() => {
                        setState({ isForgotPassword: true });
                      }}
                    >
                      {" "}
                      mot de passe
                    </span>
                  </p>
                </Grid>

                <Grid xs={12} item className="display-flex">
                  <CustomButton
                    style={{
                      width: "260px",
                    }}
                    onClick={handleLogin}
                    classesNames={"contained_button_two"}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "10px",
                            padding: "8px 0px 8px 0px",
                            fontSize: "16px",
                          }}
                        >
                          ME CONNECTER
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              padding: 4,
                              fontSize: 18,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid xs={12} item>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Pas de compte ?{" "}
                    <span
                      style={{ fontWeight: "700", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/sign-up");
                      }}
                    >
                      Créer un compte
                    </span>
                  </p>
                </Grid>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    color="#000"
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                      textAlign: "center",
                    }}
                  >
                    Ou
                  </Divider>
                </Grid>
                <Grid xs={12} item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "20px",
                      paddingTop: 10,
                    }}
                  >
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <LoginWithGoogleIcon />
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <LoginWithFacebookIcon />
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <LoginWithAppleIcon />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AuthAction;
