import { strings } from "../Store/Localization";

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const numToWordObj = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  30: "Thirty",
  40: "Forty",
  50: "Fifty",
  60: "Sixty",
  70: "Seventy",
  80: "Eighty",
  90: "Ninety",
} as any;

const placement = {
  100: " Hundred",
  1000: " Thousand",
  1000000: " Million",
  1000000000000: " Trillion",
};

export const convertNumToWord = (num: any): any => {
  const limiter = (val: any) => (num < val) as any;
  const limiterIndex = Object.keys(placement).findIndex(limiter) as any;
  const limiterKey = Object.keys(placement)[limiterIndex] as any;
  const limiterVal = Object.values(placement)[limiterIndex - 1] as any;
  const limiterMod = Object.keys(placement)[limiterIndex - 1] as any;

  if (numToWordObj[num]) {
    return numToWordObj[num];
  }

  if (num < 100) {
    const whole = Math.floor(num / 10) * 10;
    const part = num % 10;
    return numToWordObj[whole] + " " + numToWordObj[part];
  }

  if (num < limiterKey) {
    const whole = Math.floor(num / limiterMod);
    const part = num % limiterMod;
    if (part === 0) {
      return convertNumToWord(whole) + limiterVal;
    }
    return (
      convertNumToWord(whole) + limiterVal + " and " + convertNumToWord(part)
    );
  }
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve(null);
  });

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): string => {
  const R = 6371; // Radius of the earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return Number(distance).toFixed(2);
};
const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

export const numberFormatWithoutCurrency = (value: number, lang = "en-FR") =>
  Intl.NumberFormat(lang, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

export const numberFormatWithoutCurrencyFixed = (
  value: number,
  lang = "en-FR"
) => Intl.NumberFormat(lang).format(value);

export const numberFormatWithoutCurrencyFixedOne = (
  value: number,
  lang = "en-FR"
) =>
  Intl.NumberFormat(lang, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  }).format(value);

export const handleLocalize = (data: { en: string; fr: string }) => {
  try {
    const selcted_lang = strings.getLanguage();
    if (selcted_lang === "fr") {
      if (data?.fr) {
        return data?.fr;
      }
      return data?.en;
    } else if (selcted_lang === "en") {
      if (data?.en) {
        return data?.en;
      }
      return data?.fr;
    }
    return data?.fr;
  } catch (error) {
    console.log(error);
    return "N / A";
  }
};

interface Category {
  id: string;
  parent_id: string | null;
  category_name: {
    en: string;
    fr: string;
  };
  children?: Category[];
}

export const buildHierarchy = (categories: Category[]): Category[] => {
  const categoryMap: { [key: string]: Category } = {};
  // Create a map of categories by ID
  categories.forEach((category) => {
    categoryMap[category.id] = { ...category, children: [] };
  });
  const rootCategories: Category[] = [];
  // Build the hierarchy
  categories.forEach((category) => {
    if (category.parent_id) {
      // If the category has a parent, add it to the parent's children array
      const parent = categoryMap[category.parent_id];
      if (parent) {
        parent.children?.push(categoryMap[category.id]);
      }
    } else {
      // If the category doesn't have a parent, it's a root category
      rootCategories.push(categoryMap[category.id]);
    }
  });
  return rootCategories;
};
export const getCategoryPath = (categoryId: number, data: Category[]) => {
  const findPath = (id: any, nodes: any, currentPath: any): any => {
    for (let node of nodes) {
      let newPath = [...currentPath, node.category_name];
      if (node.id === id) {
        return newPath;
      }
      if (node.children) {
        let result = findPath(id, node.children, newPath);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  let path = findPath(categoryId, data, []);
  return path ? path : null;
};

export const throttle = (callback: Function, limit: number) => {
  let lastFunc: NodeJS.Timeout | null;
  let lastRan: number | undefined;

  return function (this: any, ...args: any[]) {
    const context = this;

    if (!lastRan) {
      callback.apply(context, args);
      lastRan = Date.now();
    } else {
      if (lastFunc) clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - (lastRan as number) >= limit) {
          callback.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - (lastRan as number)));
    }
  };
};
