import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import { ArrowBackIosNew } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import apiServices from "../../services/RequestHandler";
import noImageFile from "../../assets/images/FrankIcons/noImageAvailable.png";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import { defaultLabelColors } from "../../utils/constants";
import { strings } from "../../Store/Localization";
import { Rating } from "react-simple-star-rating";
import CustomFooter from "../../UiComponent/CustomFooter";
import { LocationPinIcon, MapHomeIcon } from "../../assets/images/FrankIcons";
import moment from "moment";
import OTPInput from "react-otp-input";
import Toast from "../../UiComponent/Toast/Toast";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

function JobDetails(props: any) {
  const [otp, setOtp] = React.useState("");
  const selector = useSelector((state: RootState) => state.mainApp);
  const [selectedImage, setSelectedImage] = useState("");
  const param = useParams();
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      showPickupOtpScreen: false,
    }
  );

  useEffect(() => {
    handleGetProdInfo(param?.id);
  }, []);

  const handleGetProdInfo = async (job_id: any) => {
    try {
      const resp = await apiServices.getFromApi(`c2c-shipments/${job_id}`, "");

      setState({
        job_details: resp?.data,
      });

      setSelectedImage(
        resp?.data?.shipment_items[0]?.prod_images[0] ?? noImageFile
      );
      //   handleGetShopInfo(job_details?.shop_id);
    } catch (error) {
      console.log(error);
    }
  };
  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };

  const handleComplete = (otpValue: any) => {
    if (otpValue?.length === 4) {
      if (state?.job_details?.ship_status === "Accepted") {
        handlePickOrder(otpValue);
        return;
      }
      if (state?.job_details?.ship_status === "Picked") {
        handleDeliverOrder(otpValue);
        return;
      }
    }
  };
  const handlePickOrder = async (otp: any) => {
    try {
      let obj = {
        ship_id: Number(state?.job_details.id),
        ship_status: "Picked",
        ship_pickup_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showPickupOtpScreen: false });
        handleGetProdInfo(param?.id);
        setOtp("");
        return;
      } else {
        Toast.Error(strings?.pickup_otp_for_transporter ?? resp?.error);
      }
    } catch (error) {}
  };
  const handleDeliverOrder = async (otp: any) => {
    try {
      let obj = {
        ship_id: Number(state?.job_details?.id),
        ship_status: "Deliver",
        ship_deliver_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showPickupOtpScreen: false });
        handleGetProdInfo(param?.id);
        setOtp("");
        return;
      } else {
        Toast.Error(
          resp?.data?.error ??
            resp?.error?.error ??
            resp?.data?.isEmail ??
            resp?.data?.isNotEmpty ??
            resp?.message ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };

  const { job_details } = state;
  return (
    <div className="web-screen">
      <div style={{ background: "#f9c9b3", minHeight: "100vh" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />{" "}
              Récapitulatif de la commande . {"À transporter "}.{" "}
              <span style={{ fontWeight: "700" }}>
                {" "}
                {job_details
                  ? handleLocalize({
                      en: job_details?.shipment_items[0]?.item_name_en?.en,
                      fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                    }) ?? job_details?.shipment_items[0]?.prod_name
                  : ""}
              </span>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              background: "#fff",
              borderRadius: "16px",
              padding: "20px",
              gap: 20,
            }}
          >
            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  position="relative"
                  sx={{ borderRadius: "32px 32px 0px 0px" }}
                >
                  <div style={{ height: "480px" }} className="display-flex">
                    <CardMedia
                      component="img"
                      image={
                        selectedImage ?? "https://via.placeholder.com/300x200"
                      }
                      alt="Product Image"
                      sx={{
                        objectFit: "contain",
                        width: "98%",
                        height: "460px",
                        borderRadius: "32px 32px 0px 0px",
                      }}
                    />
                  </div>
                </Box>

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#3B3A3A"
                        sx={{
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {handleLocalize({
                          en: job_details?.shipment_items[0]?.item_name_en?.en,
                          fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                        }) ??
                          job_details?.shipment_items[0]?.prod_name ??
                          ""}
                        <span> </span>
                      </Typography>
                    </div>

                    <div
                      style={{
                        color: defaultLabelColors.main_orange,
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                        gap: "10px",
                        marginRight:'10px'
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          fontFamily: "Raleway-Variable",
                          color: "#000",
                          fontWeight: 700,
                        }}
                      >
                        Tarif accepté
                      </span>
                      {numberFormatWithoutCurrency(
                        job_details?.agree_ship_price
                          ? job_details?.agree_ship_price
                          : job_details?.ship_price
                      ) ?? ""}&nbsp;€
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {job_details?.shipment_items[0]?.prod_images?.map(
                      (item: any) => {
                        return (
                          <div
                            className="display-flex"
                            style={{
                              height: "116px",
                              width: "116px",
                              cursor: "pointer",
                              borderRadius: "12px",
                              border: `2px solid ${
                                selectedImage === item
                                  ? defaultLabelColors.main_orange
                                  : "grey"
                              } `,
                            }}
                            onClick={() => {
                              setSelectedImage(item);
                            }}
                          >
                            <img
                              src={item}
                              alt="prod-img"
                              style={{ objectFit: "contain", height: "110px" }}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {strings?.seller_details}
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: 75,
                      height: 75,
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: 3,
                      border: "1px solid #ee6931",
                    }}
                  >
                    <img
                      src={
                        job_details?.userDetails?.profile_pic ??
                        "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                      }
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>{" "}
                  <p />
                  <p>
                    {job_details?.user_details?.cus_name ?? ""}
                    <br />
                    <Rating
                      initialValue={job_details?.user_details?.cus_ratings}
                      size={30}
                    />
                  </p>
                </p>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 15,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <MapHomeIcon
                      height={20}
                      width={20}
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu de départ
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {job_details?.pickup?.address}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop: "10px",
                  paddingLeft: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 13,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <LocationPinIcon
                      height={23}
                      width={23}
                      fill={defaultLabelColors.main_orange}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu d’arrivé
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {job_details?.dropoff?.address ?? ""}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    Destinataire
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <a href={`tel:${job_details?.acc_phone}`}>
                      {job_details?.acc_phone}
                    </a>
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    Taille de mon colis
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <span style={{ fontWeight: "600", color: "#000" }}>
                      Moyen
                    </span>{" "}
                    - {job_details?.shipment_items[0]?.prod_desc}
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    Description
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {handleLocalize({
                      en: job_details?.shipment_items[0]?.item_name_en?.en,
                      fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                    }) ??
                      job_details?.shipment_items[0]?.prod_name ??
                      ""}{" "}
                    <br />
                    {job_details?.shipment_items[0]?.prod_length ?? 0} x{" "}
                    {job_details?.shipment_items[0]?.prod_height ?? 0} x{" "}
                    {job_details?.shipment_items[0]?.prod_width ?? 0}
                    <br />
                    Très bon état
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    Votre trajet
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Montant à recevoir &nbsp; &nbsp; &nbsp;{" "}
                    {job_details?.agree_ship_price
                      ? job_details?.agree_ship_price
                      : job_details?.ship_price}{" "}
                    €
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Frais de service &nbsp; &nbsp; &nbsp;{" "}
                    {numberFormatWithoutCurrencyFixed(
                      job_details?.service_charges
                    )}{" "}
                    €
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "20px",
                      fontWeight: "700",
                      color: defaultLabelColors.main_orange,
                    }}
                  >
                    Total à recevoir &nbsp; &nbsp; &nbsp;{" "}
                    {job_details?.agree_ship_price
                      ? numberFormatWithoutCurrencyFixed(
                          job_details?.agree_ship_price -
                            job_details?.service_charges
                        )
                      : numberFormatWithoutCurrencyFixed(
                          job_details?.ship_price - job_details?.service_charges
                        )}{" "}
                    €
                  </p>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Grid
                container
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "24px",
                  padding: 10,
                }}
              >
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    01
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Mise en ligne <br />
                      {job_details?.order_statuses[0]?.created_at
                        ? moment(
                            job_details?.order_statuses[0]?.created_at
                          ).format("DD/MM/YYYY HH:MM")
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    02
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Accepté <br />
                      {job_details?.order_statuses[1]?.created_at
                        ? moment(
                            job_details?.order_statuses[1]?.created_at
                          ).format("DD/MM/YYYY HH:MM")
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    03
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Expédié <br />
                      {job_details?.order_statuses[2]?.created_at
                        ? moment(
                            job_details?.order_statuses[2]?.created_at
                          ).format("DD/MM/YYYY HH:MM")
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    04
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Livrer
                      <br />
                      {job_details?.order_statuses[3]?.created_at
                        ? moment(
                            job_details?.order_statuses[3]?.created_at
                          ).format("DD/MM/YYYY HH:MM")
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={3} item className="display-flex">
                  {job_details?.ship_status === "Accepted" &&
                  !job_details?.ship_pickup_otp ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        padding: 20,
                        textAlign: "center",
                      }}
                    >
                      En attente du paiements
                    </div>
                  ) : null}

                  {state?.showPickupOtpScreen ? (
                    <div>
                      <OTPInput
                        inputStyle={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          fontWeight: "900",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "16px",
                        }}
                        inputType="number"
                        value={otp}
                        onChange={(otpValue) => {
                          setOtp(otpValue);
                          handleComplete(otpValue);
                        }}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "5px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "20px",
                          fontWeight: "800",
                          color: "#fff",
                          margin: 0,
                        }}
                      >
                        {job_details?.ship_status === "Accepted" ? (
                          <span>
                            Code à demander à <br />
                            l’envoyeur{" "}
                          </span>
                        ) : job_details?.ship_status === "Picked" ? (
                          <span>
                            {" "}
                            Code à demander au <br /> destinataire{" "}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  ) : job_details?.ship_status === "Accepted" &&
                    job_details?.ship_pickup_otp ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setState({ showPickupOtpScreen: true });
                      }}
                    >
                      Récuperer le colis
                    </div>
                  ) : job_details?.ship_status === "Picked" ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setState({ showPickupOtpScreen: true });
                      }}
                    >
                      Deliver
                    </div>
                  ) : job_details?.ship_status === "Deliver" ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Laisser un commentaire
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#f9c7b8", marginTop: "90px" }}
        >
          <CustomFooter />
        </div>
      </div>
    </div>
  );
}

export default JobDetails;
