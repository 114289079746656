import { CSSProperties, useState } from "react";
import "./loader.css"; // Assuming additional CSS is needed
import PropTypes from "prop-types";
import { RingLoader } from "react-spinners";
import { defaultLabelColors } from "../../utils/constants";

// Custom CSS override for loader styling
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red", // Customize spinner border color if necessary
  background: "transparent", // Ensures the background is transparent
};

function Loader(props: any) {
  const { loading } = props;
  const [color, setColor] = useState(defaultLabelColors.main_blue); // You can update the color dynamically if needed

  return (
    loading ? <div className="loader-overlay">
      {" "}
      {/* Transparent overlay */}
      <RingLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={150} // Adjust the size if needed
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div> : null
  );
}

// Default prop values
Loader.defaultProps = {
  loading: true,
};

// PropTypes for validation
Loader.propTypes = {
  loading: PropTypes.any,
};

export default Loader;
