import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { GetApiParams } from "..";
import apiServices from "../../services/RequestHandler";

export interface profileState {
  profile: any;
  profile_data: any;
  activity: any;
}

interface APIParams {
  end_point: string;
}

const initialState: profileState = {
  profile: [],
  profile_data: [],
  activity: [],
};

export const getProfileInfo = createAsyncThunk(
  "c2c-transporters/me",
  async (data: GetApiParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, `me`);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state: profileState, action: any) => {
      state.profile_data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileInfo.pending, (state, { payload }) => {
      state.activity = true;
    });
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
