import React, { useEffect,  useState } from "react";
import CustomFooter from "../../UiComponent/CustomFooter";
import {
  AddProductIcon,
  AnouncementIcon,
  CartIconMarketPlace,
  ConsoleIcon,
  FrankIcon,
  MailOrangeIcon,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { defaultLabelColors, spacing } from "../../utils/constants";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import InputField from "../../UiComponent/TextFeild";
import {
  AddBoxOutlined,
  ArrowDropDown,
  Close,
  KeyboardArrowDown,
  Logout,
  Search,
  Settings,
} from "@mui/icons-material";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import marketplace_banner_img from "../../assets/images/FrankIcons/services-icon-1.png";
import marketplace_banner_img_two from "../../assets/images/ec20a7fa2b94b62d2a3b0e9fcbfec8543d68719d.png";
import { strings } from "../../Store/Localization";
import apiServices from "../../services/RequestHandler";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../UiComponent/LoadingComponent";
import Toast from "../../UiComponent/Toast/Toast";
import { RootState } from "../../Store";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import { Helmet } from "react-helmet";
interface CartItem {
  id: number;
  name: string;
  quantity: number;
}
export const MarketPlace = (props: any) => {
  let user = getDataFromLocalStorage("user");
  const dispatch = useDispatch<any>();
  let userDetails = getDataFromLocalStorage("user");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
  const openForLang = Boolean(anchorEN);
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
    }
  );
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const [cartItems, setCartItems] = useState<CartItem[]>([
    { id: 1, name: "Product 1", quantity: 2 },
    { id: 2, name: "Product 2", quantity: 1 },
  ]);
  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };
  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  useEffect(() => {
    handleGetAllCategories();
    handleGetProducts();
  }, []);
  const handleOpenProduct = (id: any) => {
    try {
      navigate(`/product/${id}`);
    } catch (error) {}
  };
  const handleGetProducts = async () => {
    try {
      let user = getDataFromLocalStorage("user");
      let obj = {
        user_id: user?.id,
      };
      let resp = await apiServices.postFromApi(
        "c2c-product-masters/product-home",
        obj,
        ""
      );

      setState({
        latestProducts: resp?.data?.latest_items,
        topTrendingProducts: resp?.data?.top_trending_items,
        mostViewedProducts: resp?.data?.most_recent_view_items,
      });
    } catch (error) {}
  };
  const getProductsByCategory = (cat_id: any) => {
    try {
      navigate(`/all-products/${Number(cat_id)}`);
    } catch (error) {}
  };
 
  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, childern: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.childern.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }
  const handleGetAllCategories = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);
        console.log(
          "buildNestedStructure(resp?.data);",
          buildNestedStructure(resp?.data)
        );
        setState({ activity: false });
      }
    } catch (error) {}
  };

  return state?.activity ? (
    <LoadingComponent activity={state?.activity} />
  ) : (
    <>
      <Helmet>
        <title>Marketplace | Frank</title>
        <link rel="canonical" href="https://v2internal.frankme.com/marketplace" />
        <meta name="description" content="" />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <div
                style={{
                  padding: 15,
                  background: "white",
                  borderRadius: 24,
                  marginTop: 20,
                  // width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 14,
                      width: "92%",
                    }}
                  >
                    <a
                      href="/"
                      style={{ marginBottom: "-10px", marginLeft: 25 }}
                    >
                      <FrankIcon fill={"#ee6931"} height={55} />
                    </a>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 14,
                        flex: 1,
                      }}
                    >
                      <div className="font_resp">
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="particulier font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Particulier{" "}
                            <KeyboardArrowDown
                              style={{
                                fontSize: "24px",
                                fontWeight: "lighter",
                              }}
                            />
                          </span>

                          <div className="dropdown">
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/send-package");
                              }}
                            >
                              Envoyer un colis
                            </div>
                            <div className="dropdown-item">
                              Suivre mon colis
                            </div>
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/transporter-un-colis");
                              }}
                            >
                              Devenir transporteur
                            </div>
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/send-package");
                              }}
                            >
                              Démenagement{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="font_resp">
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="particulier font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Transporteur{" "}
                            <KeyboardArrowDown
                              style={{
                                fontSize: "24px",
                                fontWeight: "lighter",
                              }}
                            />{" "}
                          </span>

                          <div className="dropdown">
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/transporter-un-colis");
                              }}
                            >
                              Devenir Transporteur
                            </div>
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/transporter-un-colis");
                              }}
                            >
                              Voir les annonces
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="font_resp">
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="particulier font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              navigate("/marketplace");
                            }}
                          >
                            Market place
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="hide_login_drawer"
                      style={{ cursor: "pointer", marginTop: "10px" }}
                      onClick={toggleDrawer(true)}
                    >
                      <svg
                        width="23px"
                        height="22px"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: "-10px" }}
                      >
                        <path
                          d="M4 16H28M4 8H28M4 24H20"
                          stroke="#281208"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    {/* This is Drawer */}
                    <Drawer
                      anchor="right"
                      open={isDrawerOpen}
                      onClose={toggleDrawer(false)}
                      style={{
                        borderRadius: "0px",
                        background: "none",
                        height: "100vh",
                      }}
                      PaperProps={{
                        sx: {
                          width: "300px",
                        },
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                          <FrankIcon fill={"#ee6931"} height={60} />
                        </div>
                        <div style={{ padding: "20px 20px 0px 0px" }}>
                          <IconButton onClick={toggleDrawer(false)}>
                            <Close
                              style={{ fontSize: "28px", color: "#000" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                      <Typography
                        variant="body1"
                        style={{ paddingLeft: "20px", flex: 1 }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                          }}
                        >
                          {userDetails ? (
                            <div className="user" style={{ cursor: "pointer" }}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "600",
                                }}
                                onClick={() => {
                                  navigate("/inbox");
                                }}
                              >
                                <MailOrangeIcon />{" "}
                                <span style={{ color: "#000" }}>Mail</span>
                              </div>
                            </div>
                          ) : null}

                          {userDetails ? (
                            <div
                              className="compte"
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <div className="user" style={{}}>
                                <div
                                  onClick={handleClick}
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "600",
                                  }}
                                >
                                  <UserOrangeIcon /> <span>Account</span>
                                </div>
                              </div>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "200px",
                                    borderRadius: "12px",
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&::before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <MenuItem
                                  className="dropdown-item"
                                  style={{ fontFamily: "Raleway-Variable" }}
                                  onClick={() => {
                                    navigate("/profile");
                                  }}
                                >
                                  <Avatar /> Profil
                                </MenuItem>

                                <Divider />

                                <MenuItem
                                  className="dropdown-item"
                                  style={{ fontFamily: "Raleway-Variable" }}
                                  // onClick={handleClose}
                                >
                                  <ListItemIcon>
                                    <Settings fontSize="small" />
                                  </ListItemIcon>
                                  Settings
                                </MenuItem>
                                <MenuItem
                                  className="dropdown-item"
                                  style={{ fontFamily: "Raleway-Variable" }}
                                  // onClick={handleLogout}
                                >
                                  <ListItemIcon>
                                    <Logout fontSize="small" />
                                  </ListItemIcon>
                                  Logout
                                </MenuItem>
                              </Menu>
                            </div>
                          ) : (
                            <div style={{ gap: "20px", marginTop: "20px" }}>
                              <div
                                onClick={() => {
                                  navigate("/login");
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <span
                                  className="particulier"
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "left",
                                  }}
                                >
                                  S'inscrire
                                </span>
                              </div>

                              <div
                                onClick={() => {
                                  navigate("/login");
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                  marginTop: "20px",
                                }}
                              >
                                <span
                                  className="particulier  "
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                >
                                  Se connecter
                                </span>
                              </div>
                            </div>
                          )}

                          <div
                            className="compte"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <div className="user">
                              <div
                                className="particulier"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={handleClickForLang}
                              >
                                {selector.lang?.toUpperCase()} <ArrowDropDown />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <img
                            src={drawerBoxIcon}
                            height={"60%"}
                            alt="box-icon"
                          />
                        </div>
                      </Typography>
                    </Drawer>
                    {/*Drawer ends here*/}

                    <div className="login_area">
                      <div className="right">
                        <div className="font_resp">
                          <div
                            className="menu-component menu-container"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className="particulier font_resp"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Suivre mon colis
                            </span>
                          </div>
                        </div>

                        {userDetails ? (
                          <div
                            className="tracker"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="line-mail"
                              onClick={() => {
                                navigate("/inbox");
                              }}
                            >
                              <MailOrangeIcon />
                            </div>
                          </div>
                        ) : null}

                        {userDetails ? (
                          <div
                            className="compte"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <div className="user" style={{}}>
                              <div onClick={handleClick}>
                                <UserOrangeIcon />
                              </div>
                            </div>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "200px",
                                  borderRadius: "12px",
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&::before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                onClick={() => {
                                  navigate("/profile");
                                }}
                              >
                                <Avatar /> Profil
                              </MenuItem>
                              <MenuItem
                                className="dropdown-item"
                                style={{
                                  fontFamily: "Raleway-Variable",
                                  gap: 10,
                                }}
                                onClick={() => {
                                  navigate("/my-jobs");
                                }}
                              >
                                <AnouncementIcon
                                  height={25}
                                  width={25}
                                  fill={"#bdbdbd"}
                                />
                                {"Transactions"}
                              </MenuItem>
                              <MenuItem
                                className="dropdown-item"
                                style={{
                                  fontFamily: "Raleway-Variable",
                                  gap: 10,
                                }}
                                onClick={() => {
                                  navigate("/my-anounces");
                                }}
                              >
                                <AnouncementIcon
                                  height={25}
                                  width={25}
                                  fill={"#bdbdbd"}
                                />{" "}
                                {strings?.my_parcels}
                              </MenuItem>
                              <Divider />

                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                // onClick={handleClose}
                              >
                                <ListItemIcon>
                                  <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                              </MenuItem>
                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                onClick={handleLogout}
                              >
                                <ListItemIcon>
                                  <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                              </MenuItem>
                            </Menu>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #F4C7B8 ",
                              borderRadius: 4,
                              padding: 5,
                              color: "#ee6931",
                              gap: 10,
                            }}
                          >
                            <div
                              onClick={() => {
                                navigate("/login");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="particulier font_resp"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                S'inscrire
                              </span>
                            </div>
                            <div
                              style={{
                                width: "2px",
                                height: "10px",
                                background: "#F4C7B8",
                              }}
                            ></div>
                            <div
                              onClick={() => {
                                navigate("/login");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="particulier font_resp"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                Se connecter
                              </span>
                            </div>
                          </div>
                        )}

                        <div
                          className="compte"
                          style={{ cursor: "pointer", position: "relative" }}
                        >
                          <div className="user">
                            <div
                              className="particulier font_resp"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={handleClickForLang}
                            >
                              {selector.lang?.toUpperCase()} <ArrowDropDown />
                            </div>

                            <Menu
                              id="account-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorEN}
                              open={openForLang}
                              onClose={handleCloseForLang}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  // width: "200px",
                                  borderRadius: "12px",
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&::before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  dispatch(setUpdateLanguage("fr"));
                                  handleCloseForLang();
                                }}
                              >
                                <div
                                  className="display-flex"
                                  style={{ gap: 10 }}
                                >
                                  <img
                                    src={frIcon}
                                    height={30}
                                    width={30}
                                    alt="fr-icon"
                                  />
                                  FR
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  dispatch(setUpdateLanguage("en"));
                                  handleCloseForLang();
                                }}
                              >
                                <div
                                  className="display-flex"
                                  style={{ gap: 10 }}
                                >
                                  <img
                                    src={enIcon}
                                    height={20}
                                    width={30}
                                    alt="en-icon"
                                  />
                                  EN
                                </div>
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "1px solid lightgray",
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <InputField
                      placeHolder={"Rechercher des produits"}
                      input_value={state?.searched_prod}
                      input_name={"searched_prod"}
                      input_style={{
                        background: "#F4F3F6",
                        minWidth: "200px",
                        width: "600px",
                        maxWidth: "772px",
                        borderRadius: "12px",
                      }}
                      readonly={true}
                      startIcon={<Search style={{ opacity: "50%" }} />}
                    />
                  </div>
                  <div style={{ display: "flex", gap: 10 }}>
                    <Button
                      variant="contained"
                      startIcon={
                        <AddBoxOutlined style={{ fontSize: "26px" }} />
                      }
                      sx={{
                        backgroundColor: defaultLabelColors.main_orange, // Same as the blue color in the image
                        color: "#fff",
                        borderRadius: "12px",
                        padding: "10px 20px",
                        textTransform: "none",
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "bold",
                        width: "300px",
                        "&:hover": {
                          backgroundColor: defaultLabelColors.main_orange,
                        },
                      }}
                    >
                      Vendre un article
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleClickforCart}
                      sx={{
                        backgroundColor: defaultLabelColors.main_blue,
                        color: "#fff",
                        borderRadius: "12px",
                        textTransform: "none",
                        fontSize: "16px",
                        textAlign: "center",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: defaultLabelColors.main_blue,
                        },
                      }}
                    >
                      <IconButton aria-describedby={id}>
                        <Badge badgeContent={cartItems.length} color="primary">
                          <CartIconMarketPlace
                            fill={defaultLabelColors.white}
                          />
                        </Badge>
                      </IconButton>
                    </Button>

                    <Popover
                      id={id}
                      open={openForCart}
                      anchorEl={anchorEM}
                      onClose={handleCloseforCart}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          borderRadius: "16px", // Apply border radius here
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for effect
                          padding: "10px",
                        },
                      }}
                    >
                      <List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              fontFamily: "Raleway-Variable",
                              fontWeight: 700,
                              color: defaultLabelColors.main_blue,
                              fontSize: "22px",
                            }}
                          >
                            Votre panier
                          </div>
                          <div>
                            <CartIconMarketPlace
                              fill={defaultLabelColors.main_blue}
                            />
                          </div>
                        </div>
                        {cartItems.length > 0 ? (
                          <div
                            className="card-container"
                            style={{ marginTop: "5px", width: "400px" }}
                          >
                            <img
                              alt="prod-img"
                              src={
                                "https://media.istockphoto.com/id/1411619454/photo/end-table-round-coffee-table-isolated-on-white.jpg?s=1024x1024&w=is&k=20&c=1RTn3iimqMcYoGZu_io2END5yxZVOw0d6jV-nrlTPWE="
                              }
                              className="card-image"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {/* {handleLocalize({
                               fr: i?.packageType?.item_name_fr,
                               en: i?.packageType?.item_name_en,
                             }) ?? ""} */}
                                Table
                              </div>
                              <div className="card-dimensions">
                                {/* {i?.productSize[0]?.size ?? ""} */}
                                large
                              </div>
                            </div>
                            <div className="card-quantity">
                              x2
                              {/* {i?.prod_quantity} */}
                            </div>
                          </div>
                        ) : (
                          <ListItem>
                            <Typography>No items in the cart.</Typography>
                          </ListItem>
                        )}
                      </List>
                    </Popover>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div style={{ padding: "0px 50px 0px 50px" }}>
            {/* <Grid container spacing={spacing.spacing2}>
            <Grid xs={12} item>
              <p className="page-title-one">{strings?.frank_shop_2}</p>
            </Grid>
            <Grid xs={4} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.searchedText ?? ""}
                onchange={onInputChange}
                startIcon={<Search />}
                placeHolder={"Rechercher votre produit"}
                input_name={"searchedText"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.location ?? ""}
                onchange={onInputChange}
                startIcon={<PushPin />}
                placeHolder={"Localisation"}
                input_name={"location"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.prix ?? ""}
                onchange={onInputChange}
                startIcon={<LocalOffer />}
                placeHolder={"Prix"}
                input_name={"prix"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
          </Grid> */}

            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <h2 className="page-title-two">
                  Nos catégories les plus populaires
                </h2>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "40px",
                display: "flex",
              }}
            >
              <ScrollContainer
                style={{
                  width: "100%",
                  height: "auto",
                  padding: "10px 0",
                  gap: "50px",
                  cursor: "pointer",
                }}
                className="scroll-container"
                horizontal={true}
                activationDistance={70}
              >
                {state?.parentCategories?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={3}
                      style={{ paddingLeft: "10px", width: "100%" }}
                      key={idx}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "32px",
                          padding: "24px",
                          textAlign: "center",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          marginRight: "16px", // Space between each item
                          width: "100%",
                          minWidth: "310px",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mb={2}
                          onClick={() => {
                            getProductsByCategory(i?.id);
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              backgroundColor: "#fff",
                              padding: "8px",
                              borderRadius: "8px",
                            }}
                          >
                            <ConsoleIcon />
                          </Box>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            ml={1}
                            color="#4D1A18"
                          >
                            {handleLocalize({
                              en: i?.category_name?.en,
                              fr: i?.category_name?.fr,
                            }) ?? "N/A"}
                          </Typography>
                        </Box>

                        {/* Category Buttons */}
                        <Grid container spacing={2}>
                          {i?.childern
                            ?.slice(0, 4)
                            .map((category: any, index: any) => (
                              <Grid item xs={6} key={index}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "100%",
                                    backgroundColor: "#F5F5F5",
                                    color: "#000",
                                    textTransform: "none",
                                    fontWeight: "600",
                                    fontFamily: "Raleway-Variable",
                                    borderRadius: "16px",
                                    boxShadow: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    "&:hover": {
                                      backgroundColor: "#F5F5F5",
                                    },
                                  }}
                                  onClick={() => {
                                    getProductsByCategory(category?.id);
                                  }}
                                >
                                  {handleLocalize({
                                    en: category?.category_name?.en,
                                    fr: category?.category_name?.fr,
                                  }) ?? "N/A"}
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </ScrollContainer>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "32px",
                  padding: "32px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                <div style={{ display: "flex", height: "120px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "26px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      cursor: "pointer",
                    }}
                  >
                    <AddProductIcon /> Vendre un article
                  </div>
                  <div style={{ width: "200px" }}>
                    <img
                      src={marketplace_banner_img}
                      alt="marketplace-banner"
                      style={{}}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  background: defaultLabelColors.main_blue,
                  borderRadius: "32px",
                  padding: "32px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                <div style={{ display: "flex", height: "120px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "26px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Lorem ipsum <br />
                    dolar sit amet.
                  </div>
                  <div>
                    <img
                      src={marketplace_banner_img_two}
                      alt="marketplace-banner-two"
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingBottom: "10px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-35px",
                }}
              >
                <h2 style={{ flex: 1 }} className="page-title-two">
                  Derniers produits mis en ligne
                </h2>
                {state?.latestProducts?.length < 4 ? null : (
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Voir tous{" "}
                  </p>
                )}
              </Grid>

              {state?.latestProducts?.slice(0, 4)?.map((i: any, idx: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    style={{ cursor: "pointer", maxHeight: 400 }}
                    onClick={() => {
                      handleOpenProduct(i?.id);
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "32px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                      }}
                    >
                      <Box position="relative">
                        <div style={{ height: "130px" }}>
                          <CardMedia
                            component="img"
                            image={
                              i?.images[0] ??
                              "https://via.placeholder.com/300x200"
                            }
                            alt="Product Image"
                            sx={{ objectFit: "contain", height: "140px" }}
                          />
                        </div>
                        {/* <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "#fff",
                          padding: "5px",
                          borderRadius: "50%",
                          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                          "&:hover": { backgroundColor: "#fff" },
                        }}
                      >
                        {state?.isFavorited ? (
                          <Favorite sx={{ color: "#ee6931" }} />
                        ) : (
                          <FavoriteBorder sx={{ color: "#626175" }} />
                        )}
                      </IconButton> */}
                      </Box>

                      <CardContent>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="#3B3A3A"
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            textWrap: "nowrap",
                          }}
                        >
                          {handleLocalize({
                            en: i?.product_title?.en,
                            fr: i?.product_title?.fr,
                          }) ?? "N/A"}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="#8F8F8F"
                          sx={{
                            fontSize: "12px",
                            marginBottom: "4px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {handleLocalize({
                            en: i?.brand_name?.en,
                            fr: i?.brand_name?.fr,
                          }) ?? "N/A"}{" "}
                          -
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Typography
                              variant="body2"
                              color="#8F8F8F"
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Raleway-Variable",
                              }}
                            >
                              Versailles - 78000
                            </Typography>
                          </div>
                          <div
                            style={{
                              color: defaultLabelColors.main_blue,
                              fontWeight: "700",
                              fontFamily: "Raleway-Variable",
                              fontSize: "22px",
                            }}
                          >
                            {i?.variation?.discount ? (
                              <span
                                style={{ color: "lightgray", paddingRight: 20 }}
                              >
                                <s> {i?.variation?.price} € </s>
                              </span>
                            ) : null}
                            {i?.variation?.discount
                              ? numberFormatWithoutCurrency(
                                  Number(i?.variation?.price) -
                                    Number(i?.variation?.discount)
                                )
                              : numberFormatWithoutCurrency(
                                  i?.variation?.price
                                )}
                            €
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-35px",
                }}
              >
                <h2 style={{ flex: 1 }} className="page-title-two">
                  Les boutiques tendances
                </h2>
                {state?.topTrendingProducts?.length < 4 ? null : (
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Voir tous{" "}
                  </p>
                )}
              </Grid>
              {state?.topTrendingProducts
                ?.slice(0, 4)
                ?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenProduct(i?.id);
                      }}
                    >
                      <Card
                        sx={{
                          borderRadius: "32px",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          overflow: "hidden",
                        }}
                      >
                        <Box position="relative">
                          <div style={{ height: "130px" }}>
                            <CardMedia
                              component="img"
                              image={
                                i?.images[0] ??
                                "https://via.placeholder.com/300x200"
                              }
                              alt="Product Image"
                              sx={{ objectFit: "contain", height: "140px" }}
                            />
                          </div>
                          {/* <IconButton
                          onClick={() => {
                            setState({ isFavorited: !state?.isFavorited });
                          }}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "#fff",
                            padding: "5px",
                            borderRadius: "50%",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                            "&:hover": { backgroundColor: "#fff" },
                          }}
                        >
                          {state?.isFavorited ? (
                            <Favorite sx={{ color: "#ee6931" }} />
                          ) : (
                            <FavoriteBorder sx={{ color: "#626175" }} />
                          )}
                        </IconButton> */}
                        </Box>

                        <CardContent>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="#3B3A3A"
                            sx={{
                              fontSize: "16px",
                              fontFamily: "Raleway-Variable",
                              textWrap: "nowrap",
                            }}
                          >
                            {handleLocalize({
                              en: i?.product_title?.en,
                              fr: i?.product_title?.fr,
                            }) ?? "N/A"}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="#8F8F8F"
                            sx={{
                              fontSize: "12px",
                              marginBottom: "4px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {handleLocalize({
                              en: i?.brand_name?.en,
                              fr: i?.brand_name?.fr,
                            }) ?? "N/A"}{" "}
                            -
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography
                                variant="body2"
                                color="#8F8F8F"
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                Versailles - 78000
                              </Typography>
                            </div>
                            <div
                              style={{
                                color: defaultLabelColors.main_blue,
                                fontWeight: "700",
                                fontFamily: "Raleway-Variable",
                                fontSize: "22px",
                              }}
                            >
                              {i?.variation?.discount ? (
                                <span
                                  style={{
                                    color: "lightgray",
                                    paddingRight: 20,
                                  }}
                                >
                                  <s> {i?.variation?.price} € </s>
                                </span>
                              ) : null}
                              {i?.variation?.discount
                                ? numberFormatWithoutCurrencyFixed(
                                    i?.variation?.price - i?.variation?.price
                                  )
                                : numberFormatWithoutCurrencyFixed(
                                    i?.variation?.price
                                  )}{" "}
                              €
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>

            {user && state?.topTrendingProducts?.length > 1 ? (
              <Grid container spacing={2} style={{ paddingBottom: "50px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <h2 style={{ flex: 1 }} className="page-title-two">
                    Une autre catégorie
                  </h2>
                  {state?.topTrendingProducts?.length < 4 ? null : (
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      Voir tous{" "}
                    </p>
                  )}
                </Grid>
                {state?.mostViewedProducts?.length < 1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <h1
                      style={{
                        textAlign: "center",
                        fontFamily: "Raleway-Variable",
                        color: "GrayText",
                      }}
                    >
                      No viewed any product yet!
                    </h1>
                  </div>
                ) : (
                  state?.mostViewedProducts
                    ?.slice(0, 4)
                    ?.map((i: any, idx: any) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={3}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpenProduct(i?.id);
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: "32px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              overflow: "hidden",
                            }}
                          >
                            <Box position="relative">
                              <div style={{ height: "180px" }}>
                                <CardMedia
                                  component="img"
                                  image={
                                    i?.images[0] ??
                                    "https://via.placeholder.com/300x200"
                                  }
                                  alt="Product Image"
                                  sx={{ objectFit: "contain", height: "160px" }}
                                />
                              </div>
                              {/* <IconButton
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRadius: "50%",
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                "&:hover": { backgroundColor: "#fff" },
                              }}
                            >
                              {state?.isFavorited ? (
                                <Favorite sx={{ color: "#ee6931" }} />
                              ) : (
                                <FavoriteBorder sx={{ color: "#626175" }} />
                              )}
                            </IconButton> */}
                            </Box>

                            {/* Content Section */}
                            <CardContent>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#3B3A3A"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Raleway-Variable",
                                  textWrap: "nowrap",
                                }}
                              >
                                {handleLocalize({
                                  en: i?.product_title?.en,
                                  fr: i?.product_title?.fr,
                                }) ?? "N/A"}
                              </Typography>

                              <Typography
                                variant="body2"
                                color="#8F8F8F"
                                sx={{
                                  fontSize: "12px",
                                  marginBottom: "4px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {handleLocalize({
                                  en: i?.brand_name?.en,
                                  fr: i?.brand_name?.fr,
                                }) ?? "N/A"}{" "}
                                -
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <Typography
                                    variant="body2"
                                    color="#8F8F8F"
                                    sx={{
                                      fontSize: "12px",
                                      fontFamily: "Raleway-Variable",
                                    }}
                                  >
                                    Versailles - 78000
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    color: defaultLabelColors.main_blue,
                                    fontWeight: "700",
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "22px",
                                  }}
                                >
                                  {i?.variation?.discount ? (
                                    <span
                                      style={{
                                        color: "lightgray",
                                        paddingRight: 20,
                                      }}
                                    >
                                      <s> {i?.variation?.price} € </s>
                                    </span>
                                  ) : null}
                                  {i?.variation?.discount
                                    ? numberFormatWithoutCurrencyFixed(
                                        i?.variation?.price -
                                          i?.variation?.price
                                      )
                                    : numberFormatWithoutCurrencyFixed(
                                        i?.variation?.price
                                      )}{" "}
                                  €
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })
                )}
              </Grid>
            ) : null}
          </div>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default MarketPlace;
