import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./App.css";
import { store } from "./Store";
import MapLoaderProvider from "./UiComponent/MapForLocations/MapLoaderProvider";
function AppEntryPoint() {
  return (
    <Provider store={store}>
      <MapLoaderProvider apiKey="AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ" >
      <App />
      </MapLoaderProvider>
    </Provider>
  );
}

export default AppEntryPoint;
