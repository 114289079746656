import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useRef } from "react";
import { CameraAlt } from "@mui/icons-material";
import { Rating } from "react-simple-star-rating";
import InputField from "../../UiComponent/TextFeild";
import CustomButton from "../../UiComponent/CustomButton";
import FlagInputField from "../../UiComponent/FlagInput";
import moment from "moment";
import { templeteData } from "../../utils/constants";
import { RefrestIcon } from "../../assets/images/FrankIcons";
import orangeBackground from "../../assets/images/FrankIcons/orange-background.png";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";
import { strings } from "../../Store/Localization";
import CustomDatePicker from "../../UiComponent/CustomDatePicker";
import { useDispatch } from "react-redux";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import axios from "axios";
import { BASE_URL } from "../../services/HttpProvider";
import { numberFormatWithoutCurrency, toBase64 } from "../../utils/helper";
import imageCompression from "browser-image-compression";

export const CustomSwitch = styled(Switch)({
  "& .Mui-checked": {
    color: "#ee6931", // Active color
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ee6931", // Track color when checked
  },
});

export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

export const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function ProfileMobileView(props: any) {
  const dispatch = useDispatch();
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleLaunchCamera = async (e: any, stateName: string) => {
    try {
      setState((prevState: any) => ({ ...prevState, loading: true }));
      const imageFile = e.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imageFile, options);

      let file = compressedFile || null;
      let uri = (await toBase64(file)) as any;

      if (!uri) {
        return;
      }

      const modifiedFile = new File(
        [file],
        `${stateName}-` + new Date().getTime(),
        { type: file.type }
      );
      let formData = new FormData();

      formData.append("files", modifiedFile);

      const resp = await axios({
        baseURL: BASE_URL,
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        url: "upload/upload-multiple",
        data: formData,
      });

      console.log("img", resp?.data?.data[0]?.uri);
      setState({ profile_pic: resp?.data?.data[0]?.uri });

      setState((prevState: any) => ({
        ...prevState,
        [`${stateName}_obj`]: { modifiedFile, uri },
        [`show_${stateName}`]: true,
        loading: false,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState: any) => ({ ...prevState, loading: false }));
    }
  };

  return (
    <div style={{ background: "#f9c9b3" }}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <CustomHeaderMobile />
        </Grid>
        <Grid xs={12} item style={{ marginLeft: "10px" }}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <CustomTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <CustomTab label="Profil" />
                <CustomTab label="Porte monnaie" />
                <CustomTab label="Evaluations" />
              </CustomTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Mon profil</p>
                </Grid>

                <Grid xs={12} item className="display-flex">
                  <div>
                    <Box
                      sx={{
                        position: "relative",
                        width: 175,
                        height: 175,
                        borderRadius: "50%",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: 3,
                        border: "1px solid #ee6931",
                      }}
                    >
                      <img
                        src={
                          state?.profile_pic
                            ? state?.profile_pic
                            : props?.userDetails?.profile_pic ??
                              "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                        }
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />

                      <IconButton
                        onClick={triggerFileInput}
                        aria-label="upload picture"
                        component="span"
                        sx={{
                          zIndex: 111111,
                          position: "absolute",
                          bottom: 5,
                          right: 62,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          boxShadow: 3,
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      >
                        <CameraAlt sx={{ color: "#3026f1" }} />
                      </IconButton>
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={(e) => handleLaunchCamera(e, "profile")}
                        style={{ display: "none" }}
                      />
                    </Box>

                    <div
                      style={{
                        padding: "10px 0px 10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <Rating
                        disableFillHover
                        initialValue={state?.userDetails?.rating ?? 0}
                        allowHover={false}
                        size={30}
                        readonly={true}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        0 avis
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} item style={{ padding: "0px 5px 0px 5px" }}>
                  <Grid container spacing={1}>
                    <Grid xs={12} item>
                      {props?.userDetails?.title && (
                        <FormControl
                          size="small"
                          style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            padding: "10px 0px 10px 0px",
                            marginLeft: "10px",
                            fontFamily: "Raleway-Variable",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <RadioGroup
                            style={{ paddingLeft: "20px" }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="title"
                            value={state?.title} // Controlled value
                            onChange={(e) =>
                              setState({
                                title: e.target.value, // Update title inside userDetails
                              })
                            }
                          >
                            <FormControlLabel
                              style={{ fontSize: "10px" }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                />
                              }
                              value="Male"
                              label={strings?.male}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "small",
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                />
                              }
                              value="Female"
                              label={strings?.female}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "small",
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                />
                              }
                              value="Others"
                              label={strings?.other}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "small",
                                },
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"Prénom"}
                        input_value={props?.first_name}
                        input_name={"first_name"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={props?.onInputChangeTwo}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"Nom"}
                        input_value={props?.surname}
                        input_name={"surname"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={props?.onInputChangeTwo}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item style={{ padding: "0px 5px 0px 5px" }}>
                  <Grid container spacing={1}>
                    <Grid xs={12} item style={{ marginLeft: "10px" }}>
                      <FlagInputField
                        divStyle={{
                          background: "#F4F3F6",
                          borderRadius: "16px",
                          border: "none",
                          padding: "8px",
                          width: "98%",
                        }}
                        classes={classes}
                        inputStyle={{ background: "#F4F3F6" }}
                        required={false}
                        name={"phone"}
                        codeName={"phone_code"}
                        value={props?.phone || ""}
                        countryCode={props?.phone_code || "+92"}
                        handleChangeMobileCode={(dialcode, codeName) =>
                          props?.handleChangeMobileCode(dialcode, codeName)
                        }
                        handleChangeMobile={(event) =>
                          props?.handleChangeMobile(event)
                        }
                        enableFlagInput={props?.enableFlagInput}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <InputField
                        placeHolder={"example@gmail.com"}
                        input_value={props?.email}
                        input_name={"email"}
                        input_style={{
                          background: "#F4F3F6",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={props?.onInputChangeTwo}
                      />
                    </Grid>
                    <Grid xs={12} item style={{ paddingLeft: "8px" }}>
                      <CustomDatePicker
                        onChange={props?.handleDateChange}
                        value={props?.selectedDate}
                        disableFuture={true}
                        fontFamily="Raleway-Italic"
                        backgroundColor="#F4F3F6"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} item style={{ padding: "0px 5zpx 0px 5px" }}>
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: "#ee6931",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 40px 10px 40px",
                      width: "100%",
                      marginTop: "5px",
                    }}
                    value={"Enregistrer"}
                    onClick={props?.handleUpdateProfile}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Vérification d’identité</p>
                </Grid>

                <Grid xs={12} item>
                  <p className="profile-text">
                    Lorem ipsum dolor sit amet consectetur. Tincidunt vitae at
                    aliquam metus mattis. Integer lectus aenean purus pretium
                    pellentesque sed.
                  </p>
                  <div>
                    <p className="profile-text">
                      1. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                    <p className="profile-text">
                      2. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                    <p className="profile-text">
                      3. Lacus mattis placerat sapien nulla pulvinar a et massa.
                      Est consectetur donec id quisque est facilisis quis massa.
                    </p>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Grid xs={12} item>
                  <p className="profile-headings">Paramètres</p>
                </Grid>

                <Grid xs={12} item>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Compte</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{
                          fontWeight: "600",
                          color: "#ee6931",
                          cursor: "pointer",
                        }}
                        onClick={props?.handlOpenDialogForPassword}
                      >
                        Changer le mot de passe
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Notifications</p>
                    </div>
                    <div>
                      <CustomSwitch />
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Paiement</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        // onClick={props?.handleOpenFuturePayment}
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Voir
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Langue</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{ fontWeight: "600", color: "#ee6931" }}
                      >
                        Changer
                      </p>
                      <Menu
                        id="account-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={props?.anchorEN}
                        open={props?.openForLang}
                        onClose={props?.handleCloseForLang}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            // width: "200px",
                            borderRadius: "12px",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("fr"));
                            props?.handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={frIcon}
                              height={30}
                              width={30}
                              alt="fr-icon"
                            />
                            FR
                          </div>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("en"));
                            props?.handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={enIcon}
                              height={20}
                              width={30}
                              alt="en-icon"
                            />
                            EN
                          </div>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <p className="profile-text">Conditions générales</p>
                    </div>
                    <div>
                      <p
                        className="profile-text"
                        style={{
                          fontWeight: "600",
                          color: "#ee6931",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(
                            "https://frankme.com/terms-and-condition"
                          );
                        }}
                      >
                        Voir
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={1} style={{ columnGap: "50px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <p
                        className="profile-headings"
                        style={{ fontSize: "24px" }}
                      >
                        Transactions
                      </p>
                    </Grid>

                    {props?.earning_data?.length < 1 ? (
                      <h1 style={{fontFamily:'Raleway-Variable',color:'grey',textAlign:'center'}}>No Earnings yet!</h1>
                    ) : (
                      props?.earning_data?.map((i: any, index: any) => {
                        return (
                          <>
                            <Grid
                              xs={12}
                              item
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px 20px 0px 20px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                  <p
                                    className="profile-headings"
                                    style={{ fontSize: "18px" }}
                                  >
                                    {" "}
                                    {numberFormatWithoutCurrency(
                                      i?.order_amount_detail?.sub_total
                                    ) ?? "0.00"}{" "}
                                    &nbsp; €
                                  </p>
                                  <p className="profile-text">
                                    {" "}
                                    {moment(i?.exp_dropoff_date).format(
                                      "DD/MM/YYYY"
                                    ) ?? "10/02/22"}
                                  </p>
                                </div>
                                <div className="display-flex">
                                  <span
                                    style={{
                                      padding: "10px 15px 10px 15px",
                                      borderRadius: "12px",
                                      background:
                                        i?.ship_status !== "Deliver"
                                          ? "#BBF4D0"
                                          : "#FBACAC",
                                      color:
                                        i?.ship_status !== "Deliver"
                                          ? "darkgreen"
                                          : "#B91C1C",
                                      textAlign: "center",
                                      fontFamily: "Raleway-Variable",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {i?.ship_status !== "Deliver"
                                      ? "Unpaid"
                                      : "Unpaid"}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </>
                        );
                      })
                    )}

                    {props?.earning_data?.length > 6 ? (
                      <Grid xs={12} item className="display-flex">
                        <p
                          className="profile-headings display-flex"
                          style={{
                            color: "#3026f1",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <RefrestIcon /> Charger plus de transactions
                        </p>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  item
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "16px",
                    marginTop: "20px",
                    height: "630px",
                  }}
                >
                  <Grid container spacing={2} style={{ height: "100%" }}>
                    <Grid xs={12} item>
                      <p
                        className="profile-headings"
                        style={{ fontSize: "24px" }}
                      >
                        Mon porte monnaie
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "30px 50px 30px 50px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "52px",
                            fontWeight: "800",
                            fontFamily: "Raleway-Variable",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ color: "grey", fontWeight: "600" }}>
                            €
                          </span>
                          240.<span style={{ fontWeight: "600" }}> 50</span>
                        </p>
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div
                        style={{
                          paddingTop: "40px",
                          position: "relative",
                          zIndex: 111,
                          flexDirection: "column",
                        }}
                        className="display-flex"
                      >
                        <img
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "220px",
                          }}
                          src={orangeBackground}
                          alt="background-orange"
                        />
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "800",
                            fontFamily: "Raleway-Variable",
                            color: "#fff",
                            zIndex: 111,
                          }}
                        >
                          Merci Frank
                        </p>

                        <CustomButton
                          style={{
                            background: "#fff",
                            color: "#ee6931",
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            border: "none",
                            borderRadius: "12px",
                            // padding: "10px 10px 10px 10px",
                            width: "200px",
                          }}
                          value={"Transférer vers un compte bancaire"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={props?.value} index={2}>
              Item Three
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileMobileView;
