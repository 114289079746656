import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  defaultLabelColors,
  getStatusColors,
  spacing,
  status_translations,
} from "../../utils/constants";
import CustomButton from "../../UiComponent/CustomButton";
import { East, ExpandMore, Search } from "@mui/icons-material";

import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  PointerIcon,
  RefrestIcon,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import InputField from "../../UiComponent/TextFeild";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import { strings } from "../../Store/Localization";
import TransporterUnColisMobile from "./TransporterUnColisMobile";
import CustomFooter from "../../UiComponent/CustomFooter";
import { styled } from "@mui/system";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import NewHeader from "../../UiComponent/NewHeader";
import transporterUnColisImage from "../../assets/images/FrankIcons/transporterUnColis.png";
import Quest from "../../UiComponent/Quest";
import Toast from "../../UiComponent/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import AuthAction from "../Auth/AuthAction";
import { Helmet } from "react-helmet";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

function TranspoprterUnColis(props: any) {
  const { classes } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiate: false,
      messagingScreen: false,
      shouldNavigate: false,
      disableNegotiate: false,
      accepter_details: null,
      login_user: getDataFromLocalStorage("user"),
    }
  );

  useEffect(() => {
    handleGetAllJobs(state?.announes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.announes]);

  // const handleRetour = () => {
  //   try {
  //     setState({
  //       openJobDialog: false,
  //       messagingScreen: false,
  //       negotiate: false,
  //       disableNegotiate: false,
  //       accepter_details: [],
  //     });

  //     handleGetJobInfo();
  //     handleGetAllJobs(state?.announes);
  //   } catch (error) {}
  // };

  const handleTerminer = () => {
    try {
      setState({
        openJobDialog: false,
        messagingScreen: false,
        negotiate: false,
        disableNegotiate: false,
        accepter_details: [],
      });
      handleGetAllJobs(state?.announes);
    } catch (error) {}
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setState({
        coords: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
  }, []);
  const handleGetAllJobs = async (jobType: string) => {
    try {
      let user = getDataFromLocalStorage("user");

      let body: any = {
        type: state?.announes,
        user: user?.id ?? null,
        limit: false,
      };
      if (jobType === "Around Me") {
        body = {
          ...body,
          lat: state?.coords?.lat,
          lng: state?.coords?.lng,
        };
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );
      const slicedData = resp?.data?.slice(0, 6);

      setState({
        allJobsData: slicedData,
        announes: jobType,
      });
      // const locations = state?.allJobsData?.pickup?.map(
      //   (item: any) => item.location
      // );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetJobInfo = async (item?: any) => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.id}`,
        ""
      );

      const accepter_details = resp?.data?.requester?.filter(
        (i: any) => i?.accepter_id === user?.id
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() > new Date(b?.created_at).getTime()
          ? -1
          : 1
      );

      if (accepter_details?.[0]?.is_agree === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }

      setState({
        jobDetails: resp?.data,
        openJobDialog: true,
      });
    } catch (error) {}
  };
  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (!re.test(value) || value[0] === " " || parseFloat(value) < 0) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };
  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
      };
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        handleSentFirstMessage(state?.jobDetails?.ship_price);
        handleGetAllJobs(state?.announes);
        setState({ openJobDialog: false });
      }
    } catch (error) {}
  };
  const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Please enter valid amount");
      return;
    }
    handleSentFirstMessage();
  };
  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${
        amount ?? state?.negotiated_price
      }`,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      return;
    }

    let obj = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      handleGetAllJobs(state?.announes);
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      if (resp.status === 201) {
        // getMessages();
        // setState({
        //   message: "",
        //   temp_document: null,
        //   upload_type: null,
        //   temp_gallery: null,
        // });
        setState({ messagingScreen: true });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
        // item_sizes.globalErrorMessage(selector.lang),
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Transport de colis entre particuliers - Frank</title>
        <link
          rel="canonical"
          href="https://v2internal.frankme.com/transporter-un-colis"
        />
        <meta
          name="description"
          content="Vous souhaitez devenir transporteur et rentabiliser vos trajets ? Découvrez les différentes annonces de livraison de colis entre particuliers avec Frank.
"
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div style={{ padding: "20px 110px 0px 110px" }}>
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} md={12} item>
                <div>
                  <h1 className="page-title-one">{strings?.announces} </h1>
                </div>
                <div
                  style={{
                    display: "flex",

                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div style={{ width: "150px" }}>
                    <img
                      src={transporterUnColisImage}
                      height={150}
                      width={150}
                      alt="transporter-img"
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      <span style={{ color: defaultLabelColors.main_blue }}>
                        {" "}
                        Gagnez de l'argent{" "}
                      </span>{" "}
                      en transportant des colis entre particuliers
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Sélectionnez, transportez, encaissez ! Rien de plus simple
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: "20px" }}>
              <Grid xs={12} md={6} item>
                <div>
                  <h3
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontWeight: "700",
                      fontSize: "22px",
                      color: "#000",
                    }}
                  >
                    Votre trajet
                  </h3>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "64px",
                        left: "26px",
                        width: "2px",
                        height: "15px",
                        borderLeft: "2px dashed gray",
                      }}
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <HomeIcon height={55} width={55} />
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputField
                          placeHolder={"Ville de départ "}
                          input_value={state?.origin}
                          input_name={"origin"}
                          input_style={{
                            background: "#fff",
                            width: "100%",
                            borderRadius: "12px",
                          }}
                          readonly={true}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <PointerIcon height={55} width={55} />
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputField
                          placeHolder={"Ville d’arrivée "}
                          input_value={state?.destination}
                          input_name={"destination"}
                          input_style={{
                            background: "#fff",
                            width: "100%",
                            borderRadius: "12px",
                          }}
                          readonly={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: "#ee6931",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      startIcon={<Search />}
                      value={strings?.search}
                    />
                  </div>
                </div>

                <div style={{ paddingTop: "50px" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <h3
                      style={{
                        flex: 1,
                        fontSize: "20px",
                        fontWeight: "700",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      Les dernières annonces de trajet
                    </h3>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {state?.allJobsData?.length ?? ""} annonces en lignes{" "}
                    </div>
                  </div>
                  <Grid container spacing={1}>
                    {state?.allJobsData?.length < 1 ? (
                      <div
                        style={{
                          fontSize: "42px",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        No Jobs Found
                      </div>
                    ) : (
                      state?.allJobsData?.map((i: any) => {
                        return (
                          <Grid xs={12} sm={12} md={6} item>
                            <div
                              style={{
                                background: "#fff",
                                borderRadius: "16px",
                                padding: "20px",
                                // height: "250px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleGetJobInfo(i);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "8px",
                                    padding: "5px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    background:
                                      getStatusColors[i?.ship_status].bg,
                                    color: getStatusColors[i?.ship_status].txt,
                                  }}
                                >
                                  {handleLocalize(
                                    status_translations[i?.ship_status]
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Raleway-Italic",
                                      color: "#000",
                                      fontWeight: "700",
                                    }}
                                    className="title-text-announces"
                                  >
                                    {handleLocalize({
                                      en: i?.shipment_items[0]?.item_name_en,
                                      fr: i?.shipment_items[0]?.item_name_fr,
                                    }) ??
                                      i?.shipment_items[0]?.prod_name ??
                                      ""}

                                    <br />
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Variable",
                                        fontSize: "12px",
                                        color: "grey",
                                      }}
                                    >
                                      Publié le{" "}
                                      {moment(i?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    fontFamily: "Raleway-Italic",
                                    fontSize: "24px",
                                    fontWeight: "800",
                                    color: defaultLabelColors.main_orange,
                                  }}
                                >
                                  {i?.ship_price ?? "0.00"}€
                                </div>
                              </div>

                              <div
                                style={{
                                  height: "100px",
                                  width: "100%",
                                  borderRadius: "15px 15px 0px 0px",
                                }}
                              >
                                <img
                                  src={i?.shipment_items[0]?.prod_images[0]}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: "15px 15px 0px 0px",
                                  }}
                                  alt="prod-img"
                                />
                              </div>

                              <div
                                style={{
                                  background: "#F4F3F6",
                                  borderRadius: "0px 0px 15px 15px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    borderBottom: "1px solid #3026f1",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "#f9c9b3",
                                      borderRadius: 100,
                                      padding: "10px 12px",
                                    }}
                                  >
                                    <MapHomeIcon
                                      fill={defaultLabelColors.main_blue}
                                      height={20}
                                      width={20}
                                    />
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Italic",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Depart
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Italic",
                                        fontWeight: "700",
                                        wordBreak: "break-all",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {" "}
                                      {i?.pickup?.city ?? "City"},
                                      {i?.pickup?.country ?? "City"}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "#f9c9b3",
                                      borderRadius: 100,
                                      padding: "10px 13px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MapPointerIcon
                                      fill={defaultLabelColors.main_blue}
                                    />
                                  </p>
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Destination
                                    <br />
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Italic",
                                        fontWeight: "700",
                                        wordBreak: "break-all",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {" "}
                                      {i?.dropoff?.city ?? "City"},
                                      {i?.dropoff?.country ?? "City"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        );
                      })
                    )}
                  </Grid>
                  {state?.allJobsData?.length < 6 ||
                  state?.allJobsData?.length < 1 ? null : (
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontSize: "22px",
                        fontWeight: "600",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/announces");
                      }}
                    >
                      <RefrestIcon fill={defaultLabelColors.main_blue} /> Voir
                      plus d'annonce
                    </div>
                  )}
                </div>
              </Grid>

              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    value={"Tout"}
                    classesNames={
                      state?.announes === "All"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "All" });
                    }}
                  />
                  <CustomButton
                    value={"Express"}
                    classesNames={
                      state?.announes === "Express"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Express" });
                    }}
                  />
                  <CustomButton
                    value={"Autour de moi"}
                    classesNames={
                      state?.announes === "Around Me"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Around Me" });
                    }}
                  />
                </div>
                <div style={{ height: "92%", marginTop: "10px" }}>
                  <GoogleMaps
                    handleGetLocation={(data) => {
                      setState({
                        destinationAddress: data?.result[0],
                        recipient_digital_code: data?.result[0].zipcode,
                      });
                    }}
                    markers_data={state.allJobsData ?? []}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ paddingBottom: "20px" }}>
              <Quest />
            </div>
          </div>
        </div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2
              style={{
                textAlign: "left",
                fontSize: "42px",
                fontFamily: "Raleway-Italic",
                fontWeight: "800",
                padding: " 40px 0px 20px 20px",
                margin: 0,
              }}
            >
              Nos actualités
            </h2>

            <ul className="cards">
              <li className="cards__item">
                <div className="card">
                  <div className="card__image card__image--river"></div>
                  <div className="card__content">
                    <div style={{ display: "flex" }}>
                      <div className="card__title" style={{ flex: 1 }}>
                        Du nouveau chez Frank
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "400",
                            marginTop: 5,
                          }}
                        >
                          il y a 10 heures
                        </p>
                      </div>
                      <div>
                        <East
                          style={{
                            background: "#fff",
                            borderRadius: 100,
                            color: defaultLabelColors.main_blue,
                            padding: 5,
                            fontSize: "16px",
                          }}
                        />{" "}
                      </div>
                    </div>
                    <p className="card__text">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </li>
              <li className="cards__item">
                <div className="card">
                  <div className="card__image card__image--record"></div>
                  <div className="card__content">
                    <div style={{ display: "flex" }}>
                      <div className="card__title" style={{ flex: 1 }}>
                        Du nouveau chez Frank
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "400",
                            marginTop: 5,
                          }}
                        >
                          il y a 10 heures
                        </p>
                      </div>
                      <div>
                        <East
                          style={{
                            background: "#fff",
                            borderRadius: 100,
                            color: defaultLabelColors.main_blue,
                            padding: 5,
                            fontSize: "16px",
                          }}
                        />{" "}
                      </div>
                    </div>
                    <p className="card__text">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </li>
              <li className="cards__item">
                <div className="card">
                  <div className="card__image card__image--flowers"></div>
                  <div className="card__content">
                    <div style={{ display: "flex" }}>
                      <div className="card__title" style={{ flex: 1 }}>
                        Du nouveau chez Frank
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "400",
                            marginTop: 5,
                          }}
                        >
                          il y a 10 heures
                        </p>
                      </div>
                      <div>
                        <East
                          style={{
                            background: "#fff",
                            borderRadius: 100,
                            color: defaultLabelColors.main_blue,
                            padding: 5,
                            fontSize: "16px",
                          }}
                        />{" "}
                      </div>
                    </div>
                    <p className="card__text">
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid
          container
          spacing={2}
          marginTop={5}
          style={{ padding: 10 }}
          className="display-flex"
        >
          <Grid
            xs={12}
            item
            className="display-flex"
            style={{
              maxWidth: "1000px",
              flexDirection: "column",
              background: "#F5C9B3",
              padding: "10px",
              borderRadius: "18px",
            }}
          >
            <h3
              style={{
                fontSize: "36px",
                fontWeight: "900",
                fontFamily: "Raleway-Italic",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                textAlign: "left",
                width: "1000px",
                paddingLeft: "38px",
                padding: "15px 0px 10px 15px",
                margin: 0,
              }}
            >
              Des questions ?
            </h3>
            <div style={{ width: "1000px" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>Qu’est-ce que Frank ?</h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}></AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment fonctionne la livraison ??
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}></AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Que se passe-t-il si mon colis est perdu / volé ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}></AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Y a-t-il une limite de poids ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  Y a-t-il une limite de poids ?
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={`${state?.negotiate ? "sm" : "md"}`}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({
              openJobDialog: false,
              messagingScreen: false,
              negotiate: false,
              disableNegotiate: false,
              accepter_details: [],
            });
          }}
          dialog_title={"Transporter un colis"}
          classes={classes}
          dialogBody={
            <DialogContent style={{}} dividers>
              {state?.negotiate ? (
                state?.messagingScreen ? (
                  <>
                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "92%",
                        padding: "20px",
                        height: "60vh",
                      }}
                    >
                      <div style={{ flex: 1, width: "100%", gap: 20 }}>
                        <div
                          style={{
                            background: "#eef2f6",
                            borderRadius: "16px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginTop: "10px",
                            width: "90%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              borderBottom: "1px solid #3026f1",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapHomeIcon
                                fill={defaultLabelColors.main_blue}
                                height={20}
                                width={20}
                              />
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "16px",
                                }}
                              >
                                Depart
                              </span>
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.pickup?.city ?? "City"},
                                {state?.jobDetails?.pickup?.country ?? "City"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapPointerIcon
                                fill={defaultLabelColors.main_blue}
                              />
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Destination
                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.dropoff?.city ?? "City"},
                                {state?.jobDetails.dropoff?.country ?? "City"}
                              </span>
                            </p>
                          </div>

                          <div
                            style={{
                              borderTop: "1px solid lightgrey",
                              padding: "10px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                                flex: 1,
                              }}
                            >
                              Frais de livraison
                            </div>
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                            >
                              {numberFormatWithoutCurrency(
                                state?.jobDetails?.ship_price
                              )}{" "}
                              €
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            background: "#DCDBFB",
                            borderRadius: "16px",
                            width: "94%",
                            padding: "10px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                            marginTop: "20px",
                            fontSize: "16px",
                          }}
                        >
                          Vous avez fait une offre pour une montant de :{" "}
                          {numberFormatWithoutCurrency(state?.negotiated_price)}{" "}
                          €
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Retour"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={handleTerminer}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Terminer"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={() => {
                          setState({
                            openJobDialog: false,
                            messagingScreen: false,
                            negotiate: false,
                            disableNegotiate: false,
                            accepter_details: [],
                            negotiated_price: "",
                          });
                          handleGetAllJobs(state?.announes);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <div>
                      <div className="card-container">
                        <img
                          src={
                            state?.jobDetails?.shipment_items[0]?.prod_images[0]
                          }
                          className="card-image"
                          alt="prod-img"
                        />
                        <div className="card-details">
                          <div className="card-title" style={{ fontSize: 20 }}>
                            {handleLocalize({
                              en: state?.jobDetails?.shipment_items[0]
                                ?.item_name_en,
                              fr: state?.jobDetails?.shipment_items[0]
                                ?.item_name_fr,
                            }) ??
                              state?.jobDetails?.shipment_items[0]?.prod_name ??
                              ""}
                          </div>

                          <div
                            className="card-quantity"
                            style={{
                              color: defaultLabelColors.main_orange,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "#080626",
                                font: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              Demande négociation :{" "}
                            </p>
                            <span
                              style={{
                                fontSize: "26px",
                                color: defaultLabelColors.main_orange,
                                fontWeight: 600,
                                paddingLeft: "10px",
                              }}
                            >
                              {" "}
                              {state?.jobDetails?.ship_price} €
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "1px",
                        marginTop: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "32px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Insérez le montant de votre offre
                      </p>

                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        cette limite permet aux membres de prendre le temps de
                        les passer en revue
                      </p>
                      <div className="display-flex">
                        <StyledTextField
                          name="negotiated_price"
                          onChange={onInputChange}
                          type="text"
                          placeholder="ex : 8 "
                          value={state.negotiated_price ?? ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  fontSize: "52px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "700",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "53px",
                                    position: "absolute",
                                    left: "calc(100% - 38%)",
                                  }}
                                >
                                  €
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Annuler"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setState({ negotiate: false, negotiated_price: "" });
                        }}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Négocier"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={() => {
                          handleNegotiate(
                            state.negotiated_price,
                            state?.jobDetails?.id
                          );
                        }}
                      />
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: 20,
                      gap: 10,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Détail de l’expéditeur
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Membre depuis &nbsp;
                        <span style={{ fontWeight: "600" }}>
                          {moment(
                            state?.jobDetails?.user_details?.created_at
                          ).format("YYYY") ?? "recently"}
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "15%",
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: 100,
                            height: 100,
                            borderRadius: 100,
                            overflow: "hidden",
                            display: "flex",

                            boxShadow: 3,
                          }}
                        >
                          <img
                            src={
                              state?.jobDetails?.user_details?.acc_phone ??
                              "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                            } // replace with your image path
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "22px",
                              fontWeight: 600,
                            }}
                          >
                            {state?.jobDetails?.user_details?.cus_name ??
                              "Customer Name"}
                          </p>
                          <Rating
                            disableFillHover
                            initialValue={
                              state?.jobDetails?.user_details?.cus_ratings ?? 2
                            }
                            allowHover={false}
                            readonly={true}
                            size={40}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Type de livraison
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "24px",
                        }}
                      >
                        {state?.jobDetails?.ship_delivery_type ?? "Express"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Annonce créée le{" "}
                        {moment(state?.jobDetails?.created_at).format(
                          "DD/MMM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.item_sizes}
                    </p>

                    <div>
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div
                            className="card-container"
                            style={{ marginTop: "10px" }}
                          >
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                              alt="prod-img"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {handleLocalize({
                                  en: i?.item_name_en,
                                  fr: i?.item_name_fr,
                                }) ??
                                  i?.prod_name ??
                                  ""}
                              </div>

                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                              {i?.prod_is_fragile ? (
                                <div
                                  className="card-quantity"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "500",
                                    marginTop: 5,
                                  }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    Fragile
                                  </span>{" "}
                                  : Handle With Care
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {i?.cat_name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {state?.accepter_details?.[0]?.is_agree === null ? (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          Frais de livraison négociés
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: "grey",
                            gap: 20,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                          <span style={{ color: defaultLabelColors.main_blue }}>
                            {numberFormatWithoutCurrency(
                              state?.accepter_details[0]?.ship_price
                            )}{" "}
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          {"Frais de livraison"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: defaultLabelColors.main_blue,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={
                        state?.disableNegotiate
                          ? "outlined_button_disable"
                          : "outlined_button"
                      }
                      value={strings?.negotiate}
                      disable={state?.disableNegotiate}
                      style={{ width: "200px" }}
                      onClick={() => {
                        setState({ negotiate: true });
                      }}
                    />
                    <CustomButton
                      classesNames={"contained_button"}
                      value={strings?.accept}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={handleAcceptJob}
                    />
                  </div>
                </>
              )}
            </DialogContent>
          }
        />

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openLoginDialog}
          handleClose={() => {
            setState({ openLoginDialog: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
              }}
              dividers
            >
              <Grid container spacing={spacing.spacing2}>
                <Grid xs={12} item>
                  <AuthAction
                    {...state}
                    {...props}
                    handleCloseLoginPage={handleCloseLoginPage}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          }
        />
      </div>

      <div>
        <div className="mobile-screen">
          <TransporterUnColisMobile
            {...props}
            {...state}
            handleAcceptJob={handleAcceptJob}
            handleNegotiate={handleNegotiate}
            handleSentFirstMessage={handleSentFirstMessage}
            handleCloseLoginPage={handleCloseLoginPage}
            handleTerminer={handleTerminer}
            onInputChange={onInputChange}
          />
        </div>
      </div>
    </>
  );
}

export default TranspoprterUnColis;
