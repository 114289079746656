import React from 'react'
import CustomHeaderMobile from '../../UiComponent/CustomeHeaderMobile'
import { defaultLabelColors } from '../../utils/constants'
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";
import CustomButton from '../../UiComponent/CustomButton';
import { AppleIcon, AppStoreFR, FacebookIcon, FrankIcon, GoogleIcon } from '../../assets/images/FrankIcons';
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import { Divider, Grid } from '@mui/material';
import { East, LockOpen, MailOutline } from '@mui/icons-material';
import InputField from '../../UiComponent/TextFeild';
import { useNavigate } from 'react-router-dom';
import { saveDataToLocalStorage } from '../../utils/LocalStore.util';
import Toast from '../../UiComponent/Toast/Toast';
import apiServices from '../../services/RequestHandler';

function AuthMobileScreen(props:any) {
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
    }
  );

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleLogin = async () => {
    try {
      if (!state?.username) {
        Toast.Error("Username cannot be empty");
        return;
      }
      if (!state?.password) {
        Toast.Error("password cannot be empty");
        return;
      }
      let obj = {
        username: state?.username,
        password: state?.password,
      };
      let resp = await apiServices.postFromApi("c2c/login", obj, "");
      if (resp?.status === 201) {
        Toast.showSuccess("Logged in succesfully");
        setState({ step: resp?.step + 1, username: "", password: "" });
        saveDataToLocalStorage("token", resp?.data?.token);
        saveDataToLocalStorage("user", resp?.data);
        navigate("/");
        return;
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.data?.error ??
              resp?.error?.error ??
              resp?.data?.isEmail ??
              resp?.data?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
      }
    } catch (error) {}
  };

  return (

      <div className="main-container" style={{ background:"#f9c9b3"  }}>
      <CustomHeaderMobile />
      
     
     
          <Grid container spacing={2}>
             
                <Grid xs={12} item>
                  <h1
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    CONNEXION
                  </h1>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Insérez votre email ainsi que votre mot de passe
                  </p>
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={"Email"}
                    onchange={onInputChange}
                    startIcon={<MailOutline />}
                    input_value={state?.username}
                    input_name={"username"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                  />
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={"Mot de passe"}
                    input_type={"password"}
                    startIcon={<LockOpen />}
                    input_value={state?.password}
                    onchange={onInputChange}
                    input_name={"password"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    J’ai oublié mon{" "}
                    <span style={{ fontWeight: "700" }}> mot de passe</span>
                  </p>
                </Grid>

                <Grid xs={12} item className="display-flex">
                  <CustomButton
                    style={{
                      width: "260px",
                    }}
                    onClick={handleLogin}
                    classesNames={"contained_button_two"}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "40px",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          ME CONNECTER
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              padding: 2,
                              fontSize:18,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid xs={12} item>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Pas de compte ?{" "}
                    <span
                      style={{ fontWeight: "700", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/sign-up");
                      }}
                    >
                      Créer un compte
                    </span>
                  </p>
                </Grid>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    color="#000"
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                      textAlign: "center",
                    }}
                  >
                    Ou
                  </Divider>
                </Grid>
                <Grid xs={12} item style={{}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "20px",
                      padding:'0px 20px 0px 20px'
                    }}
                  >
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      
                      <GoogleIcon     />
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <FacebookIcon />
                    </div>
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 40px 15px 40px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <AppleIcon   />
                    </div>
                  </div>
                </Grid>
              
          </Grid>
      
        

        <div style={{ padding: "10px 20px 10px 20px" }}>
        <div
          className="triangle"
          style={{ height: "100vh", position: "relative" }}
        >
          <div
            style={{
              paddingTop: 20,
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
            }}
          >
            <FrankIcon fill={"#fff"} height={70} />

            <p
              className="heading-two"
              style={{
                fontSize: "28px",
                textAlign: "center",
                color: "#fff",
                padding: "0px 10px 0px 10px",
              }}
            >
              <span style={{ fontSize: "24px", fontWeight: "500" }}>
                Télécharger
              </span>
              <br />
              notre application mobile
            </p>

            <div
              className="display-flex"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <a
              rel="noopener noreferrer"
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
              >
                <AppStoreFR height={60} />
              </a>
              <a
              rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ textAlign: "center", width: "190px" }}
              >
                <img src={playSore} alt='google-play' height={56} width={"65px"} />
              </a>
            </div>
          </div>

          <img
            src={downloadappStoreBanner}
            style={{ position: "absolute", bottom: 0 }}
            alt="download-banner"
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="contain" style={{ padding: "40px 0px 0px 0px" }}>
          <div className="col" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
              <FrankIcon fill={"#fff"} height={80} />
          </div>
          <div className="col">
            <h1>Particulier</h1>
            <ul>
              <li>Envoyer un colis</li>
              <li>Transporteur</li>
              <li>Devenir transporteur</li>

              <li style={{ marginTop: "10px", fontWeight: "700" }}>
                Mon compte
              </li>
              <li style={{ fontWeight: "700" }}>Messagerie</li>
            </ul>
          </div>
          <div className="col">
            <h1>Transporteur</h1>
            <ul>
              <li>Devenir transporteur</li>
              <li>Voir les annonces pour</li>
              <li> une livraison</li>
            </ul>
          </div>
          <div className="col">
            <h1>Déménagement </h1>
            <ul>
              <li>Déménageur particulier</li>
              <li>Déménageur professionnel</li>
            </ul>
          </div>
          <div className="col">
            <h1>Marketplace</h1>
            <ul></ul>
          </div>
          <div className="col">
            <h1>Stockage colis</h1>
            <ul>
              <li>Trouver un point relais particulier </li>
              <li> Proposer un espace de point relais</li>
              <li>Consigner bagage</li>

              <li
                style={{
                  marginTop: "10px",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Blog
              </li>
              <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
            </ul>
          </div>
          <div className="col" style={{ width: "190px", height: 200 }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "180px",
              }}
            >
              <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
              <ul>
                <li>hello@frankme.com</li>
                <li> 04 XX XX XX XX</li>
                <li>91 avenue de la République 75011 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <img src={facebookIcon} alt="facebook-icon" />
              </div>
              <div>
                <img src={instaIcon} alt="instagram-icon" />
              </div>
              <div>
                <img src={linkedInIcon} alt="linkedin-icon" />
              </div>
            </div>
          </div>
          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              textAlign: "center",
            }}
          >
            <a
            rel="noopener noreferrer"
              href="https://frankme.com/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Politique de confidentialité
            </a> 
            <a
            rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>

      </div>
 

  )
}

export default AuthMobileScreen
