import React, { useEffect } from "react";
import { defaultLabelColors } from "../../utils/constants";
import moment from "moment";
import { numberFormatWithoutCurrency } from "../../utils/helper";
import apiServices from "../../services/RequestHandler";
import { ArrowForwardIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { color_theme } from "./AccepterChat";

function SenderChat(props: any) {
  const selector = useSelector((state: RootState) => state.mainApp);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {}, [selector.lang]);

  useEffect(() => {
    setState({
      chat_details: props?.chat_details,
    });
  }, [JSON.stringify(props?.chat_details)]);

  const handleAcceptDeclineReq = async (req: any, req_val: boolean) => {
    try {
      let obj: any = {
        ship_id: Number(req?.ship_id),
        accepter_id: Number(req?.accepter_id),
        is_agree: Boolean(req_val),
      };
      if (!req_val) {
        obj.id = req.id;
      }
      const resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");
      if (resp.status === 200 || resp.status === 201) {
        const res = await apiServices.getFromApi(
          `c2c-shipments/${req?.ship_id}`,
          ""
        );
        setState({
          chat_details: res?.data,
        });
        if (props.handleGetShipmentRates) {
           props.handleGetShipmentRates({ ship_id: req?.ship_id },true);
        }
      }
    } catch (error) {}
  };

  const { chat_details } = state;
  return (
    <div style={{ rowGap: 20 }}>
      {(state?.chat_details?.requester ?? state?.chat_details?.requesters)?.map(
        (i: any, index: number) => (
          <div
            key={`REQUESTERS_${index + 1}`}
            style={{
              flexDirection: "row",
              borderRadius: "16px",
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              background: i.is_agree === false ? "#d9534f1f" : "lightgrey",
              color: i.is_agree === false ? "#d9534f" : "#000",
            }}
          >
            <p
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {i.accepter_name} has sent you a proposal of {i?.ship_price}€
            </p>
            {i?.is_agree === null && (
              <div
                style={{
                  flexDirection: "row",
                  display:
                    state?.chat_details?.ship_status_level > 1
                      ? "none"
                      : "flex",
                  justifyContent: "space-between",
                  gap: 10,
                  alignItems: "center",
                  paddingRight: 10,
                }}
              >
                <div
                 style={{
                  backgroundColor: "#5cb85c", // Pleasant green
                  borderRadius: 7,
                  borderWidth: 1,
                  borderColor: color_theme.card_background,
                  padding: "8px 16px",
                  fontFamily: "Raleway-Variable",
                  fontWeight: 600,
                  color: "#ffffff", // White text
                  fontSize: "14px", // Increased font size
                  cursor: "pointer",
                  transition: "background-color 0.3s, transform 0.3s",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#4cae4c"; // Darken on hover
                  e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#5cb85c"; // Reset to original color
                  e.currentTarget.style.transform = "scale(1)"; // Reset scale
                }}
                  onClick={() => handleAcceptDeclineReq(i, true)}
                >
                  Accept
                </div>
                <div
                style={{
                  backgroundColor: "#d9534f99", // semi-transparent red
                  borderRadius: 7,
                  borderWidth: 1,
                  borderColor: color_theme.card_background,
                  padding: "8px 16px", // Increased padding for better clickability
                  fontFamily: "Raleway-Variable",
                  fontWeight: 600,
                  color: "#ffffff", // Change text color to white for better contrast
                  fontSize: "14px", // Increased font size for better readability
                  cursor: "pointer", // Change cursor to pointer on hover
                  transition: "background-color 0.3s, transform 0.3s", // Smooth transition
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#c9302c"; // Darken on hover
                  e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#d9534f99"; // Reset to original color
                  e.currentTarget.style.transform = "scale(1)"; // Reset scale
                }}
                  onClick={() => handleAcceptDeclineReq(i, false)}
                >
                  Decline
                </div>
              </div>
            )}
          </div>
        )
      )}

      {state?.chat_details?.ship_status_level > 1 && (
        <div
          style={{
            background: "#5cb85c1f",
            color: "#5cb85c",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "16px",
            marginTop: "10px",
            textAlign: "center",
            fontFamily: "Raleway-Variable",
            fontWeight: "600",
            paddingLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (props.handleSwitChat) {
              props.handleSwitChat();
            }
          }}
        >
          <p>You accepet {chat_details?.acc_name}'s Offer</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForwardIos />
          </div>
        </div>
      )}
    </div>
  );
}

export default SenderChat;
