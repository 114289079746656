import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { strings } from "../../Store/Localization";

type Dayjs = dayjs.Dayjs;

interface CustomDatePickerProps {
  value: Dayjs |  null;
  onChange: (newValue: Dayjs | null, formattedDate: string) => void;
  placeholder?: string;
  disablePast?: boolean;
  fontFamily?: string;
  backgroundColor?: string;
  disableFuture?: boolean;
}

function CustomDatePicker({
  value,
  onChange,
  placeholder = strings?.date_of_birth_placeholder,
  disablePast = false,
  disableFuture = false,
  backgroundColor="white",
  fontFamily = "Raleway-Italic",
}: CustomDatePickerProps) {
  const handleDateChange = (newValue: Dayjs | null) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
    onChange(newValue, formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        disablePast={disablePast}
        disableFuture={disableFuture}
        slotProps={{
          textField: {
            placeholder: placeholder, // Display placeholder when no date is selected
            sx: {
              backgroundColor:backgroundColor,
              width: "100%",
              borderRadius: "16px",
              border: "none",
              fontFamily: fontFamily,
              "& .MuiInputBase-input": {
                padding: "18px 16px", // Add padding if needed
              },
              "& fieldset": {
                display: "none", // Hide the fieldset element
              },
              "& .MuiFormLabel-root": {
                display: "none", // Hide the label (legend) element
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
