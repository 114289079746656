import { configureStore } from "@reduxjs/toolkit";
import ProfileReducer from "./ProfileReducer";
import MainAppReducer from "./MainAppReducer";
 
// ==============================|| REDUX - MAIN STORE ||============================== //
export const store = configureStore({
  reducer: {
    profileInfo: ProfileReducer,
    mainApp:MainAppReducer
   
  },
});

export interface PostApiParams {
  end_point: string;
  data: any;
}

export interface GetApiParams {
  end_point: string;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
