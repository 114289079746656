import React, { useEffect, useRef, useState } from "react";
import {
  AppStoreFR,
  FrankIcon,
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  PointerIcon,
} from "../../assets/images/FrankIcons";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";

import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";

import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import { Box, Grid, InputAdornment, Tab, Tabs, TextField } from "@mui/material";
import { Close, FilterList, Search } from "@mui/icons-material";
import transporterUnColisImage from "../../assets/images/FrankIcons/transporterUnColis.png";
import commentCafonctionne from "../../assets/images/FrankIcons/comment_ca_fonctionne.png";
import { defaultLabelColors } from "../../utils/constants";
import CustomButton from "../../UiComponent/CustomButton";
import { strings } from "../../Store/Localization";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import apiServices from "../../services/RequestHandler";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";
import moment from "moment";
import InputField from "../../UiComponent/TextFeild";
import ActionSheet from "actionsheet-react";
import { Rating } from "react-simple-star-rating";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { styled } from "@mui/system";
import Toast from "../../UiComponent/Toast/Toast";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
    width:'100%'
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TransporterUnColisMobile(props: any) {
  const {} = props;
  const ref = useRef<any>(null);
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiateScreen: false,
    }
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  useEffect(() => {
    handleGetAllJobs();
  }, []);

  const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Please enter valid amount");
      return;
    }
    handleSentFirstMessage();
  };
  const handleTerminer = () => {
    try {
      handleClose();
      setState({
        messagingScreen: false,
        negotiate: false,
        disableNegotiate: false,
        negotiated_price: null,
        accepter_details: [],
      });
      handleGetAllJobs();
    } catch (error) {}
  };
  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (!re.test(value) || value[0] === " " || parseFloat(value) < 0) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${
        amount ?? state?.negotiated_price
      }`,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      return;
    }

    let obj = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      handleGetAllJobs();
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      if (resp.status === 201) {
        // getMessages();
        // setState({
        //   message: "",
        //   temp_document: null,
        //   upload_type: null,
        //   temp_gallery: null,
        // });
        setState({ messagingScreen: true, negotiateScreen: false });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
        // item_sizes.globalErrorMessage(selector.lang),
      );
    }
  };
  const handleOpen = () => {
    if (ref.current) {
      ref.current.open();
    }
  };
  const handleClose = () => {
    setState({
      accepter_details: [],
      disableNegotiate: false,
    });
    if (ref.current) {
      ref.current.close();
    }
    setState({ negotiateScreen: false, });
  };
  const handleGetJobInfo = async (item: any) => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.id}`,
        ""
      );

      const accepter_details = resp?.data?.requester?.filter(
        (i: any) => i?.accepter_id === user?.id
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() > new Date(b?.created_at).getTime()
          ? -1
          : 1
      );
      console.log("accepter_details", accepter_details);

      if (accepter_details?.[0]?.is_agree === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }

      setState({
        jobDetails: resp?.data,
        // openJobDialog: true,
      });
      handleOpen();
    } catch (error) {}
  };
  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
      };
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        handleSentFirstMessage(state?.jobDetails?.ship_price);
        handleGetAllJobs();
        handleClose()
      }
    } catch (error) {}
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };
  const handleMenuClick = (menuId: string) => () => {
    setExpandedMenu(expandedMenu === menuId ? null : menuId);
  };
  const handleGetAllJobs = async () => {
    try {
      let user = getDataFromLocalStorage("user");
      let body: any = {
        type: state?.announes,
        user: user?.id,
      };
      if (state?.announes === "Around Me") {
        navigator.geolocation.getCurrentPosition(function (position) {
          body = {
            ...body,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
      });
      // const locations = state?.allJobsData?.pickup?.map(
      //   (item: any) => item.location
      // );
    } catch (error) {}
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="main-container" style={{ background: "#f9c9b3" }}>
      <div className="header-body-top">
        <CustomHeaderMobile />

        <div className="body" style={{ gap: "10px" }}>
          <span
            style={{
              fontFamily: "Raleway-Italic",
              color: "#000",
              fontSize: "28px",
              fontWeight: "800",
            }}
          >
            Transporter un colis
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ height: "100px" }}>
              <img src={transporterUnColisImage} alt="transporter-img" />
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  color: "#000",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                <span style={{ color: defaultLabelColors.main_blue }}>
                  {" "}
                  Gagnez de l'argent{" "}
                </span>{" "}
                en transportant des colis entre particuliers
              </p>
              <p style={{ fontFamily: "Raleway-Variable", fontSize: "14px" }}>
                Sélectionnez, transportez, encaissez ! Rien de plus simple
              </p>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "#FACDBA",
                borderRadius: 100,
              }}
            >
              <Tabs
                style={{ padding: 5 }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{ width: "50%" }}
                  label="Vue liste"
                  {...a11yProps(0)}
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Raleway-Variable",
                    color: value === 0 ? "#783519" : "#9F4721",
                    backgroundColor: value === 0 ? "#fff" : "transparent",
                    transition: "all 0.3s ease", // Smooth transition for color and background

                    "&.Mui-selected": {
                      color: "#783519",
                    },
                    borderRadius: 100,
                  }}
                />
                <Tab
                  style={{ width: "50%" }}
                  label="Vue Carte"
                  {...a11yProps(1)}
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Raleway-Variable",
                    color: value === 1 ? "#783519" : "#9F4721",
                    transition: "all 0.3s ease", // Smooth transition for color and background

                    backgroundColor: value === 1 ? "#fff" : "transparent",
                    "&.Mui-selected": {
                      color: "#783519",
                    },
                    borderRadius: 100,
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <div>
                  <InputField
                    placeHolder={"Ex : table en bois"}
                    input_value={state?.origin}
                    input_name={"origin"}
                    input_style={{
                      background: "#fff",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    readonly={true}
                    startIcon={<Search />}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <FilterList />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <CustomButton
                  onClick={() => {
                    setState({ announes: "All" });
                  }}
                  classesNames={
                    state?.announes === "All"
                      ? "mobile_contained_button mobile_button_width"
                      : "mobile_outlined_button mobile_button_width"
                  }
                  value={"Tout"}
                />
                <CustomButton
                  onClick={() => {
                    setState({ announes: "Express" });
                  }}
                  classesNames={
                    state?.announes === "Express"
                      ? "mobile_contained_button mobile_button_width"
                      : "mobile_outlined_button mobile_button_width"
                  }
                  value={strings?.Express_announces}
                />
                <CustomButton
                  onClick={() => {
                    setState({ announes: "Around Me" });
                  }}
                  classesNames={
                    state?.announes === "Around Me"
                      ? "mobile_contained_button mobile_button_width"
                      : "mobile_outlined_button mobile_button_width"
                  }
                  value={"Autour de moi"}
                />
              </div>
              <div style={{ height: "271px" }}>
                <GoogleMaps
                  handleGetLocation={(data) => {
                    setState({
                      destinationAddress: data?.result[0],
                      recipient_digital_code: data?.result[0].zipcode,
                    });
                  }}
                  markers_data={state.allJobsData ?? []}
                />
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <div>
                  <InputField
                    placeHolder={"Ex : table en bois"}
                    input_value={state?.origin}
                    input_name={"origin"}
                    input_style={{
                      background: "#fff",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    readonly={true}
                    startIcon={<Search />}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <FilterList />
                </div>
              </div>
            </CustomTabPanel>
          </Box>

          <div>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontWeight: "700",
                fontSize: "22px",
                color: "#000",
              }}
            >
              Votre trajet
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "20%" }}>
                <HomeIcon height={55} width={55} />
              </div>
              <div>
                <InputField
                  placeHolder={"Ville de départ "}
                  input_value={state?.origin}
                  input_name={"origin"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  readonly={true}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "20%" }}>
                <PointerIcon height={55} width={55} />
              </div>
              <div style={{ width: "100%" }}>
                <InputField
                  placeHolder={"Ville d’arrivée "}
                  input_value={state?.destination}
                  input_name={"destination"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  readonly={true}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                fontFamily: "Raleway-Italic",
                color: "#000",
                fontSize: "24px",
                fontWeight: "800",
              }}
            >
              Annonces
            </div>

            <div
              style={{
                fontFamily: "Raleway-Italic",
                color: "#000",
                fontSize: "12x",
                fontWeight: "800",
                textAlign: "right",
              }}
            >
              60 annonces en lignes
            </div>
          </div>

          {value === 1 ? (
            <div
              style={{
                display: "flex",
                padding: "10px",
                gap: "10px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <CustomButton
                onClick={() => {
                  setState({ announes: "All" });
                }}
                classesNames={
                  state?.announes === "All"
                    ? "mobile_contained_button mobile_button_width"
                    : "mobile_outlined_button mobile_button_width"
                }
                value={"Tout"}
              />
              <CustomButton
                onClick={() => {
                  setState({ announes: "Express" });
                }}
                classesNames={
                  state?.announes === "Express"
                    ? "mobile_contained_button mobile_button_width"
                    : "mobile_outlined_button mobile_button_width"
                }
                value={strings?.Express_announces}
              />
              <CustomButton
                onClick={() => {
                  setState({ announes: "Around Me" });
                }}
                classesNames={
                  state?.announes === "Around Me"
                    ? "mobile_contained_button mobile_button_width"
                    : "mobile_outlined_button mobile_button_width"
                }
                value={"Autour de moi"}
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              borderRadius: "16px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {state?.allJobsData?.map((i: any) => {
              return (
                <Grid xs={12} item>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "16px",
                      padding: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleGetJobInfo(i);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Italic",
                            color: "#000",
                            fontWeight: "700",
                          }}
                          className="title-text-announces"
                        >
                          {i?.shipment_items[0]?.item_name_fr ??
                            i?.shipment_items[0]?.prod_name ??
                            "Arbre a chat"}
                          <br />
                          <span
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "12px",
                              color: "grey",
                            }}
                          >
                            Publié le{" "}
                            {moment(i?.created_at).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "24px",
                          fontWeight: "800",
                          color: defaultLabelColors.main_orange,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {i?.ship_price ?? "0.00"}€
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#F4F3F6",
                        borderRadius: " 15px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: 100,
                            padding: "10px 13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20%",
                          }}
                        >
                          <MapPointerIcon fill={defaultLabelColors.main_blue} />
                        </p>
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "16px",
                          }}
                        >
                          Destination
                          <br />
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {i?.dropoff?.city ?? "City"},
                            {i?.dropoff?.country ?? "City"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </div>

          <div style={{ height: "100%", width: "100%" }}>
            <img src={commentCafonctionne} alt="comment-img" />
          </div>
        </div>

        <div style={{ padding: "10px 20px 10px 20px" }}>
          <div
            className="triangle"
            style={{ height: "100vh", position: "relative" }}
          >
            <div
              style={{
                paddingTop: 20,
                rowGap: 20,
                display: "flex",
                flexDirection: "column",
                zIndex: 111,
              }}
            >
              <FrankIcon fill={"#fff"} height={70} />

              <p
                className="heading-two"
                style={{
                  fontSize: "28px",
                  textAlign: "center",
                  color: "#fff",
                  padding: "0px 10px 0px 10px",
                }}
              >
                <span style={{ fontSize: "24px", fontWeight: "500" }}>
                  Télécharger
                </span>
                <br />
                notre application mobile
              </p>

              <div
                className="display-flex"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <a
                  href="https://apps.apple.com/app/frank/id6502341979"
                  target="_blank"
                >
                  <AppStoreFR height={60} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.frankc2c"
                  target="_blank"
                  style={{ textAlign: "center", width: "190px" }}
                >
                  <img
                    src={playSore}
                    alt="google-play"
                    height={56}
                    width={"65px"}
                  />
                </a>
              </div>
            </div>

            <img
              src={downloadappStoreBanner}
              style={{ position: "absolute", bottom: 0 }}
              alt="download-banner"
            />
          </div>
        </div>
        <div
          className="footer-new"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            className="contain"
            style={{ padding: "40px 0px 0px 0px", height: "100%" }}
          >
            <div className="col">
              <h1>
                {" "}
                <FrankIcon fill={"#fff"} height={80} />
              </h1>
            </div>
            <div className="col">
              <h1>Particulier</h1>
              <ul>
                <li>Envoyer un colis</li>
                <li>Transporteur</li>
                <li>Devenir transporteur</li>

                <li style={{ marginTop: "10px", fontWeight: "700" }}>
                  Mon compte
                </li>
                <li style={{ fontWeight: "700" }}>Messagerie</li>
              </ul>
            </div>
            <div className="col">
              <h1>Transporteur</h1>
              <ul>
                <li>Devenir transporteur</li>
                <li>Voir les annonces pour</li>
                <li> une livraison</li>
              </ul>
            </div>
            <div className="col">
              <h1>Déménagement </h1>
              <ul>
                <li>Déménageur particulier</li>
                <li>Déménageur professionnel</li>
              </ul>
            </div>
            <div className="col">
              <h1>Marketplace</h1>
              <ul></ul>
            </div>
            <div className="col">
              <h1>Stockage colis</h1>
              <ul>
                <li>Trouver un point relais particulier </li>
                <li> Proposer un espace de point relais</li>
                <li>Consigner bagage</li>

                <li
                  style={{
                    marginTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  Blog
                </li>
                <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
              </ul>
            </div>
            <div className="col" style={{ width: "190px", height: 200 }}>
              <div
                style={{
                  border: "1px solid #fff",
                  borderRadius: "12px",
                  marginTop: "20px",
                  padding: "20px",
                  width: "180px",
                }}
              >
                <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
                <ul>
                  <li>hello@frankme.com</li>
                  <li> 04 XX XX XX XX</li>
                  <li>91 avenue de la République 75011 Paris</li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "70px",
                  gap: "10px",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div>
                  <img src={facebookIcon} alt="facebook-icon" />
                </div>
                <div>
                  <img src={instaIcon} alt="instagram-icon" />
                </div>
                <div>
                  <img src={linkedInIcon} alt="linkedin-icon" />
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                width: "100%",
                // marginLeft: "170px",
                fontFamily: "Raleway-Italic",
                fontSize: "14px",
                color: "#fff",
                // marginTop: "-30px",
                textAlign: "center",
              }}
            >
              <a
                href="https://frankme.com/privacy-policy"
                target="_blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Politique de confidentialité
              </a>
              |
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="https://frankme.com/terms-and-condition"
                target="_blank"
              >
                Termes et conditions
              </a>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ActionSheet
          ref={ref}
          sheetStyle={{
            backgroundColor: "#fff",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            padding: 10,
            height: "80%",
            overflowY: "auto",
          }}
          closeOnBgTap={false}
          onClose={() => {}}
          bgStyle={{
            backgroundColor: "rgba(1, 1, 1, 0.8)",
          }}
        >
          <div
            style={{ maxHeight: "100%", overflowY: "auto" }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
          >
            {state?.negotiateScreen ? (
              <>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: 22,
                    }}
                  >
                    Négocier
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Close onClick={handleClose} />
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "10px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <div>
                    {state?.jobDetails?.shipment_items?.map((i: any) => {
                      return (
                        <div className="card-container" style={{marginTop:'5px'}}>
                          <img
                            src={i?.prod_images[0]}
                            className="card-image"
                            style={{ width: 80, padding: 0, margin: 0 }}
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {handleLocalize({
                                en: i?.item_name_en,
                                fr: i?.item_name_fr,
                              }) ??
                                i?.prod_name ??
                                ""}
                            </div>

                            <div
                              className="card-quantity"
                              style={{ fontSize: "12px" }}
                            >
                              {i?.cat_name}
                            </div>
                            {i?.prod_is_fragile ? (
                            <div
                              className="card-quantity"
                              style={{
                                fontSize: "14px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "500",
                                marginTop: 5,
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Fragile</span> :
                              Handle With Care
                            </div>
                          ) : null}
                          </div>
                          <div
                            className="card-quantity"
                            style={{
                              paddingLeft: "30px",
                              display: "flex",
                              justifyContent: "flex-end",
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_orange,
                              width:'20%'
                            }}
                          >
                            {state?.jobDetails?.ship_price} €
                          </div>
                         
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "10px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="card-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "32px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Insérez le montant de votre offre
                    </p>

                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      cette limite permet aux membres de prendre le temps de les
                      passer en revue
                    </p>
                    <div className="display-flex" style={{ paddingLeft:'40px'}}>
                        <StyledTextField
                          name="negotiated_price"
                          onChange={onInputChange}
                          type="text"
                          placeholder="ex : 8 "
                          value={state.negotiated_price ?? ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  fontSize: "52px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "700",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "53px",
                                    position: "absolute",
                                    left: "calc(100% - 48%)",
                                    width:'50px',
                                    display:'flex',
                                    justifyContent:'flex-end',
                                    alignItems:'flex-end'

                                  }}
                                >
                                  €
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <CustomButton
                      classesNames={"outlined_button"}
                      value={strings?.cancel}
                      disable={state?.disableNegotiate}
                      // style={{ width: "200px" }}
                      onClick={() => {
                        setState({ negotiateScreen: false });
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomButton
                      classesNames={"contained_button"}
                      value={strings?.negotiate}
                      // disable={state?.items?.length > 0 ? true : false}
                      // style={{ width: "200px" }}
                      onClick={handleNegotiate}
                    />
                  </div>
                </div>
              </>
            ) : state?.messagingScreen ? (
              <div>
                <div
                  className="card-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "92%",
                    padding: "20px",
                    height: "60vh",
                  }}
                >
                  <div style={{ flex: 1, width: "100%", gap: 20 }}>
                    <div
                      style={{
                        background: "#eef2f6",
                        borderRadius: "16px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "10px",
                        width: "90%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          borderBottom: "1px solid #3026f1",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapHomeIcon
                            fill={defaultLabelColors.main_blue}
                            height={20}
                            width={20}
                          />
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "16px",
                            }}
                          >
                            Depart
                          </span>
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.pickup?.city ?? "City"},
                            {state?.jobDetails?.pickup?.country ?? "City"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapPointerIcon fill={defaultLabelColors.main_blue} />
                        </p>
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "16px",
                          }}
                        >
                          Destination
                          <br />
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.dropoff?.city ?? "City"},
                            {state?.jobDetails.dropoff?.country ?? "City"}
                          </span>
                        </p>
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid lightgrey",
                          padding: "10px",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "18px",
                            fontWeight: 600,
                            flex: 1,
                          }}
                        >
                          Frais de livraison
                        </div>
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#DCDBFB",
                        borderRadius: "16px",
                        width: "94%",
                        padding: "10px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        marginTop: "20px",
                        fontSize: "16px",
                      }}
                    >
                      Vous avez fait une offre pour une montant de :{" "}
                      {numberFormatWithoutCurrency(state?.negotiated_price)} €
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    gap: "10px",
                    paddingTop: "20px",
                  }}
                >
                  <CustomButton
                    classesNames={"outlined_button"}
                    value={"Retour"}
                    // disable={state?.items?.length > 0 ? true : false}
                    style={{ width: "200px" }}
                    onClick={handleTerminer}
                  />
                  <CustomButton
                    classesNames={"contained_button"}
                    value={"Terminer"}
                    // disable={state?.items?.length > 0 ? true : false}
                    style={{ width: "200px" }}
                    onClick={handleTerminer}
                  />
                </div>
              </div>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: 22,
                    }}
                  >
                    Annonce
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Close onClick={handleClose} />
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: 10,
                    marginTop: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        width: "50%",
                      }}
                    >
                      Détail de l’expéditeur
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "50%",
                      }}
                    >
                      Membre depuis &nbsp;
                      {moment(
                        state?.jobDetails?.user_details?.created_at
                      ).format("YYYY") ?? "recently"}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: 10 }}>
                    <div
                      style={{
                        width: "25%",
                        paddingTop: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: 100,
                          height: 100,
                          borderRadius: 100,
                          overflow: "hidden",
                          display: "flex",

                          boxShadow: 3,
                        }}
                      >
                        <img
                          src={
                            state?.jobDetails?.user_details?.acc_phone ??
                            "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                          }
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            fontWeight: 600,
                          }}
                        >
                          {state?.jobDetails?.user_details?.cus_name ??
                            "Customer Name"}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "20px",
                          }}
                        >
                          {/* <Rating
                            initialValue={5}
                            size={20}
                            iconsCount={5}
                            emptyColor="grey"
                            emptyStyle={{ color: "red" }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "15px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      background: "#F4F3F6",
                      borderRadius: "24px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ display: "flex", padding: "10px", gap: 10 }}>
                      <div style={{ width: "20%" }} className="display-flex">
                        <HomeIcon height={36} width={36} />
                      </div>
                      <div style={{lineHeight:'15px'}}>
                         <p
                          className="paragraph-text"
                          style={{ fontSize: "14px" }}
                        >
                          {strings?.pickup_address}
                        </p>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {state?.jobDetails?.pickup?.address ??
                            "Paris, France"}
                          &nbsp;
                          {state?.jobDetails?.pickup?.city ?? ""},
                          {state?.jobDetails?.pickup?.country ?? "Paris"}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", padding: "10px", gap: 10 }}>
                      <div style={{ width: "20%" }} className="display-flex">
                        <PointerIcon height={36} width={36} />
                      </div>
                      <div style={{lineHeight:'15px'}}>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px" }}
                        >
                          {strings?.dropoff_address}
                        </p>
                        <p
                          className="paragraph-text"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {state?.jobDetails?.dropoff?.address ??
                            "Beausoleil, france"}
                          &nbsp;
                          {state?.jobDetails?.dropoff?.city ??
                            ""}
                          ,
                          {state?.jobDetails?.dropoff?.country ??
                            ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Type de livraison
                  </p>
                  <div style={{ display: "flex",justifyContent:'center',alignItems:'center' }}>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        fontSize: "20px",
                        width: "50%",
                        flex: 1,
                      }}
                    >
                      {state?.jobDetails?.ship_delivery_type ?? "Express"}
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        width: "50%",
                        display:'flex',justifyContent:'flex-end'
                      }}
                    >
                      Annonce créée le{" "}
                      {moment(state?.jobDetails?.created_at).format(
                        "DD/MMM/YYYY"
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Products & dimension
                  </p>

                  <div>
                    {state?.jobDetails?.shipment_items?.map((i: any) => {
                      return (
                        <div className="card-container" style={{marginTop:'5px'}}>
                          <img
                            src={i?.prod_images[0]}
                            className="card-image"
                            style={{ width: 80, padding: 0, margin: 0 }}
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {i?.prod_name ?? ""}
                            </div>

                            <div
                              className="card-quantity"
                              style={{ fontSize: "12px" }}
                            >
                              {i?.cat_name}
                            </div>
                            {i?.prod_is_fragile ? (
                            <div
                              className="card-quantity"
                              style={{
                                fontSize: "14px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "500",
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Fragile</span> :
                              Handle With Care
                            </div>
                          ) : null}
                          </div>
                          <div
                            className="card-quantity"
                            style={{
                              paddingLeft: "30px",
                              display: "flex",
                              justifyContent: "flex-end",
                              width:'20%'
                            }}
                          >
                            x{i?.prod_quantity}
                          </div>
                       <br/>
                       
                        </div>
                      );
                    })}
                  </div>
                </div>

                {state?.accepter_details?.[0]?.is_agree === null ? (
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "18px",
                          width: "60%",
                        }}
                      >
                        Frais de livraison négociés
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          color: "grey",
                          gap: 20,
                          width: "40%",
                        }}
                      >
                        {numberFormatWithoutCurrency(
                          state?.jobDetails?.ship_price
                        )}
                        €
                        <span style={{ color: defaultLabelColors.main_blue }}>
                          {numberFormatWithoutCurrency(
                            state?.accepter_details[0]?.ship_price
                          )}
                          €
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        {"Frais de livraison"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {numberFormatWithoutCurrency(
                          state?.jobDetails?.ship_price
                        )}{" "}
                        €
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ marginTop: "10px" }}>
                  <CustomButton
                    classesNames={
                      state?.disableNegotiate
                        ? "outlined_button_disable"
                        : "outlined_button"
                    }
                    value={strings?.negotiate}
                    disable={state?.disableNegotiate}
                    // style={{ width: "200px" }}
                    onClick={() => {
                      setState({ negotiateScreen: true });
                    }}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CustomButton
                    classesNames={"contained_button"}
                    value={strings?.accept}
                    // disable={state?.items?.length > 0 ? true : false}
                    // style={{ width: "200px" }}
                    onClick={handleAcceptJob}
                  />
                </div>
              </>
            )}
          </div>
        </ActionSheet>
      </React.Fragment>
    </div>
  );
}

export default TransporterUnColisMobile;
