import { Box, DialogContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import { strings } from "../../Store/Localization";
import moment from "moment";
import CustomButton from "../../UiComponent/CustomButton";
import apiServices from "../../services/RequestHandler";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  PointerIcon,
} from "../../assets/images/FrankIcons";
import {
  defaultLabelColors,
  getStatusColors,
  status_translations,
} from "../../utils/constants";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { Rating } from "react-simple-star-rating";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import OTPInput from "react-otp-input";
import Toast from "../../UiComponent/Toast/Toast";
import CustomFooter from "../../UiComponent/CustomFooter";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { Helmet } from "react-helmet";

function TransporterJobs(props: any) {
  const { classes } = props;
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiate: false,
      messagingScreen: false,
      openDeliveryDialog: false,
      showOtpScreen: false,
    }
  );
  useEffect(() => {
    handleGetAllDeliveries();
  }, []);
  useEffect(() => {}, [selector.lang]);

  const handleGetAllDeliveries = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-accepter",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        
        setState({
          deliveries: resp?.data,
        });
      }
    } catch (error) {}
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleViewDetails = async (data: any) => {
    try {
      navigate(`/job/${data?.id}`)
      let resp = await apiServices.getFromApi(`c2c-shipments/${data?.id}`, "");
      if (resp?.status === 200 || resp?.status === 201) {
        // setState({ deliveryDetails: resp?.data, openDeliveryDialog: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePickOrder = async () => {
    try {
      if (!state?.showOtpScreen) {
        setState({ showOtpScreen: true });
        Toast.showInfo(strings?.pickup_otp_message);
        return;
      }
      if (!otp || otp?.length < 4) {
        Toast.Error(strings?.pickup_otp_for_transporter);
        return;
      }
      let obj = {
        ship_id: Number(state?.deliveryDetails?.id),
        ship_status: "Picked",
        ship_pickup_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showOtpScreen: false, openDeliveryDialog: false });
        handleGetAllDeliveries();
        setOtp("");
        return;
      } else {
        Toast.Error(strings?.pickup_otp_for_transporter ?? resp?.error);
      }
    } catch (error) {}
  };
  const handleDeliverOrder = async () => {
    try {
      if (!state?.showOtpScreen) {
        setState({ showOtpScreen: true });
        Toast.showInfo(strings?.delivery_otp_message);

        return;
      }
      if (!otp || otp?.length < 4) {
        Toast.Error(strings?.otp_confirmation);
        return;
      }
      let obj = {
        ship_id: Number(state?.deliveryDetails?.id),
        ship_status: "Deliver",
        ship_deliver_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showOtpScreen: false, openDeliveryDialog: false });
        handleGetAllDeliveries();
        setOtp("");
        return;
      } else {
        Toast.Error(
            resp?.data?.error ??
              resp?.error?.error ??
              resp?.data?.isEmail ??
              resp?.data?.isNotEmpty ??
              resp?.message ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };

  return (
    <>
     <Helmet>
        <title>Transporter Jobs | Frank</title>
        <link rel="canonical" href="https://v2internal.frankme.com/my-jobs" />
        <meta
          name="description"
          content=""
        />
      </Helmet>
    <div className="web-screen">
      <div style={{ background: "#f9c9b3" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <div style={{ padding: "10px 100px 0px 100px" }}>
          <Grid container spacing={2} style={{ padding: "30px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "35px",
                  fontWeight: "700",
                }}
              >
                {"Mes livraisons"}
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: "20px 40px",
            }}
          >
            {state?.deliveries?.map((i: any) => {
              return (
                <Grid xs={12} sm={12} md={4} lg={3} item>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "16px",
                      padding: "20px",
                      // height: "250px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleViewDetails(i);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Italic",
                            color: "#000",
                            fontWeight: "700",
                          }}
                          className="title-text-announces"
                        >
                          {i?.shipment_items[0]?.item_name_fr ??
                            i?.shipment_items[0]?.prod_name ??
                            "Arbre a chat"}
                        </p>
                      </div>
                      <div style={{}}>
                        <div
                          style={{
                            borderRadius: "8px",
                            padding: "5px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                            fontSize: "16px",
                            background: getStatusColors[i?.ship_status].bg,
                            color: getStatusColors[i?.ship_status].txt,
                          }}
                        >
                          {handleLocalize(status_translations[i?.ship_status])}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "100px",
                        width: "100%",
                        borderRadius: "15px 15px 0px 0px",
                      }}
                    >
                      <img
                        src={
                          i?.shipment_items[0]?.prod_images[0] ??
                          "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                        }
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "15px 15px 0px 0px",
                        }}
                        alt="prod-img"
                      />
                    </div>

                    <div
                      style={{
                        background: "#eef2f6",
                        borderRadius: "16px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          borderBottom: "1px solid #3026f1",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapHomeIcon
                            fill={defaultLabelColors.main_blue}
                            height={20}
                            width={20}
                          />
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "16px",
                            }}
                          >
                            Depart
                          </span>
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {i?.pickup?.city ?? "City"},
                            {i?.pickup?.country ?? "City"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            background: "#f9c9b3",
                            borderRadius: "32px",
                            padding: "10px 15px",
                          }}
                        >
                          <MapPointerIcon fill={defaultLabelColors.main_blue} />
                        </p>
                        <p
                          style={{
                            fontFamily: "Raleway-Italic",
                            fontSize: "16px",
                          }}
                        >
                          Destination
                          <br />
                          <span
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontWeight: "700",
                              wordBreak: "break-all",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            {i?.dropoff?.city ?? "City"},
                            {i?.dropoff?.country ?? "City"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>

      <div
        className="main-container"
        style={{ background: "#fff", marginTop: "100px" }}
      >
        <CustomFooter />
      </div>
      <DraggableDialog
        maxWidth={"sm"}
        open={state?.openDeliveryDialog}
        handleClose={() => {
          setState({ openDeliveryDialog: false, showOtpScreen: false });
          setOtp("");
        }}
        dialog_title={"Transporter un colis"}
        classes={classes}
        dialogBody={
          <DialogContent style={{}} dividers>
            <>
              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: 15,
                  gap: 5,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    {strings?.sender_details}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Membre depuis &nbsp;
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      {moment(
                        state?.deliveryDetails?.user_details?.created_at
                      ).format("YYYY") ?? "recently"}
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "15%",
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: 140,
                        height: 100,
                        borderRadius: 100,
                        overflow: "hidden",
                        display: "flex",

                        boxShadow: 3,
                      }}
                    >
                      <img
                        src={
                          state?.deliveryDetails?.user_details?.profile_pic ??
                          "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                        } // replace with your image path
                        alt="Profile"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      paddingLeft: "15px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        {state?.deliveryDetails?.user_details?.cus_name ??
                          "Customer Name"}
                      </p>
                      <Rating
                        disableFillHover
                        initialValue={
                          state?.deliveryDetails?.user_details?.cus_ratings ?? 2
                        }
                        readonly
                        allowHover={false}
                        size={30}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "0px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}>
                  {strings?.type_of_delivery}
                </p>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: "24px",
                    }}
                  >
                    {state?.deliveryDetails?.ship_delivery_type ?? "Express"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Annonce créée le{" "}
                    {moment(state?.deliveryDetails?.created_at).format(
                      "DD/MMM/YYYY"
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "15px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    background: "#F4F3F6",
                    borderRadius: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", padding: "10px", gap: 10 }}>
                    <div style={{ flex: 1 }} className="display-flex">
                      <HomeIcon height={36} />
                    </div>
                    <div>
                      <p
                        className="paragraph-text"
                        style={{ fontSize: "14px" }}
                      >
                        {strings?.pickup_address}
                      </p>
                      <p
                        className="paragraph-text"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {state?.deliveryDetails?.pickup?.address ??
                          "Paris, France"}
                        &nbsp;
                        {state?.deliveryDetails?.pickup?.city ?? ""},
                        {state?.deliveryDetails?.pickup?.country ?? "Paris"}
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", padding: "10px", gap: 10 }}>
                    <div style={{ flex: 1 }} className="display-flex">
                      <PointerIcon height={36} />
                    </div>
                    <div>
                      <p
                        className="paragraph-text"
                        style={{ fontSize: "14px" }}
                      >
                        {strings?.dropoff_address}
                      </p>
                      <p
                        className="paragraph-text"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {state?.deliveryDetails?.dropoff?.address ??
                          "Beausoleil, france"}
                        &nbsp;
                        {state?.deliveryDetails?.dropoff?.city ??
                          "Beausoleil, france"}
                        ,
                        {state?.deliveryDetails?.dropoff?.country ??
                          "Beausoleil, france"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "0px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}>
                  {strings?.item_sizes}
                </p>

                <div>
                  {state?.deliveryDetails?.shipment_items?.map((i: any) => {
                    return (
                      <div
                        className="card-container"
                        style={{ background: "#F4F4F6", marginTop: "10px" }}
                      >
                        <img src={i?.prod_images[0]} className="card-image" alt="prod-img" />
                        <div className="card-details">
                          <div className="card-title" style={{ fontSize: 17 }}>
                            {handleLocalize({
                              en: i?.item_name_en,
                              fr: i?.item_name_en,
                            }) ??
                              i?.prod_name ??
                              ""}
                          </div>

                          <div className="card-quantity">
                            x{i?.prod_quantity}
                          </div>
                          {i?.prod_is_fragile ? <div className="card-quantity" style={{fontSize:'14px',fontFamily:"Raleway-Variable",fontWeight:'500',marginTop:5}}>
                                <span style={{fontWeight:600}}>Fragile</span> : Handle With Care
                              </div> : null}
                        </div>
                        <div
                          className="card-quantity"
                          style={{ paddingRight: "20px" }}
                        >
                          {i?.cat_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "20px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                    {strings?.delivery_cost}
                  </div>
                  <div style={{ fontFamily: "Raleway-Variable" }}>
                    {numberFormatWithoutCurrency(
                      state?.deliveryDetails?.ship_price
                    )}{" "}
                    €
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                    Commission
                  </div>
                  <div style={{ fontFamily: "Raleway-Variable", color: "red" }}>
                    -
                    {numberFormatWithoutCurrency(
                      state?.deliveryDetails?.order_amount_detail
                        ?.commission_amount
                    )}{" "}
                    €
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                    {strings?.tax_on_commission}
                  </div>
                  <div style={{ fontFamily: "Raleway-Variable", color: "red" }}>
                    -
                    {numberFormatWithoutCurrency(
                      state?.deliveryDetails?.order_amount_detail
                        ?.commission_tax_amount
                    )}{" "}
                    €
                  </div>
                </div>

                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                    }}
                  >
                    {strings?.total}
                  </div>
                  <div
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                    }}
                  >
                    {numberFormatWithoutCurrency(
                      state?.deliveryDetails?.ship_price -
                        state?.deliveryDetails?.order_amount_detail
                          ?.commission_amount -
                        state?.deliveryDetails?.order_amount_detail
                          ?.commission_tax_amount
                    )}{" "}
                    €
                  </div>
                </div>
              </div>
              {state?.showOtpScreen ? (
                <>
                  <Grid container spacing={2} className="display-flex">
                    <Grid
                      xs={12}
                      item
                      className="display-flex"
                      style={{ flexDirection: "column" }}
                    >
                      <p
                        className="heading-one"
                        style={{ fontSize: "22px", textAlign: "center" }}
                      >
                        Insérez le code reçu par SMS
                      </p>
                      <OTPInput
                        inputStyle={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "33px",
                          fontWeight: "900",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "20px",
                        }}
                        inputType="number"
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "15px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "10px",
                  paddingTop: 10,
                }}
              >
                <CustomButton
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#000",
                    background: "#FFF",
                    border: "1px solid grey",
                    borderRadius: "12px",
                    padding: "10px 40px 10px 40px",
                    fontFamily: "Raleway-Variable",
                  }}
                  onClick={() => {
                    setState({
                      openDeliveryDialog: false,
                      showOtpScreen: false,
                    });
                    setOtp("");
                  }}
                  value={strings?.back}
                />
                {state?.deliveryDetails?.ship_status ===
                "Deliver" ? null : state?.deliveryDetails?.ship_status ===
                  "Picked" ? (
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: "#ee6931",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 40px 10px 40px",
                    }}
                    onClick={handleDeliverOrder}
                    value={strings?.deliver_order}
                  />
                ) : (
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: "#ee6931",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 40px 10px 40px",
                    }}
                    onClick={handlePickOrder}
                    value={strings?.pick_order}
                  />
                )}
              </div>
            </>
          </DialogContent>
        }
      />
    </div>
    </>
  );
}

export default TransporterJobs;
