import {
  Checkbox,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import loginImage from "../../assets/images/FrankIcons/login-image.png";
import InputField from "../../UiComponent/TextFeild";
import {
  AlarmOutlined,
  LockOpen,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CustomButton from "../../UiComponent/CustomButton";
import { Box } from "@mui/system";
import PasswordStrengthBar from "react-password-strength-bar";
import Toast from "../../UiComponent/Toast/Toast";
import apiServices from "../../services/RequestHandler";
import { strings } from "../../Store/Localization";
import {
  HandClapImage,
  LoginWithAppleIcon,
  LoginWithFacebookIcon,
  LoginWithGoogleIcon,
} from "../../assets/images/FrankIcons";
import FlagInputField from "../../UiComponent/FlagInput";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { defaultLabelColors } from "../../utils/constants";
import NewHeader from "../../UiComponent/NewHeader";
import CustomDatePicker from "../../UiComponent/CustomDatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { saveDataToLocalStorage } from "../../utils/LocalStore.util";
import { Helmet } from "react-helmet";
import CustomFooter from "../../UiComponent/CustomFooter";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";

function SignUp(props: any) {
  const { classes } = props;
  const [seconds, setSeconds] = useState<number>(30);
  const [isActive, setIsActive] = useState<boolean>(false);
  const selector = useSelector((state: RootState) => state.mainApp);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const strengthMessages = [
    selector.lang === "fr" ? "Too Faible " : "Too Short",
    selector.lang === "fr" ? "Faible " : "Weak",
    selector.lang === "fr" ? "Moyen " : "Okay ",
    selector.lang === "fr" ? "Bon " : "Good ",
    selector.lang === "fr" ? "Élevé   " : "Strong  ",
  ];

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      step: 1,
      signUpScreen: null,
      openOTPDialog: false,
      phone_code: "+33",
      verifyScreen: true,
      viewPassword: false,
      viewConfirmPassword: false,
      dateOfBirth: null,
      user_id: null,
      title: "Male",
    }
  );

  useEffect(() => {
    setState({ title: state?.title });
  }, [selector.lang, state?.title]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isActive && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setOtp("");
    }
    return () => clearInterval(timerId);
  }, [isActive, seconds]);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>(""); // Add this line

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    setSelectedDate(newValue);

    if (newValue) {
      const formatted = newValue.format("YYYY-MM-DD"); // Format the date as needed
      setFormattedDate(formatted);
    }
  };
  const handlePrevious = () => {
    try {
      if (state?.signUpScreen === 1) {
        setState({ step: 1 });
        return;
      }
      setState({
        signUpScreen: state?.signUpScreen - 1,
      });
    } catch (error) {}
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const onCheckboxChange = (e: any) => {
    try {
      const { name, checked } = e.target;

      setState({
        [name]: checked,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSignUp = async () => {
    try {
      if (!state?.userEmail) {
        Toast.Error("Email cannot be empty");
        return;
      }
      if (!regEmail.test(state?.userEmail)) {
        Toast.Error("Please enter valid email");
        return;
      }

      let obj = {
        email: state?.userEmail,
        is_agreed: state?.is_agreed,
        is_accepted: state?.is_accepted,
      };
      let resp = await apiServices.postFromApi("c2c/signup", obj, "");
      if (resp?.status === 201 || resp?.status === 200) {
        // Toast.showSuccess("Request submitted succesfully");
        setState({
          user_details: resp?.data,
          step: state?.step + 1,
          signUpScreen: resp?.data?.step_no,
          user_id: resp?.data?.id,
          title: resp?.data?.title,
          first_name: resp?.data?.first_name,
          surname: resp?.data?.surname,
        });
        setSelectedDate(dayjs(resp.data.date_of_birth));
      } else {
        Toast.Error(
          resp?.error?.error ??
            resp?.error ??
            resp?.isEmail ??
            resp?.isNotEmpty ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitCredentials = async () => {
    try {
      if (state?.signUpScreen === 1) {
        try {
          if (!state?.title) {
            Toast.Error(strings?.title_error);
            return;
          }
          if (!state?.first_name) {
            Toast.Error(strings?.first_name_error);
            return;
          }
          if (!state?.surname) {
            Toast.Error(strings?.surname_error);
            return;
          }
          if (!selectedDate) {
            Toast.Error("Enter Valid Date of Birth");
            return;
          }

          let obj = {
            title: state?.title,
            first_name: state?.first_name,
            surname: state?.surname,
            date_of_birth: dayjs(formattedDate), // Use formattedDate here
            id: state?.user_details?.id ?? state?.user_id,
          };
          let resp = await apiServices.postFromApi(
            "c2c/personal-info",
            obj,
            ""
          );
          if (resp?.status === 201 || resp?.status === 200) {
            // Toast.showSuccess("Info added succesfully");
            setState({
              user_details: resp,
              signUpScreen: resp?.data?.step,
              user_id: resp?.data?.id,
            });
          } else {
            Toast.Error(
              resp?.error ??
                resp?.error?.error ??
                resp?.isEmail ??
                resp?.isNotEmpty ??
                "Please Select Date of Birth"
            );
          }
        } catch (error) {}
      }
      if (state?.signUpScreen === 2) {
        try {
          if (!state?.username) {
            Toast.Error(strings?.user_name_error);
            return;
          }
          if (!state?.password) {
            Toast.Error(strings?.password_error);
            return;
          }
          if (!state?.confirm_password) {
            Toast.Error(strings?.confirm_password_error);
            return;
          }
          if (state?.password !== state?.confirm_password) {
            Toast.Error(strings?.passowrd_not_match);
            return;
          }

          let obj = {
            user_name: state?.username,
            password: state?.password,
            confirm_password: state?.confirm_password,
            id: state?.user_details?.data?.id ?? state?.user_id,
          };

          let resp = await apiServices.postFromApi(
            "c2c/set-login-credentials",
            obj,
            ""
          );

          if (resp?.status === 201) {
            Toast.showSuccess(strings?.set_credentials_success);
            setState({
              signUpScreen: resp?.data?.step,
              user_details: resp?.data,
              user_id: resp?.data?.id,
            });
          } else {
            Toast.Error(
              resp?.error?.error ??
              resp?.error ??
                resp?.isEmail ??
                resp?.isNotEmpty ??
                "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (state?.signUpScreen === 3) {
        try {
          if (!state?.phone_code) {
            Toast.Error("Select phone code");
            return;
          }
          if (!state?.phone) {
            Toast.Error(strings?.phone_number_error);
            return;
          }

          let obj = {
            phone_code: state?.phone_code,
            phone: state?.phone,
            id: state?.user_details?.id ?? state?.user_id,
          };
          console.log("obj",obj);
          
          let resp = await apiServices.postFromApi("c2c/phone-number", obj, "");

          if (resp?.status === 201) {
            Toast.showSuccess(
              "A verification code has been send to your phone"
            );
            setState({
              user_details: resp?.data,
            });
            alert(
              `Voici votre code de vérification Frank : ${resp?.data?.otp} `
            );
            setState({
              openOTPDialog: true,
            });
            startTimer();
          } else {
            Toast.Error(
              resp?.error.error ??
                resp?.error ??
                resp?.error?.error ??
                resp?.isEmail ??
                resp?.isNotEmpty ??
                "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {}
  };
  const handleResendOTP = async () => {
    if (state?.signUpScreen === 3) {
      try {
        if (!state?.phone_code) {
          Toast.Error("Select phone code");
          return;
        }
        if (!state?.phone) {
          Toast.Error("Phone number cannot be empty");
          return;
        }

        let obj = {
          phone_code: state?.phone_code,
          phone: state?.phone,
          id: state?.user_details?.id,
        };
        let resp = await apiServices.postFromApi("c2c/phone-number", obj, "");

        if (resp?.status === 201) {
          // Toast.showSuccess("Otp resend Succesfully");
          setState({
            user_details: resp?.data,
          });
          alert(`Voici votre code de vérification Frank : ${resp?.data?.otp} `);
          setState({
            openOTPDialog: true,
          });
          startTimer();
        } else {
          Toast.Error(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const startTimer = () => {
    setSeconds(30); // Reset to 30 seconds
    setIsActive(true);
  };
  const handleSubmitOTP = async () => {
    try {
      if (!otp) {
        Toast.Error(strings?.otp_empty_err);
        return;
      }
      if (otp.length < 4) {
        Toast.Error(strings?.otp_empty_err);
        return;
      }

      let obj = {
        phone_code: state?.phone_code,
        phone: state?.phone,
        otp: Number(otp),
        id: state?.user_details?.id ?? state?.user_id,
      };
console.log("obj",obj);

      let resp = await apiServices.postFromApi("c2c/verify-otp", obj, "");

      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess("Account Created and Verified Succesfully");
        setState({
          verifyScreen: false,
        });
        saveDataToLocalStorage("user", resp?.data);
        saveDataToLocalStorage("token", resp?.data?.token);
      } else {
        Toast.Error(strings?.wrong_or_expored_otp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseDialog = () => {
    try {
      setState({
        openOTPDialog: false,
      });
      setOtp("");
      setSeconds(0);
    } catch (error) {}
  };
  return (
    <>
      <Helmet>
        <title>Création du compte Frank - Livraison Collaborative</title>
        <link rel="canonical" href="https://v2internal.frankme.com/sign-up" />
        <meta
          name="description"
          content="Meta description : Créer votre compte et accéder à l’ensemble des services de Frank (Livraison collaborative ) 
"
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3", minHeight: "100vh" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          {state?.step === 1 ? null : (
            <Grid container spacing={2}>
              <Grid xs={12} item className="display-flex">
                <div
                  style={{
                    // maxWidth: "600px",
                    height: "100px",
                    width: "40%",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: defaultLabelColors.main_orange,
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      background:
                        state?.signUpScreen === 2 || state?.signUpScreen === 3
                          ? defaultLabelColors.main_orange
                          : "#E3CFC7",
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      background:
                        state?.signUpScreen === 3
                          ? defaultLabelColors.main_orange
                          : "#E3CFC7",
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    <p style={{ display: "flex" }}>
                      {" "}
                      étape{" "}
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          paddingLeft: 5,
                        }}
                      >
                        {" "}
                        {state?.signUpScreen}/3{" "}
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {state?.step === 1 ? (
            <Grid
              container
              spacing={2}
              style={{ padding: "50px" }}
              className="display-flex"
            >
              <Grid xs={12} md={6} item className="web-screen">
                <img
                  src={loginImage}
                  style={{ height: "100%", width: "100%" }}
                  alt="login-page"
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
                item
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Grid container spacing={2} style={{ width: "550px" }}>
                  <Grid xs={12} item>
                    <h1 style={{ paddingLeft: "10px" }} className="heading-one">
                      Inscription
                    </h1>
                    <p
                      className="paragraph-text"
                      style={{ marginTop: "-20px", paddingLeft: "10px" }}
                    >
                      Insérez votre email
                    </p>
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      placeHolder={"Inscris toi avec ton e-mail"}
                      onchange={onInputChange}
                      startIcon={<MailOutline />}
                      input_value={state?.userEmail}
                      input_name={"userEmail"}
                      input_style={{
                        background: "#fff",
                        // width: "100%",
                        borderRadius: "16px",
                      }}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <FormGroup style={{ paddingLeft: "10px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => onCheckboxChange(event)}
                            name="is_accepted"
                            checked={state?.is_accepted ? true : false}
                            size="small"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            {strings?.I_ACCEPT_FRANK_TERMS_CONDITION}
                          </span>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => onCheckboxChange(event)}
                            name="is_agreed"
                            checked={state?.is_agreed ? true : false}
                            size="small"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {strings?.I_AGREE_LATEST_UPDATED_FRANK}
                          </span>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid xs={12} item className="display-flex">
                    <CustomButton
                      value={"Je m’inscris"}
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#fff",

                        borderRadius: "12px",
                        background:
                          state?.userEmail && state?.is_accepted
                            ? " #ee6931"
                            : "#c4c4c4",
                        border:
                          state?.userEmail && state?.is_accepted
                            ? "1px solid #ee6931"
                            : "#c4f4f4",
                      }}
                      disable={
                        state?.userEmail && state?.is_accepted ? false : true
                      }
                      onClick={handleSignUp}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Divider color="grey" style={{ color: "grey" }}>
                      Ou
                    </Divider>
                  </Grid>
                  <Grid xs={12} item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithGoogleIcon />
                      </div>
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithFacebookIcon />
                      </div>
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithAppleIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : state?.step === 2 ? (
            <Grid
              container
              spacing={2}
              className="display-flex"
              style={{ paddingTop: "50px" }}
            >
              <Box sx={{ width: "40%" }}>
                {state?.signUpScreen === 1 ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid xs={12} item>
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-15px" }}
                      >
                        Informations personnelles
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={state?.title}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                value="Male"
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                              />
                            }
                            name="title"
                            label={strings?.male}
                            onClick={() => {
                              setState({ title: "Male" });
                            }}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                                value="Female"
                                name="title"
                                onClick={() => {
                                  setState({ title: "Female" });
                                }}
                              />
                            }
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                            label={strings?.female}
                          />
                          <FormControlLabel
                            onClick={() => {
                              setState({ title: "Others" });
                            }}
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                                value="Others"
                                name="title"
                              />
                            }
                            label={strings?.other}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        placeHolder={strings?.first_name}
                        input_value={state?.first_name}
                        input_name={"first_name"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        placeHolder={strings?.surname}
                        input_value={state?.surname}
                        input_name={"surname"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>

                    <Grid xs={12} item style={{ marginRight: "-16px" }}>
                      <CustomDatePicker
                        onChange={handleDateChange}
                        value={selectedDate}
                        disableFuture={true}
                        fontFamily="Raleway-Italic"
                      />
                    </Grid>
                  </Grid>
                ) : state?.signUpScreen === 2 ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid xs={12} item>
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-15px" }}
                      >
                        Informations personnelles
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        onchange={onInputChange}
                        startIcon={<MailOutline />}
                        placeHolder={strings?.user_name}
                        input_value={state?.username}
                        input_name={"username"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        input_type={
                          state?.viewPassword === false ? "password" : "text"
                        }
                        onchange={onInputChange}
                        startIcon={<LockOpen />}
                        placeHolder={"Mot de passe "}
                        input_value={state?.password}
                        input_name={"password"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        endIicon={
                          state?.viewPassword ? (
                            <Visibility
                              onClick={() => {
                                setState({
                                  viewPassword: !state?.viewPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => {
                                setState({
                                  viewPassword: !state?.viewPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          )
                        }
                      />
                      <PasswordStrengthBar
                        password={state?.password}
                        style={{ paddingLeft: "10px" }}
                        scoreWords={strengthMessages}
                        className="custom-strength-bar"
                        minLength={4}
                      />
                    </Grid>

                    <Grid xs={12} item style={{ marginTop: "-15px" }}>
                      <InputField
                        input_type={
                          state?.viewConfirmPassword === false
                            ? "password"
                            : "text"
                        }
                        onchange={onInputChange}
                        startIcon={<LockOpen />}
                        placeHolder={"Confirmation du mot de passe"}
                        input_value={state?.confirm_password}
                        input_name={"confirm_password"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        endIicon={
                          state?.viewConfirmPassword ? (
                            <Visibility
                              onClick={() => {
                                setState({
                                  viewConfirmPassword:
                                    !state?.viewConfirmPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => {
                                setState({
                                  viewConfirmPassword:
                                    !state?.viewConfirmPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ) : state?.signUpScreen === 3 ? (
                  <Grid container spacing={1} className="display-flex" > 
                    <Grid xs={12} item >
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-20px" }}
                      >
                        Numéro de téléphone & vérification
                      </p>
                    </Grid>
                    <Grid xs={12} item>
                      <p
                        className="paragraph-text"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        {" "}
                        Insérez votre numéro de téléphone
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <FlagInputField
                        classes={classes}
                        required={false}
                        name={"phone"}
                        codeName={"phone_code"}
                        value={state?.phone || ""}
                        countryCode={state?.phone_code || "+92"}
                        handleChangeMobileCode={(dialcode, codeName) =>
                          handleChangeMobileCode(dialcode, codeName)
                        }
                        handleChangeMobile={(event) =>
                          handleChangeMobile(event)
                        }
                        enableFlagInput={enableFlagInput}
                      />
                      <p className="paragraph-text">
                        Nous vous enverrons un SMS pour vérification
                      </p>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      columnGap: "10px",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#000",
                        background: "#FCE6DD",
                        border: "1px solid grey",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                        cursor: "pointer",
                        fontFamily: "Raleway-Variable",
                      }}
                      value={"Retour"}
                      onClick={handlePrevious}
                      // disable={state?.signUpScreen === 1 ? true : false}
                    />
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: "#ee6931",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      value={"Suivant"}
                      onClick={handleSubmitCredentials}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ) : null}

          <DraggableDialog
            maxWidth={"md"}
            open={state?.openOTPDialog}
            handleClose={() => {
              setState({ openOTPDialog: false });
            }}
            dialog_title={" "}
            classes={classes}
            dialogBody={
              <>
               

              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
                dividers
              >
                <>
                <div className="web-screen"> 
                {state?.verifyScreen ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid xs={12} item>
                      <p
                        className="heading-one"
                        style={{ fontSize: "32px", textAlign: "center" }}
                      >
                        Insérez le code reçu par SMS
                      </p>
                    </Grid>

                    <Grid xs={12} item className="display-flex">
                      <OTPInput
                        inputType="number"
                        inputStyle={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "53px",
                          fontWeight: "900",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "20px",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "15px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <div className="display-flex" style={{ gap: "30px" }}>
                        <p style={{ fontFamily: "Raleway-Variable" }}>
                          Vous n’avez pas reçu le code ?
                        </p>
                        {seconds < 1 ? (
                          <p
                            onClick={handleResendOTP}
                            style={{
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_orange,
                              cursor: "pointer",
                            }}
                          >
                            Renvoyez le code
                          </p>
                        ) : (
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              opacity: "60%",
                            }}
                          >
                            Renvoyez le code
                          </p>
                        )}
                        {seconds < 1 ? (
                          <div></div>
                        ) : (
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontWeight: "800",
                            }}
                            className="display-flex"
                          >
                            <AlarmOutlined />
                            {seconds} s
                          </p>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      xs={12}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        columnGap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000",
                          border: "2px solid #78778A",
                          borderRadius: "12px",
                          padding: "8px 40px 8px 40px",
                          fontFamily: "Raleway-Variable",
                        }}
                        value={"Précedent"}
                        onClick={handleCloseDialog}
                      />
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                        }}
                        value={"Verifier et créer mon compte"}
                        onClick={handleSubmitOTP}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <p
                        className="heading-one"
                        style={{ fontSize: "32px", textAlign: "center" }}
                      >
                        Votre compte est créé !
                      </p>
                      <p
                        className="paragraph-text"
                        style={{ textAlign: "center" }}
                      >
                        Vous pouvez désormais utiliser notre plateforme
                      </p>
                    </Grid>
                    <Grid xs={12} item className="display-flex">
                      <HandClapImage height={250} width={250} />
                    </Grid>
                    <Grid
                      xs={12}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        columnGap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000",
                          border: "2px solid #78778A",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          fontFamily: "Raleway-Variable",
                        }}
                        onClick={() => {
                          navigate("/send-package");
                        }}
                        value={"Envoyer un colis"}
                      />
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                        }}
                        value={"Transporter un colis"}
                        onClick={() => {
                          navigate("/transporter-un-colis");
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                </div>

                <div className="mobile-screen">
                <div>
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
                dividers
              >
                {state?.verifyScreen ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid xs={12} item>
                      <p
                        className="heading-one"
                        style={{ fontSize: "22px", textAlign: "center" }}
                      >
                        Insérez le code reçu par SMS
                      </p>
                    </Grid>

                    <Grid xs={12} item className="display-flex">
                      <OTPInput
                        inputType="number"
                        inputStyle={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "33px",
                          fontWeight: "900",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "15px",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "15px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <div className="display-flex" style={{ gap: "30px" }}>
                        <p style={{ fontFamily: "Raleway-Variable" }}>
                          Vous n’avez pas reçu le code ?
                        </p>
                        {seconds < 1 ? (
                          <p
                            onClick={handleResendOTP}
                            style={{
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_orange,
                              cursor: "pointer",
                            }}
                          >
                            Renvoyez le code
                          </p>
                        ) : (
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              opacity: "60%",
                            }}
                          >
                            Renvoyez le code
                          </p>
                        )}
                        {seconds < 1 ? (
                          <div></div>
                        ) : (
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontWeight: "800",
                            }}
                            className="display-flex"
                          >
                            <AlarmOutlined />
                            {seconds} s
                          </p>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      xs={12}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        columnGap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#000",
                          border: "2px solid #78778A",
                          borderRadius: "12px",
                          padding: "8px 40px 8px 40px",
                          fontFamily: "Raleway-Variable",
                        }}
                        value={"Précedent"}
                        onClick={handleCloseDialog}
                      />
                      <CustomButton
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                        }}
                        value={"Verifier et "}
                        onClick={handleSubmitOTP}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <p
                        className="heading-one"
                        style={{ fontSize: "32px", textAlign: "center" }}
                      >
                        Votre compte est créé !
                      </p>
                      <p
                        className="paragraph-text"
                        style={{ textAlign: "center" }}
                      >
                        Vous pouvez désormais utiliser notre plateforme
                      </p>
                    </Grid>
                    <Grid xs={12} item className="display-flex">
                      <HandClapImage height={250} width={250} />
                    </Grid>
                    <Grid
                      xs={12}
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        columnGap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000",
                          border: "2px solid #78778A",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          fontFamily: "Raleway-Variable",
                        }}
                        onClick={() => {
                          navigate("/send-package");
                        }}
                        value={"Envoyer un colis"}
                      />
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                        }}
                        value={"Transporter un colis"}
                        onClick={() => {
                          navigate("/transporter-un-colis");
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
              </div> </div>
                </>

              </DialogContent>
           
              
              </>
            }
          />
        </div>
        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>
      </div>

      <div className="mobile-screen">
        <div
          style={{
            background: "#f9c9b3",
            minHeight: "100vh",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",

              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <CustomHeaderMobile />
            </Grid>
          </Grid>

          {state?.step === 1 ? null : (
            <Grid container spacing={2}>
              <Grid xs={12} item className="display-flex">
                <div
                  style={{
                    // maxWidth: "600px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: defaultLabelColors.main_orange,
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      background:
                        state?.signUpScreen === 2 || state?.signUpScreen === 3
                          ? defaultLabelColors.main_orange
                          : "#E3CFC7",
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      background:
                        state?.signUpScreen === 3
                          ? defaultLabelColors.main_orange
                          : "#E3CFC7",
                      borderRadius: "32px",
                      width: "30%",
                      height: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    <p style={{ display: "flex" }}>
                      {" "}
                      étape{" "}
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          paddingLeft: 5,
                        }}
                      >
                        {" "}
                        {state?.signUpScreen}/3{" "}
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {state?.step === 1 ? (
            <Grid container spacing={2} className="display-flex">
              <Grid
                xs={12}
                item
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <h1 style={{ paddingLeft: "10px" }} className="heading-one">
                      Inscription
                    </h1>
                    <p
                      className="paragraph-text"
                      style={{ marginTop: "-20px", paddingLeft: "10px" }}
                    >
                      Insérez votre email
                    </p>
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      placeHolder={"Inscris toi avec ton e-mail"}
                      onchange={onInputChange}
                      startIcon={<MailOutline />}
                      input_value={state?.userEmail}
                      input_name={"userEmail"}
                      input_style={{
                        background: "#fff",
                        width: "90%",
                        borderRadius: "16px",
                      }}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <FormGroup style={{ paddingLeft: "10px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => onCheckboxChange(event)}
                            name="is_accepted"
                            checked={state?.is_accepted ? true : false}
                            size="small"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {" "}
                            {strings?.I_ACCEPT_FRANK_TERMS_CONDITION}
                          </span>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => onCheckboxChange(event)}
                            name="is_agreed"
                            checked={state?.is_agreed ? true : false}
                            size="small"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {strings?.I_AGREE_LATEST_UPDATED_FRANK}
                          </span>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid xs={12} item className="display-flex">
                    <CustomButton
                      value={"Je m’inscris"}
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#fff",

                        borderRadius: "12px",
                        background:
                          state?.userEmail && state?.is_accepted
                            ? " #ee6931"
                            : "#c4c4c4",
                        border:
                          state?.userEmail && state?.is_accepted
                            ? "1px solid #ee6931"
                            : "#c4f4f4",
                      }}
                      disable={
                        state?.userEmail && state?.is_accepted ? false : true
                      }
                      onClick={handleSignUp}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Divider color="grey" style={{ color: "grey" }}>
                      Ou
                    </Divider>
                  </Grid>
                  <Grid xs={12} item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithGoogleIcon />
                      </div>
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithFacebookIcon />
                      </div>
                      <div
                        style={{
                          background: "#fff",
                          padding: "20px 50px 20px 50px",
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <LoginWithAppleIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : state?.step === 2 ? (
            <Grid
              container
              spacing={2}
              className="display-flex"
              style={{ paddingTop: "50px" }}
            >
              <Box sx={{}}>
                {state?.signUpScreen === 1 ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid
                      xs={12}
                      item
                      style={{
                        paddingLeft: "20px",
                      }}
                    >
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-15px" }}
                      >
                        Informations personnelles
                      </p>
                    </Grid>

                    <Grid xs={12} item > 
                      <FormControl style={{
                        paddingLeft:'10px'
                      }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={state?.title}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                value="Male"
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                              />
                            }
                            name="title"
                            label={strings?.male}
                            onClick={() => {
                              setState({ title: "Male" });
                            }}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                                value="Female"
                                name="title"
                                onClick={() => {
                                  setState({ title: "Female" });
                                }}
                              />
                            }
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                            label={strings?.female}
                          />
                          <FormControlLabel
                            onClick={() => {
                              setState({ title: "Others" });
                            }}
                            control={
                              <Radio
                                sx={{
                                  color: "black",
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                                value="Others"
                                name="title"
                              />
                            }
                            label={strings?.other}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "18px",
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        placeHolder={strings?.first_name}
                        input_value={state?.first_name}
                        input_name={"first_name"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        placeHolder={strings?.surname}
                        input_value={state?.surname}
                        input_name={"surname"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        onchange={onInputChange}
                      />
                    </Grid>

                    <Grid xs={12} item style={{ marginRight: "-16px" }}>
                      <CustomDatePicker
                        onChange={handleDateChange}
                        value={selectedDate}
                        disableFuture={true}
                        fontFamily="Raleway-Italic"
                      />
                    </Grid>
                  </Grid>
                ) : state?.signUpScreen === 2 ? (
                  <Grid container spacing={2} className="display-flex">
                    <Grid xs={12} item style={{marginLeft:'10px'}}>
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-15px" }}
                      >
                        Informations personnelles
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        onchange={onInputChange}
                        startIcon={<MailOutline />}
                        placeHolder={strings?.user_name}
                        input_value={state?.username}
                        input_name={"username"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                      />
                    </Grid>

                    <Grid xs={12} item>
                      <InputField
                        input_type={
                          state?.viewPassword === false ? "password" : "text"
                        }
                        onchange={onInputChange}
                        startIcon={<LockOpen />}
                        placeHolder={"Mot de passe "}
                        input_value={state?.password}
                        input_name={"password"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        endIicon={
                          state?.viewPassword ? (
                            <Visibility
                              onClick={() => {
                                setState({
                                  viewPassword: !state?.viewPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => {
                                setState({
                                  viewPassword: !state?.viewPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          )
                        }
                      />
                      <PasswordStrengthBar
                        password={state?.password}
                        style={{ paddingLeft: "10px" }}
                        scoreWords={strengthMessages}
                        className="custom-strength-bar"
                        minLength={4}
                      />
                    </Grid>

                    <Grid xs={12} item style={{ marginTop: "-15px" }}>
                      <InputField
                        input_type={
                          state?.viewConfirmPassword === false
                            ? "password"
                            : "text"
                        }
                        onchange={onInputChange}
                        startIcon={<LockOpen />}
                        placeHolder={"Confirmation du mot de passe"}
                        input_value={state?.confirm_password}
                        input_name={"confirm_password"}
                        input_style={{
                          background: "#fff",
                          width: "100%",
                          borderRadius: "16px",
                          border: "none",
                        }}
                        endIicon={
                          state?.viewConfirmPassword ? (
                            <Visibility
                              onClick={() => {
                                setState({
                                  viewConfirmPassword:
                                    !state?.viewConfirmPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => {
                                setState({
                                  viewConfirmPassword:
                                    !state?.viewConfirmPassword,
                                });
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ) : state?.signUpScreen === 3 ? (
                  <Grid container spacing={1} className="display-flex" style={{paddingLeft:'10px',paddingRight:"10px"}}>
                    <Grid xs={12} item>
                      <h1 className="heading-two"> Création de compte</h1>
                      <p
                        className="paragraph-text"
                        style={{ marginTop: "-20px" }}
                      >
                        Numéro de téléphone & vérification
                      </p>
                    </Grid>
                    <Grid xs={12} item>
                      <p
                        className="paragraph-text"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        {" "}
                        Insérez votre numéro de téléphone
                      </p>
                    </Grid>

                    <Grid xs={12} item>
                      <FlagInputField
                        classes={classes}
                        required={false}
                        name={"phone"}
                        codeName={"phone_code"}
                        value={state?.phone || ""}
                        countryCode={state?.phone_code || "+92"}
                        handleChangeMobileCode={(dialcode, codeName) =>
                          handleChangeMobileCode(dialcode, codeName)
                        }
                        handleChangeMobile={(event) =>
                          handleChangeMobile(event)
                        }
                        enableFlagInput={enableFlagInput}
                      />
                      <p className="paragraph-text">
                        Nous vous enverrons un SMS pour vérification
                      </p>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      columnGap: "10px",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#000",
                        background: "#FCE6DD",
                        border: "1px solid grey",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                        cursor: "pointer",
                        fontFamily: "Raleway-Variable",
                      }}
                      value={"Retour"}
                      onClick={handlePrevious}
                      // disable={state?.signUpScreen === 1 ? true : false}
                    />
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background: "#ee6931",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      value={"Suivant"}
                      onClick={handleSubmitCredentials}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ) : null}

         
        </div>
      </div>
    </>
  );
}

export default SignUp;
