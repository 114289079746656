import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRoute from "./routes";
import { setUpdateLanguage } from "./Store/MainAppReducer";
import { getDataFromLocalStorage } from "./utils/LocalStore.util";
import ScrollToTop from "./UiComponent/ScrollToTop";
import CookieBanner from "./UiComponent/CookieBanner";
function App() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const lang = getDataFromLocalStorage("lang") ?? "fr";
    dispatch(setUpdateLanguage(lang));
  }, []);

  return (
    <>
      <ScrollToTop />
      <CookieBanner />
      <ToastContainer limit={1} />
      <AppRoute />
    </>
  );
}

export default App;
