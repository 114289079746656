import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import apiServices from "../../services/RequestHandler";
import moment from "moment";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { defaultLabelColors } from "../../utils/constants";
import SenderChat from "./SenderChat";
import AccepterChat from "./AccepterChat";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { strings } from "../../Store/Localization";
import { Helmet } from "react-helmet";

function SenderInbox(props: any) {
  const [chatHeads, setChatHeads] = useState<Array<any>>([]);
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      user: getDataFromLocalStorage("user"),
      loading_screen: true,
      showCustomerChat: false,
      showAccepterChat: false,
    }
  );
  useEffect(() => {
    handleGetAllChats();
  }, []);

  useEffect(() => {}, [selector]);

  const handleGetAllChats = async () => {
    try {
      let resp = await apiServices.getFromApi("chat/announcement-chats", "");
      setChatHeads(resp?.data);
    } catch (error) {}
  };

  const handleGetShipmentRates = async (item: any, val?: boolean) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.ship_id}`,
        ""
      );
      if (val) {
        setState({
          chat_details: resp?.data,
        });
        return;
      }

      setState({
        chat_details: resp?.data,
        chat_type:
          `${item?.customer_id}` === `${state?.user?.id}`
            ? "sender"
            : "transporter",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderAnnounceCard = () => (
    <div className="card-container">
      <img
        src={state?.chat_details?.shipment_items[0]?.prod_images[0]}
        className="card-image"
        alt="prod-img"
      />
      <div className="card-details" style={{ flex: 1 }}>
        <div className="card-title" style={{ fontSize: 20 }}>
          {handleLocalize({
            en: state?.chat_details?.shipment_items[0]?.item_name_en,
            fr: state?.chat_details?.shipment_items[0]?.item_name_fr,
          }) ??
            state?.chat_details?.shipment_items[0]?.prod_name ??
            ""}
        </div>

        <div
          className="card-quantity"
          style={{
            color: defaultLabelColors.main_orange,
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <p
            style={{
              color: "#080626",
              font: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            Postée le{" "}
            {moment(state?.chat_details?.created_at).format("DD/MM/YYYY")}{" "}
          </p>
        </div>
      </div>
      <div
        style={{
          fontFamily: "Raleway-Variable",
          fontWeight: "700",
          fontSize: "22px",
          color: defaultLabelColors.main_orange,
        }}
      >
        {numberFormatWithoutCurrency(state?.chat_details?.ship_price)} €
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Le Chat </title>
        <link rel="canonical" href="https://v2internal.frankme.com/inbox" />
        <meta name="description" content="Rejoins la communauté Frank." />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              padding: "60px 160px 0px 150px",
              gap: 10,
              paddingBottom: "30px",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                overflow: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <h2
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      fontSize: "22px",
                    }}
                  >
                    {strings?.inbox}
                  </h2>
                </div>
                {/* <div>
                <StartConversationIcon />
              </div> */}
              </div>
              <div style={{ paddingRight: "10px" }}>
                <InputField
                  placeHolder={"Ville de départ "}
                  input_value={state?.origin}
                  input_name={"origin"}
                  input_style={{
                    background: "#F4F3F6",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  readonly={true}
                />
              </div>
              {/* {chatHeads?.map((i: any, index: any) => {
              return (
                <ChatComponent
                  count={chatHeads?.length}
                  index={index}
                  item={i}
                />
              );
            })} */}
              <Box sx={{ flex: 1, backgroundColor: "white" }}>
                {chatHeads?.map((item: any, index: any) => {
                  let item_name = "";
                  if (item.shipment_items.length > 1) {
                    item_name = item.shipment_items[0]?.item_name_fr;
                  } else {
                    item_name = item.shipment_items[0]?.item_name_en;
                  }

                  return (
                    <>
                      <Card
                        elevation={0}
                        key={`CHAT_HEAD_${index + 1}`}
                        onClick={() => handleGetShipmentRates(item)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: 1.5,
                          cursor: "pointer",
                        }}
                      >
                        <Avatar
                          src={
                            item.shipment_items[0]?.prod_images?.[0] ??
                            "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                          }
                          sx={{
                            height: 60,
                            width: 60,
                            borderRadius: 1,
                            marginRight: 2,
                            backgroundColor: "#f0f0f0",
                            objectFit: "contain",
                          }}
                          alt="Product Image"
                        />
                        <CardContent sx={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {handleLocalize({
                              en: item?.shipment_items[0]?.item_name_en,
                              fr: item?.shipment_items[0]?.item_name_en,
                            }) ??
                              item?.shipment_items[0]?.prod_name ??
                              ""}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.customer_id === String(state?.user?.id)
                              ? item.is_agree === false
                                ? `Vous avez refusé l'offre de ${item.fullname}`
                                : item.is_agree === true
                                ? `vous avez accepté l’offre de ${item.fullname}`
                                : `${
                                    item.added_by ?? "Someone"
                                  } fait une offre de ${
                                    item.ship_price
                                  }€ à votre annonce`
                              : `${"You"} makes an offer of ${
                                  item.ship_price
                                }€`}
                          </Typography>
                          {item?.customer_id === String(state?.user?.id) ? (
                            <Typography
                              variant="caption"
                              color="primary"
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                              }}
                            >
                              Your Announcement
                            </Typography>
                          ) : null}
                        </CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontFamily: "Raleway-Variable" }}
                        >
                          {moment(item.created_at_tz).format("HH:mm")}
                        </Typography>
                      </Card>
                      <Divider />
                    </>
                  );
                })}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  ></p>
                </div>
                <div>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  ></p>
                </div>
              </div>
              <div
                style={{
                  display: state.chat_details?.id ? "block" : "none",
                }}
              >
                {state?.chat_type === "sender" ? (
                  <>
                    {renderAnnounceCard()}
                    <SenderChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                      handleSwitChat={() => {
                        setState({
                          chat_type: "transporter",
                        });
                      }}
                    />
                  </>
                ) : state?.chat_type === "transporter" ? (
                  <>
                    {renderAnnounceCard()}
                    <AccepterChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                    />
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default SenderInbox;
