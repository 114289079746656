import React, { useEffect } from "react";
import CustomButton from "../../UiComponent/CustomButton";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  TextField,
} from "@mui/material";
import {
  calculateDistance,
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import {
  defaultLabelColors,
  PACKAGE_SHIPMENT_TYPE,
  sizes_values,
} from "../../utils/constants";
import { KeyboardArrowRight } from "@mui/icons-material";
import { LocationPinIcon, MapHomeIcon } from "../../assets/images/FrankIcons";
import Toast from "../../UiComponent/Toast/Toast";
import { strings } from "../../Store/Localization";
import axios from "axios";
import { BASE_URL } from "../../services/HttpProvider";
import apiServices from "../../services/RequestHandler";
import _ from "lodash";
import {  styled } from "@mui/system";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import clapImage from "../../assets/images/FrankIcons/clapsImage.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import Loader from "../../UiComponent/Loader";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
    position: "relative",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

function ReviewPackage(props: any) {
  const { classes,  handleUpadte } = props;
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [selectedValue, setSelectedValue] = React.useState(
    props?.pickup?.deliveryType
  );
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      anounces_datails: props?.items,
      isLoading: false,
      btn_disable: false,
      loadingLoader: false,
    }
  );

  useEffect(() => {}, [selector.lang]);

  useEffect(() => {
    setState({ loadingLoader: true });
    handleCalculateShippingCharges();
    setState({
      anounces_datails: props?.items,
    });
  }, [JSON.stringify(props?.items) ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    setState({
      openJobTypeDialog: false,
    });
  };
  const handleCreateShipment = async () => {
    try {
      setState({
        btn_disable: false,
      });
      if (Number(state.ship_price) < 1 || !state?.ship_price) {
        Toast.Error(strings.minimum_error);
        setState({
          btn_disable: false,
          screen_loading: false,
        });
        return;
      }

      if (Number(state.ship_price) < Number(state.suggested_price?.Minimum)) {
        Toast.Error(strings.recommended_price_error);
        setState({
          btn_disable: false,
          screen_loading: false,
        });
        return;
      }
      if (Number(state.ship_price) >= 10000) {
        Toast.Error("Vous pouvez saisir un montant maximum de");
        setState({
          btn_disable: false,
          screen_loading: false,
        });
        return;
      }
      const { pickup, dropOff } = props;

      let api_payload: any = {
        service_charges: Number(state?.findPlatformCharges?.amount ?? 1.99),
        shipment_cat_id: props?.items?.id ?? 1,
        ship_price: Number(state.ship_price),
        additional_info: props?.items?.additional_info ?? "",
        pickup: {
          location: {
            type: "Point",
            coordinates: pickup?.location?.coordinates,
          },
          address: pickup?.address ?? "",
          digital_code: pickup?.digital_code,
          additional_info: pickup?.additional_info,
          city: pickup?.city,
          country: pickup?.country,
        },
        dropoff: {
          location: {
            type: "Point",
            coordinates: dropOff?.location?.coordinates,
          },
          address: dropOff?.address ?? "",
          digital_code: dropOff?.digital_code,
          additional_info: dropOff?.additional_info,
          city: dropOff?.city,
          country: dropOff?.country,
        },
        recipient_phone_code: dropOff?.phone_code,
        recipient_phone: dropOff?.phone,
        recipient_name: dropOff?.receiptent_name,
        ship_delivery_type: selectedValue,
        channel: "web",
      };

      let shipment_items = [];
      for (let index = 0; index < props?.items?.length; index++) {
        const ship_item = props?.items[index];
        let formData = new FormData();

        for (const img of ship_item?.images ?? []) {
          formData.append("files", img.file);
        }

        api_payload.shipment_cat_id = ship_item?.productSize?.id;

        let obj: any = {
          prod_name: ship_item?.packageType?.label,
          prod_size: ship_item?.productSize?.key,
          prod_weight: ship_item?.productSize?.weight,
          prod_weight_unit: 1,
          prod_length: ship_item?.productLength
            ? Number(ship_item?.productLength)
            : null,
          prod_width: ship_item?.productWidth
            ? Number(ship_item?.productWidth)
            : null,
          prod_height: ship_item?.productHeight
            ? Number(ship_item?.productHeight)
            : null,
          prod_dimension_unit: "cm",
          prod_images: [],
          prod_quantity: ship_item?.prod_quantity,
          shipment_cat_id: ship_item?.productSize?.id,
          prod_desc: ship_item?.productSize?.desc,
          prod_is_fragile: ship_item?.is_fragile,
        };
        if (ship_item?.id) {
          obj.item_list_id = Number(ship_item?.id);
        }

        if (!ship_item?.id) {
          if (selector.lang === "en") {
            obj["item_name_en"] = ship_item?.packageType?.item_name_en;
          } else {
            obj["item_name_fr"] = ship_item?.packageType?.item_name_fr;
          }
        }

        if (ship_item?.images?.length > 0) {
          try {
            const resp = await axios({
              baseURL: BASE_URL,
              headers: {
                "Content-Type": "multipart/form-data", // this is important
              },
              method: "post",
              url: "upload/upload-multiple",
              data: formData,
            });

            let images = resp.data?.data?.map((i: any) => i.uri);

            obj["prod_images"] = images;
          } catch (error) {
            obj["prod_images"] = [];
          }
        }

        shipment_items.push(obj);
      }
      api_payload.shipment_items = shipment_items;

      const resp = await apiServices.postFromApi(
        "/c2c-shipments",
        api_payload,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          openShipmentSuccessDialog: true,
          packageSize: resp?.data?.cat_name,
          btn_disable: false,
          screen_loading: false,
        });
        props?.handleEmptyShipment();
        if (props.handleUpadte) {
          props.handleUpadte("pickup", null);
          props.handleUpadte("dropoff", null);
        }
      } else {
        Toast.Error(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
        setState({
          btn_disable: false,
          screen_loading: false,
        });
      }
      setState({
        btn_disable: false,
        screen_loading: false,
      });
    } catch (error) {
      setState({
        btn_disable: false,
        screen_loading: false,
      });
      console.log("error", error);
    }
  };
  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (!re.test(value) || value[0] === " " || parseFloat(value) < 0) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  const handleFinalAmount = (amountOne: number, amountTwo: number): number => {
    try {
      let totalAmount = Number(amountOne) + Number(amountTwo);

      return totalAmount;
    } catch (error) {
      console.error("Error calculating total amount:", error); // Log the error
      return 0; // Return a default value in case of an error
    }
  };
  const onCheckboxChange = (e: any) => {
    try {
      const { name, checked } = e.target;

      setState({
        [name]: checked,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculateShippingCharges = async () => {
    try {
      const address_distance = calculateDistance(
        props?.pickup?.location?.coordinates[0],
        props?.pickup?.location?.coordinates[1],
        props?.dropOff?.location?.coordinates[0],
        props?.dropOff?.location?.coordinates[1]
      );

      const item_sizes = props?.items?.map((i: any) => {
        return sizes_values[`${i.productSize?.key}`.toLowerCase()];
      });
      console.log("item_sizes", item_sizes);

      const shipping_price = await apiServices.postFromApi(
        "c2c-shipment-prices/get-distance-sizes",
        {
          distance: Number(address_distance),
          sizes: item_sizes,
        },
        ""
      );

      const platform_charges = shipping_price?.platform_charges ?? [];
      const price_resp = shipping_price?.result ?? [];

      const grouped_price = _.groupBy(price_resp, "size_type");

      for (const key in grouped_price) {
        if (Object.prototype.hasOwnProperty.call(grouped_price, key)) {
          const element = grouped_price[key];
          const suggested_price = element.reduce((acc: any, curr: any) => {
            acc[curr.key] =
              curr.key === "Average" ? curr.price_to : curr.price_from;

            return acc;
          }, {});
          if (key === "S") {
            setState({
              ship_price: suggested_price.Recommended,
              suggested_price,
              // platform_fee
            });
          }
          if (key === "M") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
          if (key === "L") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
          if (key === "XL") {
            setState({
              ship_price: `${suggested_price.Recommended}`,
              suggested_price,
            });
          }
        }
      }
      const maxLevel = Math.max(
        ...props?.items?.map((item: any) => item.productSize?.level)
      );

      const maxItem = props?.items?.find(
        (i: any) => i?.productSize?.level === maxLevel
      );

      const item_size_ = maxItem?.productSize?.size;

      const findPlatformCharges = platform_charges?.find((item: any) => {
        // if (state?.details?.productSize[0]?.id === 5) {
        //   return item.size == "Relocation";
        // }
        if (item_size_ === "Small") {
          return item.size === "S";
        }
        if (item_size_ === "Medium") {
          return item.size === "M";
        }
        if (item_size_ === "Large") {
          return item.size === "L";
        }
        if (item_size_ === "Extra Large") {
          return item.size === "XL";
        }
        return;
      });

      setState({
        findPlatformCharges,
      });
      setState({ loadingLoader: false });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="web-screen">
        <Loader loading={state?.loadingLoader} />
        <Grid container spacing={2}>
          <Grid xs={12} item className="display-flex">
            <div
              style={{
                maxWidth: "600px",
                paddingBottom: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
               <div
                style={{
                  height: "100px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  <p style={{ display: "flex" }}>
                    {" "}
                    étape{" "}
                    <span
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        paddingLeft: 5,
                      }}
                    >
                      {" "}
                      3/3{" "}
                    </span>
                  </p>
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "32px",
                  fontWeight: "700",
                  marginBottom:'-10px'
                }}
              >
                Récapitulatif
              </p>
              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "20px 20px 20px 20px",
                  borderRadius: "12px",
                  marginTop: "40px",
                  // width: "500px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 20,
                      flex: 1,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <MapHomeIcon
                      height={30}
                      width={30}
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                  <div style={{ width: "90%", lineHeight: "7px" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {strings?.pickup_address}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                        lineHeight: "22px",
                      }}
                    >
                      {props?.pickup?.address ?? ""}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: "20px ",
                      flex: 1,
                    }}
                    className="display-flex"
                  >
                    <LocationPinIcon
                      height={30}
                      width={30}
                      fill={defaultLabelColors.main_orange}
                    />
                  </div>
                  <div style={{ width: "90%", lineHeight: "7px" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {strings?.dropoff_address}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                        lineHeight: "22px",
                      }}
                    >
                      {props?.dropOff?.address ?? ""}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 20px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "18px" }}>
                  Colis
                </p>
                {props?.items?.map((i: any) => {
                  return (
                    <div className="card-container" style={{ marginTop: 5 }}>
                      <img
                        src={i?.images[0].uri}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {handleLocalize({
                            fr: i?.packageType?.item_name_fr,
                            en: i?.packageType?.item_name_en,
                          }) ?? ""}
                        </div>
                        <div className="card-dimensions">
                          {i?.productSize?.size ?? ""}
                        </div>
                      </div>
                      <div className="card-quantity">
                        x{i?.prod_quantity ?? "1"}
                      </div>
                      {i?.prod_is_fragile ? (
                        <div
                          className="card-quantity"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "500",
                            marginTop: 5,
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>Fragile</span> :
                          Handle With Care
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  cursor: "pointer",
                  display: "flex",
                }}
                onClick={() => {
                  setState({
                    openJobTypeDialog: true,
                  });
                }}
              >
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                    }}
                  >
                    {strings?.type_of_delivery}
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                    }}
                  >
                    {selectedValue ?? "Express - entre 1 à 2 jours"}
                  </p>
                </div>
                <div className="display-flex">
                  <KeyboardArrowRight
                    style={{ fontSize: 32, fontWeight: 700 }}
                  />
                </div>
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Définissez un prix de livraison
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTextField
                    name="ship_price"
                    type="text"
                    onChange={onInputChange}
                    placeholder="ex : 8 "
                    value={`${state.ship_price ?? ""}`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            fontSize: "52px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "53px",
                              position: "absolute",
                              left: "calc(100% - 38%)",
                              color: "#000",
                            }}
                          >
                            €
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <p style={{ fontFamily: "Raleway-Italic", fontSize: "16px" }}>
                  Le prix recommandé par Frank est compris enre
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.suggested_price?.Minimum ?? 0)
                    )}{" "}
                    €
                  </span>{" "}
                  et
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.suggested_price?.Average ?? 0)
                    )}{" "}
                    €
                  </span>
                </p>
              </div>
              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  // width: "500px",
                  lineHeight: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ flex: 1 }}>{strings?.delivery_cost}</div>
                  <div>
                    {numberFormatWithoutCurrency(
                      Number(state?.ship_price ?? 0)
                    )}{" "}
                    €
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <div style={{ flex: 1 }}>{strings?.handling_charges}</div>
                  <div>
                    {numberFormatWithoutCurrency(
                      Number(state?.findPlatformCharges?.amount ?? 0)
                    )}{" "}
                    €
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ flex: 1 }}>{strings?.total}</div>
                  <div>
                    {numberFormatWithoutCurrencyFixed(handleFinalAmount(
                      state?.findPlatformCharges?.amount,
                      state?.ship_price
                    ))}
                    €
                  </div>
                </div>
              </div>

              <div style={{}}>
                <Grid xs={12} item>
                  <FormGroup style={{}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => onCheckboxChange(event)}
                          name="is_accepted"
                          checked={state?.is_accepted ? true : false}
                          size="small"
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          En cliquant sur Publier mon annonce, j'accepte les{" "}
                          <a
                          rel="noopener noreferrer"
                            href="https://frankme.com/terms-and-condition"
                            target="_blank"
                            style={{ color: "#000" }}
                          >
                            {" "}
                            Conditions Générales d’Utilisations
                          </a>
                        </span>
                      }
                    />
                  </FormGroup>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={strings?.back}
                  style={{ width: "150px" }}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  disable={state.btn_disable ? true : !state?.is_accepted}
                  classesNames={
                    !state?.is_accepted
                      ? "outlined_button_Two_disable"
                      : "contained_button"
                  }
                  value={strings?.continue_}
                  style={{ width: "150px" }}
                  onClick={() => {
                    setState({
                      btn_disable: true,
                      screen_loading: true,
                    });

                    handleCreateShipment();
                  }}
                />
              </div>
            </div>
          </Grid>

          <DraggableDialog
            maxWidth={"sm"}
            open={state?.openShipmentSuccessDialog}
            handleClose={() => {
              setState({ openShipmentSuccessDialog: false });
              navigate("/");
            }}
            dialog_title={`Envoie de votre colis - ${state?.packageSize}`}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
                dividers
              >
                <div
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={clapImage}
                    alt="success-img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Retrouver votre annonce dans votre espace personnel
                  </p>
                </div>
              </DialogContent>
            }
            dialogFooter={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                <CustomButton
                  classesNames={"outlined_button"}
                  value={"Publier une  "}
                  style={{ width: "300px" }}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 3);
                    }
                  }}
                />
                <CustomButton
                  classesNames={"contained_button"}
                  value={"Voir mon annonce"}
                  style={{ width: "300px" }}
                  onClick={() => {
                    navigate("/my-anounces");
                  }}
                />
              </div>
            }
          />

          <DraggableDialog
            maxWidth={"sm"}
            open={state?.openJobTypeDialog}
            handleClose={() => {
              setState({ openJobTypeDialog: false });
            }}
            dialog_title={`${strings?.type_of_delivery}`}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                dividers
              >
                <div style={{ width: "100%" }}>
                  {PACKAGE_SHIPMENT_TYPE?.map((i: any) => {
                    return (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            background: "#F4F3F6",
                            padding: 15,
                            fontFamily: "Raleway-Variable",
                            cursor: "pointer",
                            marginTop: 5,
                            border: `2px solid ${
                              selectedValue === i?.en_type
                                ? defaultLabelColors.main_blue
                                : "#f3f3f3"
                            }`,
                          }}
                          onClick={() => {
                            setSelectedValue(i?.en_type);
                            setState({ openJobTypeDialog: false });
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "800",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              {i?.type}

                              {handleLocalize({
                                en: i?.en_type,
                                fr: i?.fr_type,
                              }) ?? ""}
                            </div>
                            <div>
                              <Radio
                                checked={selectedValue === i?.type}
                                onChange={handleChange}
                                value={i?.type}
                                name="type"
                                style={{ color: "#000" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              }}
                            >
                              {i?.data}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </DialogContent>
            }
          />
        </Grid>
      </div>

      <div className="mobile-screen">
        <Grid container spacing={2}>
          <Grid xs={12} item className="display-flex">
            <div
              style={{
                maxWidth: "600px",
                paddingBottom: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >


              
              <h1
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "32px",
                  fontWeight: "700",
                }}
              >
                Récapitulatif
              </h1>
              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "20px 20px 20px 20px",
                  borderRadius: "12px",
                  marginTop: "40px",
                  // width: "500px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 10,
                    }}
                    className="display-flex"
                  >
                    <MapHomeIcon
                      height={30}
                      width={30}
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                  <div style={{ width: "90%", lineHeight: "15px" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {strings?.pickup_address}{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                        lineHeight: "22px",
                      }}
                    >
                      {props?.pickup?.address ?? ""}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 10,
                    }}
                    className="display-flex"
                  >
                    <LocationPinIcon
                      height={35}
                      width={35}
                      fill={defaultLabelColors.main_orange}
                    />
                  </div>
                  <div style={{ width: "90%", lineHeight: "15px" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {strings?.dropoff_address}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                        lineHeight: "22px",
                      }}
                    >
                      {props?.dropOff?.address ?? ""}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 20px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "18px" }}>
                  Colis
                </p>
                {props?.items?.map((i: any) => {
                  return (
                    <div className="card-container" style={{ marginTop: 5 }}>
                      <img
                        src={i?.images[0].uri}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {handleLocalize({
                            fr: i?.packageType?.item_name_fr,
                            en: i?.packageType?.item_name_en,
                          }) ?? ""}
                        </div>
                        <div className="card-dimensions">
                          {i?.productSize?.size ?? ""}
                        </div>
                      </div>
                      <div className="card-quantity">
                        x{i?.prod_quantity ?? "1"}
                      </div>
                      {i?.prod_is_fragile ? (
                        <div
                          className="card-quantity"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "500",
                            marginTop: 5,
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>Fragile</span> :
                          Handle With Care
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  cursor: "pointer",
                  display: "flex",
                  width: "100%",
                }}
                onClick={() => {
                  setState({
                    openJobTypeDialog: true,
                  });
                }}
              >
                <div style={{ flex: 1, width: "85%" }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                    }}
                  >
                    {strings?.type_of_delivery}
                  </p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                    }}
                  >
                    {selectedValue ?? "Express - entre 1 à 2 jours"}
                  </p>
                </div>
                <div className="display-flex" style={{ width: "15%" }}>
                  <KeyboardArrowRight
                    style={{ fontSize: 32, fontWeight: 700 }}
                  />
                </div>
              </div>

              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Définissez un prix de livraison
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTextField
                    name="ship_price"
                    type="number"
                    onChange={onInputChange}
                    placeholder="ex : 8 "
                    value={state.ship_price ?? ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            fontSize: "52px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "53px",
                              position: "absolute",
                              left: "calc(100% - 50%)",
                              color: "#000",
                            }}
                          >
                            €
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Le prix recommandé par Frank est compris enre
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.suggested_price?.Minimum ?? 0)
                    )}{" "}
                    €
                  </span>{" "}
                  et
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.suggested_price?.Average ?? 0)
                    )}{" "}
                    €
                  </span>
                </p>
              </div>
              <div
                style={{
                  background: defaultLabelColors.white,
                  padding: "10px 20px 10px 20px",
                  borderRadius: "12px",
                  marginTop: "10px",
                  // width: "500px",
                  lineHeight: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ flex: 1, width: "82%" }}>
                    {strings?.delivery_cost}
                  </div>
                  <div style={{ width: "18%" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.ship_price ?? 0)
                    )}{" "}
                    €
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <div style={{ flex: 1, width: "85%" }}>{strings?.handling_charges}</div>
                  <div style={{ width: "15%" }}>
                    {numberFormatWithoutCurrency(
                      Number(state?.findPlatformCharges?.amount ?? 0)
                    )}{" "}
                    €
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ flex: 1, width: "85%" }}>{strings?.total}</div>
                  <div style={{ width: "15%" }}>
                    {handleFinalAmount(
                      state?.findPlatformCharges?.amount,
                      state?.ship_price
                    )}
                    €
                  </div>
                </div>
              </div>

              <div style={{}}>
                <Grid xs={12} item>
                  <FormGroup style={{}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => onCheckboxChange(event)}
                          name="is_accepted"
                          checked={state?.is_accepted ? true : false}
                          size="small"
                          style={{ width: "40px" }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            width: "90%",
                          }}
                        >
                          En cliquant sur Publier mon annonce, j'accepte les{" "}
                          <a
                          rel="noopener noreferrer"
                            href="https://frankme.com/terms-and-condition"
                            target="_blank"
                            style={{ color: "#000" }}
                          >
                            Conditions Générales d’Utilisations
                          </a>
                        </span>
                      }
                    />
                  </FormGroup>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={strings?.back}
                  style={{ width: "150px" }}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  disable={state.btn_disable ? true : !state?.is_accepted}
                  classesNames={"contained_button"}
                  value={strings?.continue_}
                  style={{ width: "150px" }}
                  onClick={() => {
                    setState({
                      btn_disable: true,
                      screen_loading: true,
                    });

                    handleCreateShipment();
                  }}
                />
              </div>
            </div>
          </Grid>

          <DraggableDialog
            maxWidth={"sm"}
            open={state?.openShipmentSuccessDialog}
            handleClose={() => {
              setState({ openShipmentSuccessDialog: false });
              navigate("/");
            }}
            dialog_title={`Envoie de votre colis - ${state?.packageSize}`}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
                dividers
              >
                <div
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={clapImage}
                    alt="success-img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Retrouver votre annonce dans votre espace personnel
                  </p>
                </div>
              </DialogContent>
            }
            dialogFooter={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                <CustomButton
                  classesNames={"outlined_button"}
                  value={"Publier une  "}
                  style={{ width: "300px" }}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 3);
                    }
                  }}
                />
                <CustomButton
                  classesNames={"contained_button"}
                  value={"Voir mon annonce"}
                  style={{ width: "300px" }}
                  onClick={() => {
                    navigate("/my-anounces");
                  }}
                />
              </div>
            }
          />

          <DraggableDialog
            maxWidth={"sm"}
            open={state?.openJobTypeDialog}
            handleClose={() => {
              setState({ openJobTypeDialog: false });
            }}
            dialog_title={`${strings?.type_of_delivery}`}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                dividers
              >
                <div style={{ width: "100%" }}>
                  {PACKAGE_SHIPMENT_TYPE?.map((i: any) => {
                    return (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            background: "#F4F3F6",
                            padding: 15,
                            fontFamily: "Raleway-Variable",
                            cursor: "pointer",
                            marginTop: 5,
                            border: `2px solid ${
                              selectedValue === i?.en_type
                                ? defaultLabelColors.main_blue
                                : "#f3f3f3"
                            }`,
                          }}
                          onClick={() => {
                            setSelectedValue(i?.en_type);
                            setState({ openJobTypeDialog: false });
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "800",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              {i?.type}

                              {handleLocalize({
                                en: i?.en_type,
                                fr: i?.fr_type,
                              }) ?? ""}
                            </div>
                            <div>
                              <Radio
                                checked={selectedValue === i?.type}
                                onChange={handleChange}
                                value={i?.type}
                                name="type"
                                style={{ color: "#000" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "500",
                                fontFamily: "Raleway-Italic",
                              }}
                            >
                              {i?.data}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </DialogContent>
            }
          />
        </Grid>
      </div>
    </>
  );
}

export default ReviewPackage;
