// MapForLocations.tsx
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import apiServices from "../../services/RequestHandler";
import MarkerPinIcon from "../../assets/images/FrankIcons/PinMarkerIcon.png";

interface MapForLocationsProps {
  location?: { lat: number; lng: number };
  handleGetLocation?: (address: any) => void;
}

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};

const MapForLocations: React.FC<MapForLocationsProps> = ({
  location,
  handleGetLocation,
}) => {
  const [currentLocation, setCurrentLocation] = useState(location);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    if (location) {
      setCurrentLocation(location);
    }
  }, [location]);
  const onMapPress = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      // Set the marker to the clicked position
      setMarkerPosition({ lat, lng });

      try {
        // Reverse geocode to get the address from the lat/lng
        const resp = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        // Call the callback function with the returned address
        if (handleGetLocation) {
          handleGetLocation(resp);
        }
      } catch (error) {
        console.error("Error fetching the address:", error);
      }
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        borderRadius: "16px",
      }}
      center={currentLocation || { lat: 40.7128, lng: -74.006 }}
      zoom={12}
      options={options}
      onClick={onMapPress}
    >
      {currentLocation && (
        <Marker 
        icon={{
          url: MarkerPinIcon, // Path to your custom marker icon
          scaledSize: new window.google.maps.Size(50, 50), // Scales the marker icon
        }}
        position={currentLocation} /> 
      )}
    </GoogleMap>
  );
};

export default MapForLocations;
