import React, { useEffect, useState } from "react";

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookiesAccepted");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setShowBanner(false);
  };

  const handleCustomize = () => {
    alert(
      "Customize your cookie preferences (this can open a modal or new page)."
    );
  };

  if (!showBanner) {
    return null;
  }
  return (
    <div className="cookie-banner" style={{margin:"4px",borderRadius:'12px',display:'flex',flexDirection:'column',gap:20}} >
      <div style={{width:'100%'}}>
        <p style={{fontFamily:'Raleway-Variable',fontSize:'16px',textAlign:'center'}}>This website uses cookies to ensure you get the best experience. You can
        accept all cookies, reject non-essential cookies, or customize your
        preferences.</p>
      </div>
      <div  >
      <button onClick={handleAccept}>Accept All</button>
      <button onClick={handleReject}>Reject All</button>
      </div>
      {/* <button onClick={handleCustomize}>Customize Preferences</button> */}
    </div>
  );
}

export default CookieBanner;
