import {
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import {
  CameraAlt,
  East,
  LocationOn,
  LockOpen,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Rating } from "react-simple-star-rating";
import InputField from "../../UiComponent/TextFeild";
import FlagInputField from "../../UiComponent/FlagInput";
import CustomButton from "../../UiComponent/CustomButton";
import {
  defaultLabelColors,
  getStatusColors,
  status_translations,
} from "../../utils/constants";
import { RefrestIcon } from "../../assets/images/FrankIcons";
import orangeBackground from "../../assets/images/FrankIcons/orange-background.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import apiServices from "../../services/RequestHandler";
import moment from "moment";
import NewHeader from "../../UiComponent/NewHeader";
import { useNavigate } from "react-router-dom";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { strings } from "../../Store/Localization";
import Toast from "../../UiComponent/Toast/Toast";
import { clearAllDataFromLocalStorage } from "../../utils/LocalStore.util";
import CustomDatePicker from "../../UiComponent/CustomDatePicker";
import dayjs from "dayjs";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  toBase64,
} from "../../utils/helper";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "../MyAnnounces/StripeCheckout";
import { Helmet } from "react-helmet";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { BASE_URL } from "../../services/HttpProvider";
import ProfileMobileView from "./ProfileMobileView";

export const CustomSwitch = styled(Switch)({
  "& .Mui-checked": {
    color: "#ee6931", // Active color
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ee6931", // Track color when checked
  },
});

export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

export const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface VerticalTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function VerticalTabPanel(props: VerticalTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function verticalA11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Profile(props: any) {
  const stripePromise = loadStripe(
    "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
    // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );
  const [verticalTabValue, setVerticalTabValue] = React.useState(0);
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const { classes } = props;
  const [value, setValue] = React.useState(0);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const openForLang = Boolean(anchorEN);
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const handleVerticalTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setVerticalTabValue(newValue);
  };
  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    setSelectedDate(newValue);

    if (newValue) {
      const formatted = newValue.format("YYYY-MM-DD");
      setFormattedDate(formatted);
    }
  };
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      viewPasswordOld: false,
      viewPasswordNew: false,
      viewPasswordConfirm: false,
      loading: true,
    }
  );

  useEffect(() => {
    handleGetProfileInfo();
    handleGetMyEarning();
    handleGetAllDeliveries();
    handleGetAllJobs();
  }, []);

  const handleGetAllDeliveries = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-customer",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          deliveries: resp?.data,
        });
      }
    } catch (error) {}
  };

  const handleGetAllJobs = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-accepter",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          transporterJobs: resp?.data,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {}, [selector.lang]);

  const handlOpenDialogForPassword = () => {
    try {
      setState({ openForgetPasswordDialog: true });
    } catch (error) {}
  };

  const handleChangePassword = async () => {
    try {
      if (!state?.oldPassword) {
        Toast.Error(strings?.enter_o_password);
        return;
      }
      if (!state?.newPassword) {
        Toast.Error(strings?.enter_n_password);
        return;
      }
      if (!state?.confirmPassword) {
        Toast.Error("Please enter confirm password");
        return;
      }
      if (state?.newPassword !== state?.confirmPassword) {
        Toast.Error(strings?.passowrd_not_match);
        return;
      }

      let obj = {
        old_password: state?.oldPassword,
        new_password: state?.newPassword,
        password_confirm: state?.confirmPassword,
      };

      let resp = await apiServices.postFromApi(
        "c2c/change-my-password",
        obj,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess(strings?.password_changed_seccess);
        setState({
          openForgetPasswordDialog: false,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        clearAllDataFromLocalStorage();
        navigate("/login");
      } else {
      }
    } catch (error) {}
  };

  const handleOpenFuturePayment = () => {
    try {
      setState({ openFuturePayment: true });
    } catch (error) {}
  };

  const onInputChangeTwo = (e: any) => {
    try {
      const { value, name } = e.target;

      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleGetProfileInfo = async () => {
    try {
      let resp = await apiServices.getFromApi("c2c/me", "");

      setState({
        ...state,
        userDetails: resp?.data,
        title: resp?.data?.title,
        first_name: resp?.data?.first_name,
        surname: resp?.data?.surname,
        phone_code: resp?.data?.phone_code,
        phone: resp?.data?.phone,
        email: resp?.data?.email,
        date_of_birth: resp?.data?.date_of_birth,
        profile_pic: resp?.data?.profile_pic,
      });
      setSelectedDate(dayjs(resp.data.date_of_birth));
    } catch (error) {}
  };

  const handleUpdateProfile = async () => {
    try {
      if (!state?.first_name) {
        Toast.Error(strings.first_name_error);
        return;
      }
      if (!state?.surname) {
        Toast.Error(strings.surname_error);
        return;
      }

      let obj = {
        title: state?.title,
        first_name: state?.first_name,
        surname: state?.surname,
        profile_pic: state?.profile_pic,
        date_of_birth: formattedDate
          ? formattedDate
          : state?.userDetails?.date_of_birth,
      };

      let resp = await apiServices.patchFromApi("c2c/update-me", obj, "");
      if (resp?.status === 200 || resp.status === 201) {
        Toast.showSuccess("Profile Updated Succesfully!");
        handleGetProfileInfo();
      }
    } catch (error) {}
  };
  const handleGetMyEarning = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c/get-user-earnings", "");
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          earning_data: resp?.data,
        });
      }
    } catch (error) {}
  };

  const handleInitStripeSetupPayment = async () => {
    try {
      const resp = await apiServices.postFromApi(
        "stripe/payment-sheet",
        {},
        ""
      );

      const { setupIntent, ephemeralKey, customer } = resp;
      setState({
        options: {
          setupIntent,
          ephemeralKey,
          customer,
        },
        openFuturePayment: true,
      });
      return {
        setupIntent,
        ephemeralKey,
        customer,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseDialog = () => {
    try {
      setState({ openFuturePayment: false });
    } catch (error) {}
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleLaunchCamera = async (e: any, stateName: string) => {
    try {
      setState((prevState: any) => ({ ...prevState, loading: true }));
      const imageFile = e.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imageFile, options);

      let file = compressedFile || null;
      let uri = (await toBase64(file)) as any;

      if (!uri) {
        return;
      }

      const modifiedFile = new File(
        [file],
        `${stateName}-` + new Date().getTime(),
        { type: file.type }
      );
      let formData = new FormData();

      formData.append("files", modifiedFile);

      const resp = await axios({
        baseURL: BASE_URL,
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        url: "upload/upload-multiple",
        data: formData,
      });

      console.log("img", resp?.data?.data[0]?.uri);
      setState({ profile_pic: resp?.data?.data[0]?.uri });

      setState((prevState: any) => ({
        ...prevState,
        [`${stateName}_obj`]: { modifiedFile, uri },
        [`show_${stateName}`]: true,
        loading: false,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState: any) => ({ ...prevState, loading: false }));
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Helmet>
        <title>Profile | Frank</title>
        <link rel="canonical" href="https://v2internal.frankme.com/profile" />
        <meta name="description" content="" />
      </Helmet>
      <div style={{ background: "#f9c9b3" }} className="web-screen">
        <Grid container spacing={2} item>
          <Grid
            xs={12}
            item
            style={{
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <NewHeader />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{ padding: "30px 50px 30px 50px" }}
        >
          <Grid xs={10} item>
            <Box sx={{ width: "100%" }}>
              <Box>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <CustomTab label="Profil" />
                  <CustomTab label="Porte monnaie" />
                  <CustomTab label="Evaluations" />
                </CustomTabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                  }}
                >
                  <Grid xs={12} item>
                    <h2 className="profile-headings">{strings?.my_profile}</h2>
                  </Grid>

                  <Grid xs={12} md={4} lg={2} item className="display-flex">
                    <div>
                      <Box
                        sx={{
                          position: "relative",
                          width: 175,
                          height: 175,
                          borderRadius: "50%",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: 3,
                          border: "1px solid #ee6931",
                        }}
                      >
                        <img
                          src={
                            state?.profile_pic
                              ? state?.profile_pic
                              : state?.userDetails?.profile_pic ??
                                "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                          }
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />

                        <IconButton
                          onClick={triggerFileInput}
                          aria-label="upload picture"
                          component="span"
                          sx={{
                            zIndex: 111111,
                            position: "absolute",
                            bottom: 5,
                            right: 62,
                            backgroundColor: "white",
                            borderRadius: "50%",
                            boxShadow: 3,
                            "&:hover": {
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        >
                          <CameraAlt sx={{ color: "#3026f1" }} />
                        </IconButton>
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          onChange={(e) => handleLaunchCamera(e, "profile")}
                          style={{ display: "none" }}
                        />
                      </Box>

                      <div
                        style={{
                          padding: "10px 0px 10px 0px",
                          textAlign: "center",
                        }}
                      >
                        <Rating
                          disableFillHover
                          initialValue={state?.userDetails?.rating ?? 0}
                          allowHover={false}
                          size={30}
                          readonly={true}
                        />
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "700",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          0 avis
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    xs={12}
                    md={4}
                    lg={5}
                    item
                    style={{ paddingLeft: "10px" }}
                  >
                    <Grid container spacing={1}>
                      <Grid xs={12} item>
                        {state?.userDetails?.title && (
                          <FormControl
                            size="small"
                            style={{
                              background: "#F4F3F6",
                              width: "100%",
                              borderRadius: "16px",
                              padding: "10px 0px 10px 0px",
                              marginLeft: "10px",
                              fontFamily: "Raleway-Variable",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <RadioGroup
                              style={{ paddingLeft: "20px" }}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="title"
                              value={state?.title} // Controlled value
                              onChange={(e) =>
                                setState({
                                  title: e.target.value, // Update title inside userDetails
                                })
                              }
                            >
                              <FormControlLabel
                                style={{ fontSize: "12px" }}
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                value="Male"
                                label={strings?.male}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "small",
                                  },
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                value="Female"
                                label={strings?.female}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "small",
                                  },
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                value="Others"
                                label={strings?.other}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "small",
                                  },
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Grid>
                      <Grid xs={12} item>
                        <InputField
                          placeHolder={"Prénom"}
                          input_value={state?.first_name}
                          input_name={"first_name"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                          onchange={onInputChangeTwo}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <InputField
                          placeHolder={"Nom"}
                          input_value={state?.surname}
                          input_name={"surname"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                          onchange={onInputChangeTwo}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <CustomButton
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#fff",
                            fontFamily: "Raleway-Variable",
                            background: "#ee6931",
                            border: "none",
                            borderRadius: "12px",
                            padding: "10px 40px 10px 40px",
                            marginLeft: "10px",
                          }}
                          value={"Enregistrer"}
                          onClick={handleUpdateProfile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4} lg={5} item>
                    <Grid container spacing={1}>
                      <Grid xs={12} item style={{ marginLeft: "10px" }}>
                        <FlagInputField
                          divStyle={{
                            background: "#F4F3F6",
                            borderRadius: "16px",
                            border: "none",
                            padding: "8px",
                            width: "98%",
                          }}
                          isDisabled
                          classes={classes}
                          inputStyle={{ background: "#F4F3F6" }}
                          required={false}
                          name={"phone"}
                          codeName={"phone_code"}
                          value={state?.phone || ""}
                          countryCode={state?.phone_code || "+92"}
                          handleChangeMobileCode={(dialcode, codeName) =>
                            handleChangeMobileCode(dialcode, codeName)
                          }
                          handleChangeMobile={(event) =>
                            handleChangeMobile(event)
                          }
                          enableFlagInput={enableFlagInput}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <InputField
                          placeHolder={"example@gmail.com"}
                          input_value={state?.email}
                          input_name={"email"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                          readonly
                          // onchange={onInputChange}
                        />
                      </Grid>
                      <Grid xs={12} item style={{ paddingLeft: "20px" }}>
                        {/* <InputField
                          placeHolder={"31/01/2001"}
                          input_value={moment(state?.date_of_birth).format(
                            "DD-MMM-YYYY"
                          )}
                          input_name={"date_of-birth"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                          // onchange={onInputChange}
                        /> */}
                        <CustomDatePicker
                          onChange={handleDateChange}
                          value={selectedDate}
                          disableFuture={true}
                          fontFamily="Raleway-Italic"
                          backgroundColor="#F4F3F6"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Grid xs={12} item>
                    <h2 className="profile-headings">
                      Vérification d’identité
                    </h2>
                  </Grid>

                  <Grid xs={12} item>
                    <p className="profile-text">
                      Lorem ipsum dolor sit amet consectetur. Tincidunt vitae at
                      aliquam metus mattis. Integer lectus aenean purus pretium
                      pellentesque sed.
                    </p>
                    <div>
                      <p className="profile-text">
                        1. Lacus mattis placerat sapien nulla pulvinar a et
                        massa. Est consectetur donec id quisque est facilisis
                        quis massa.
                      </p>
                      <p className="profile-text">
                        2. Lacus mattis placerat sapien nulla pulvinar a et
                        massa. Est consectetur donec id quisque est facilisis
                        quis massa.
                      </p>
                      <p className="profile-text">
                        3. Lacus mattis placerat sapien nulla pulvinar a et
                        massa. Est consectetur donec id quisque est facilisis
                        quis massa.
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Grid xs={12} item>
                    <h2 className="profile-headings">{strings?.settings}</h2>
                  </Grid>

                  <Grid xs={12} item>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <h2 className="profile-text">{strings?.account}</h2>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontWeight: "600",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setState({ openForgetPasswordDialog: true });
                          }}
                        >
                          {strings?.change_password}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.notification}</p>
                      </div>
                      <div>
                        <CustomSwitch />
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.payments}</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontWeight: "600",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={handleInitStripeSetupPayment}
                        >
                          {strings?.view}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.language}</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontWeight: "600",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={handleClickForLang}
                        >
                          Changer
                        </p>
                      </div>
                      <Menu
                        id="account-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEN}
                        open={openForLang}
                        onClose={handleCloseForLang}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            // width: "200px",
                            borderRadius: "12px",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("fr"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={frIcon}
                              height={30}
                              width={30}
                              alt="fr-icon"
                            />
                            FR
                          </div>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("en"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={enIcon}
                              height={20}
                              width={30}
                              alt="en-icon"
                            />
                            EN
                          </div>
                        </MenuItem>
                      </Menu>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">Conditions générales</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontWeight: "600",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "https://frankme.com/terms-and-condition"
                            );
                          }}
                        >
                          {strings?.view}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid
                  container
                  spacing={1}
                  style={{ columnGap: "50px", height: "100vh" }}
                >
                  <Grid
                    xs={7}
                    item
                    style={{
                      background: "#fff",
                      borderRadius: "16px",
                      padding: "20px",
                      // marginRight:'10px'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12} item>
                        <h2
                          className="profile-headings"
                          style={{ fontSize: "24px" }}
                        >
                          Transactions
                        </h2>
                      </Grid>

                      {state?.earning_data?.map((i: any, index: any) => {
                        return (
                          <>
                            <Grid
                              xs={12}
                              item
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                                margin: "0px 20px 0px 20px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                  <p
                                    className="profile-headings"
                                    style={{ fontSize: "18px" }}
                                  >
                                    {" "}
                                    {numberFormatWithoutCurrency(
                                      i?.order_amount_detail?.sub_total
                                    ) ?? "0.00"}{" "}
                                    &nbsp; €
                                  </p>
                                  <p className="profile-text">
                                    {" "}
                                    {moment(i?.exp_dropoff_date).format(
                                      "DD/MM/YYYY"
                                    ) ?? "10/02/22"}
                                  </p>
                                </div>
                                <div className="display-flex">
                                  <span
                                    style={{
                                      padding: "10px 15px 10px 15px",
                                      borderRadius: "12px",
                                      background:
                                        i?.ship_status !== "Deliver"
                                          ? "#BBF4D0"
                                          : "#FBACAC",
                                      color:
                                        i?.ship_status !== "Deliver"
                                          ? "darkgreen"
                                          : "#B91C1C",
                                      textAlign: "center",
                                      fontFamily: "Raleway-Variable",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {i?.ship_status !== "Deliver"
                                      ? "Unpaid"
                                      : "Unpaid"}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </>
                        );
                      })}

                      {state?.earning_data?.length > 6 ? (
                        <Grid xs={12} item className="display-flex">
                          <p
                            className="profile-headings display-flex"
                            style={{
                              color: "#3026f1",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            <RefrestIcon /> Charger plus de transactions
                          </p>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    xs={4}
                    item
                    style={{
                      padding: "20px",
                      background: "#fff",
                      borderRadius: "16px",
                      height: "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12} item>
                        <h2
                          className="profile-headings"
                          style={{ fontSize: "24px" }}
                        >
                          {strings?.your_wallet}
                        </h2>
                      </Grid>

                      <Grid xs={12} item>
                        <div
                          style={{
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "30px 60px 30px 60px",
                            height: "",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "42px",
                              fontWeight: "800",
                              fontFamily: "Raleway-Variable",
                              textAlign: "center",
                            }}
                          >
                            0.<span style={{ fontWeight: "600" }}> 00</span>{" "}
                            <span style={{ color: "grey", fontWeight: "600" }}>
                              €
                            </span>
                          </p>
                        </div>
                      </Grid>
                      <Grid xs={12} item>
                        <div
                          style={{
                            paddingTop: "40px",
                            position: "relative",
                            zIndex: 111,
                            flexDirection: "column",
                          }}
                          className="display-flex"
                        >
                          <img
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "220px",
                            }}
                            src={orangeBackground}
                            alt=""
                          />
                          <p
                            style={{
                              fontSize: "22px",
                              fontWeight: "800",
                              fontFamily: "Raleway-Variable",
                              color: "#fff",
                              zIndex: 111,
                            }}
                          >
                            Merci Frank
                          </p>

                          <CustomButton
                            style={{
                              background: "#fff",
                              color: "#ee6931",
                              fontSize: "16px",
                              fontFamily: "Raleway-Variable",
                              border: "none",
                              borderRadius: "12px",
                              // padding: "10px 10px 10px 10px",
                              width: "250px",
                            }}
                            value={"Transférer vers un compte bancaire"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container>
                  <Grid
                    xs={12}
                    md={4}
                    item
                    style={{
                      background: "#fff",
                      borderRadius: "16px",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: 224,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={verticalTabValue}
                        onChange={handleVerticalTabChange}
                        aria-label="Vertical profile tabs"
                        TabIndicatorProps={{ style: { display: "none" } }}
                        sx={{
                          borderRight: 1,
                          borderColor: "divider",
                          width: "100%",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "600",
                            fontFamily: "Raleway, sans-serif",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                              fontFamily: "Raleway-Variable",
                            },
                          }}
                          label="Envoyer un colis  ·  Mes annonces "
                          {...verticalA11yProps(0)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",

                            fontFamily: "Raleway, sans-serif",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Transporter un colis  ·  Mes trajets "
                          {...verticalA11yProps(1)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "600",
                            fontFamily: "Raleway, sans-serif",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Marketplace  ·  Mes achats"
                          {...verticalA11yProps(2)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: "600",
                            fontFamily: "Raleway, sans-serif",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Marketplace  ·  Mes ventes"
                          {...verticalA11yProps(3)}
                        />
                      </Tabs>
                    </Box>
                  </Grid>

                  <Grid
                    xs={12}
                    md={7}
                    item
                    style={{
                      background: "#fff",
                      borderRadius: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    <VerticalTabPanel value={verticalTabValue} index={0}>
                      <div style={{}}>
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                            fontWeight: "700",
                          }}
                        >
                          Envoyer un colis · Mes annonces
                        </div>

                        {state?.deliveries?.slice(0, 5).map((i: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: " #fff",
                                  borderRadius: "16px",
                                  padding: "8px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                <img
                                  src={i?.shipment_items[0]?.prod_images[0]}
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    borderRadius: "8px",
                                    marginRight: "16px",
                                    objectFit: "contain",
                                  }}
                                  alt="prod-img"
                                />
                                <div className="card-details">
                                  <div
                                    className="card-title"
                                    style={{ fontSize: 17 }}
                                  >
                                    {handleLocalize({
                                      fr: i?.shipment_items[0]?.item_name_fr,
                                      en: i?.shipment_items[0]?.item_name_en,
                                    }) ??
                                      i?.shipment_items[0]?.prod_name ??
                                      ""}{" "}
                                    -{" "}
                                    {i?.agree_ship_price ??
                                      i?.ship_price ??
                                      "0"}{" "}
                                    €
                                  </div>
                                  <div className="card-dimensions">
                                    {moment(i?.created_at).format(
                                      "DD/MM/YYYY"
                                    ) ?? ""}
                                  </div>
                                  <div
                                    className="card-dimensions"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      De &nbsp;
                                      {i?.dropoff?.city ?? "city"}
                                    </div>
                                    - &nbsp;
                                    <div>
                                      à &nbsp;
                                      {i?.pickup?.city}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderRadius: "8px",
                                    padding: "5px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    background:
                                      getStatusColors[i?.ship_status].bg,
                                    color: getStatusColors[i?.ship_status].txt,
                                  }}
                                >
                                  {handleLocalize(
                                    status_translations[i?.ship_status]
                                  )}
                                </div>
                              </div>

                              <div
                                style={{
                                  height: "1px",
                                  background: "lightgrey",
                                }}
                              ></div>
                            </>
                          );
                        })}

                        {state?.deliveries?.length < 6 ||
                        state?.deliveries?.length < 1 ? null : (
                          <div
                            style={{
                              textAlign: "center",
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_blue,
                              fontSize: "18px",
                              fontWeight: "600",
                              // padding: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/my-anounces");
                            }}
                          >
                            <RefrestIcon fill={defaultLabelColors.main_blue} />{" "}
                            Charger plus de transactions
                          </div>
                        )}
                      </div>
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={1}>
                      <div style={{}}>
                        <div
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                            fontWeight: "700",
                          }}
                        >
                          Transporter un colis · Mes trajets
                        </div>

                        {state?.transporterJobs?.slice(0, 5).map((i: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: " #fff",
                                  borderRadius: "16px",
                                  padding: "8px",
                                  fontFamily: "Raleway-Variable",
                                  cursor:'pointer'
                                }}
                                onClick={() => {
                                  navigate(`/job/${i?.id}`);
                                }}
                              >
                                <img
                                  src={i?.shipment_items[0]?.prod_images[0]}
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    borderRadius: "8px",
                                    marginRight: "16px",
                                    objectFit: "contain",
                                  }}
                                  alt="prod-img"
                                />
                                <div className="card-details">
                                  <div
                                    className="card-title"
                                    style={{ fontSize: 17 }}
                                  >
                                    {handleLocalize({
                                      fr: i?.shipment_items[0]?.item_name_fr,
                                      en: i?.shipment_items[0]?.item_name_en,
                                    }) ??
                                      i?.shipment_items[0]?.prod_name ??
                                      ""}{" "}
                                    -{" "}
                                    {i?.agree_ship_price ??
                                      i?.ship_price ??
                                      "0"}{" "}
                                    €
                                  </div>
                                  <div className="card-dimensions">
                                    {moment(i?.created_at).format(
                                      "DD/MM/YYYY"
                                    ) ?? ""}
                                  </div>
                                  <div
                                    className="card-dimensions"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      De &nbsp;
                                      {i?.dropoff?.city ?? "city"}
                                    </div>
                                    - &nbsp;
                                    <div>
                                      à &nbsp;
                                      {i?.pickup?.city}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderRadius: "8px",
                                    padding: "5px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    background:
                                      getStatusColors[i?.ship_status].bg,
                                    color: getStatusColors[i?.ship_status].txt,
                                  }}
                                >
                                  {handleLocalize(
                                    status_translations[i?.ship_status]
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: "1px",
                                  background: "lightgrey",
                                }}
                              ></div>
                            </>
                          );
                        })}
                        {state?.transporterJobs?.length < 6 ||
                        state?.transporterJobs?.length < 1 ? null : (
                          <div
                            style={{
                              textAlign: "center",
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_blue,
                              fontSize: "18px",
                              fontWeight: "600",
                              // padding: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                            onClick={() => {
                              navigate("/my-jobs");
                            }}
                          >
                            <RefrestIcon fill={defaultLabelColors.main_blue} />{" "}
                            Charger plus de transactions
                          </div>
                        )}
                      </div>
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={2}>
                      Profile content
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={3}>
                      Settings content
                    </VerticalTabPanel>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
        <DraggableDialog
          maxWidth={"md"}
          open={state?.openForgetPasswordDialog}
          handleClose={() => {
            setState({ openForgetPasswordDialog: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
                justifyContent: "center",
              }}
              dividers
            >
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  style={{ flexDirection: "column" }}
                >
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    {strings.change_password}
                  </p>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                    }}
                  ></p>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordOld === false ? "password" : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.old_password}
                      input_value={state?.oldPassword}
                      input_name={"oldPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordOld ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordOld: !state?.viewPasswordOld,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordOld: !state?.viewPasswordOld,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordNew === false ? "password" : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.set_new_password}
                      input_value={state?.newPassword}
                      input_name={"newPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordNew ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordNew: !state?.viewPasswordNew,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordNew: !state?.viewPasswordNew,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordConfirm === false
                          ? "password"
                          : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.confirm_password}
                      input_value={state?.confirmPassword}
                      input_name={"confirmPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordConfirm ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordConfirm:
                                  !state?.viewPasswordConfirm,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordConfirm:
                                  !state?.viewPasswordConfirm,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>

                  <Grid xs={12} item style={{ padding: "20px" }}>
                    <CustomButton
                      style={{
                        width: "260px",
                      }}
                      onClick={handleChangePassword}
                      classesNames={"contained_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "10px",
                              padding: "8px 0px 8px 0px",
                              fontSize: "16px",
                            }}
                          >
                            {strings?.continue}
                            <East
                              style={{
                                color: defaultLabelColors.main_blue,
                                background: "#f9d9b6",
                                borderRadius: 100,
                                padding: 4,
                                fontSize: 18,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
          }
        />
        <DraggableDialog
          maxWidth={"md"}
          open={state?.openFuturePayment}
          handleClose={() => {
            setState({ openFuturePayment: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
                justifyContent: "center",
              }}
              dividers
            >
              <Grid
                container
                spacing={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: state.options?.setupIntent,
                    customerOptions: {
                      customer: state.options?.customer,
                      ephemeralKey: state.options?.ephemeralKey,
                    },
                  }}
                >
                  <StripeCheckout handleCloseDialog={handleCloseDialog} />
                </Elements>
              </Grid>
            </DialogContent>
          }
        />
      </div>
      <div
        className="mobile-screen"
        style={{ width: "100%", paddingRight: 10 }}
      >
        <ProfileMobileView
          {...props}
          {...state}
          selectedDate={selectedDate}
          formattedDate={formattedDate}
          anchorEN={anchorEN}
          onInputChangeTwo={onInputChangeTwo}
          handleChangePassword={handleChangePassword}
          handleInitStripeSetupPayment={handleInitStripeSetupPayment}
          handleDateChange={handleDateChange}
          setSelectedDate={setSelectedDate}
          setFormattedDate={setFormattedDate}
          handleUpdateProfile={handleUpdateProfile}
          handlOpenDialogForPassword={handlOpenDialogForPassword}
          handleOpenFuturePayment={handleOpenFuturePayment}
          openForLang={openForLang}
          handleCloseForLang={handleCloseForLang}
        />
      </div>
    </>
  );
}

export default Profile;
