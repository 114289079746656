import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState } from "react";
import apiServices from "../../services/RequestHandler";
import { defaultLabelColors } from "../../utils/constants";
import DraggableDialog from "../DraggableDialog";
import {
  Box,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { MapHomeIcon, MapPointerIcon } from "../../assets/images/FrankIcons";
import InputField from "../TextFeild";
import { Send } from "@mui/icons-material";
import CustomButton from "../CustomButton";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import Toast from "../Toast/Toast";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { styled } from "@mui/system";
import { strings } from "../../Store/Localization";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
    width: "90px",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

const containerStyle = {
  width: "100%",
  borderRadius: "32px",
  height: "100%",
  maxHeight: "100%",
};

const options = {
  disableDefaultUI: true, // Disables all default controls
  zoomControl: true, // Enables only the zoom control
  mapTypeControl: false, // Disables the map type control
  streetViewControl: false, // Disables street view control
  fullscreenControl: false, // Disables fullscreen control
};

const center = {
  lat: 48.857244326721165,
  lng: 2.3473757074911936,
};

interface GoogleMapProps {
  handleGetLocation: (data: any) => void;
  markers_data?: Array<any>;
}

function GoogleMaps(props: GoogleMapProps) {

   
  const [address, setAddress] = useState(null);
  const { handleGetLocation, markers_data } = props;
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openJobDialog: false,
    }
  );

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;
  
      const re = /^\d*\.?\d{0,2}$/;
  
      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }
  
      if (!re.test(value) || value[0] === " " || parseFloat(value) < 0) {
        return;
      }
  
      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }
  
      if (parseFloat(value) > 10000) {
        return;
      }
  
      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
      };
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        setState({ openJobDialog: false });
      }
    } catch (error) {}
  };


 const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Please enter valid amount");
      return;
    }
    handleSentFirstMessage();
  };

  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: `` ,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message:
        `${name} has sent you a message. neg_amt=${
          amount ?? state?.negotiated_price
        }` ,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      return;
    }

    let obj = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      if (resp.status === 201) {
       
        setState({ messagingScreen: true });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
        // item_sizes.globalErrorMessage(selector.lang),
      );
    }
  };


  const onMapPress = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      try {
        let resp = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        setMarkerPosition({
          lat,
          lng,
        });
        setAddress(resp);

        if (handleGetLocation) {
          handleGetLocation(resp);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleGetJobInfo = async (item: any) => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.id}`,
        ""
      );

      const accepter_details = resp?.data?.requester?.filter(
        (i: any) => i?.accepter_id === user?.id
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() < new Date(b?.created_at).getTime()
          ? -1
          : 1
      );

      if (accepter_details?.[0]?.is_agree === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }

      setState({
        jobDetails: resp?.data,
        openJobDialog: true,
      });
    } catch (error) {}
  };
  const CustomMarker = ({ item }: any) => {
    return (
      <div
        className="speech-bubble"
        style={{
          backgroundColor: "white",
          borderRadius: "16px",
          width: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "15px",
        }}
        onClick={() => {
          handleGetJobInfo(item);
        }}
      >
        <div style={{ flex: 1 }}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 7.27771L12 11.9999M12 11.9999L3.49997 7.27771M12 11.9999L12 21.5M21 16.0585V7.94141C21 7.59876 21 7.42744 20.9495 7.27464C20.9049 7.13947 20.8318 7.01539 20.7354 6.9107C20.6263 6.79236 20.4766 6.70916 20.177 6.54276L12.777 2.43164C12.4934 2.27409 12.3516 2.19531 12.2015 2.16442C12.0685 2.13709 11.9315 2.13709 11.7986 2.16442C11.6484 2.19531 11.5066 2.27409 11.223 2.43165L3.82297 6.54276C3.52345 6.70916 3.37369 6.79236 3.26463 6.9107C3.16816 7.01539 3.09515 7.13947 3.05048 7.27465C3 7.42745 3 7.59877 3 7.94141V16.0585C3 16.4012 3 16.5725 3.05048 16.7253C3.09515 16.8605 3.16816 16.9845 3.26463 17.0892C3.37369 17.2076 3.52345 17.2908 3.82297 17.4572L11.223 21.5683C11.5066 21.7258 11.6484 21.8046 11.7986 21.8355C11.9315 21.8628 12.0685 21.8628 12.2015 21.8355C12.3516 21.8046 12.4934 21.7258 12.777 21.5683L20.177 17.4572C20.4766 17.2908 20.6263 17.2076 20.7354 17.0892C20.8318 16.9845 20.9049 16.8605 20.9495 16.7253C21 16.5725 21 16.4012 21 16.0585Z"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 9.5L7.5 4.5"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          style={{
            fontSize: "22px",
            fontWeight: "800",
            fontFamily: "Raleway-Italic",
            color: defaultLabelColors.main_blue,
          }}
        >
          {item?.ship_price}€
        </div>
      </div>
    );
  };

  return  (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        options={options}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
        onClick={(e) => onMapPress(e)}
      >
        {markers_data?.map((marker: any, index: number) => {
          const pickup = marker?.pickup?.location ?? {};
          return (
            <>
              <OverlayView
                position={{
                  lat: pickup.coordinates[0],
                  lng: pickup.coordinates[1],
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <CustomMarker item={marker} />
              </OverlayView>
            </>
          );
        })}
        {state.show_window && (
          <InfoWindow
            position={{
              lat: state.selected_marker?.pickup?.coordinates?.[0],
              lng: state.selected_marker?.pickup?.coordinates?.[1],
            }}
            onCloseClick={() =>
              setState({ show_window: false, selected_marker: null })
            }
          >
            <div
              style={{
                height: 100,
                width: 100,
                borderRadius: 16,
                background: "cyan",
              }}
            >
              <h1> </h1>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <DraggableDialog
        maxWidth={`${state?.negotiate ? "sm" : "md"}`}
        open={state?.openJobDialog}
        handleClose={() => {
          setState({
            openJobDialog: false,
            messagingScreen: false,
            negotiate: false,
            disableNegotiate: false,
            accepter_details: [],
          });
        }}
        dialog_title={"Transporter un colis"}
        dialogBody={
          <DialogContent style={{}} dividers>
            {state?.negotiate ? (
              state?.messagingScreen ? (
                <>
                  <div
                    className="card-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "92%",
                      padding: "20px",
                      height: "60vh",
                    }}
                  >
                    <div style={{ flex: 1, width: "100%", gap: 20 }}>
                      <div
                        style={{
                          background: "#eef2f6",
                          borderRadius: "16px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          marginTop: "10px",
                          width: "90%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            borderBottom: "1px solid #3026f1",
                          }}
                        >
                          <p
                            style={{
                              background: "#f9c9b3",
                              borderRadius: "32px",
                              padding: "10px 15px",
                            }}
                          >
                            <MapHomeIcon
                              fill={defaultLabelColors.main_blue}
                              height={20}
                              width={20}
                            />
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Depart
                            </span>
                            <span
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontWeight: "700",
                                wordBreak: "break-all",
                                fontSize: "15px",
                              }}
                            >
                              {" "}
                              {state?.jobDetails?.pickup?.city ?? "City"},
                              {state?.jobDetails?.pickup?.country ?? "City"}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <p
                            style={{
                              background: "#f9c9b3",
                              borderRadius: "32px",
                              padding: "10px 15px",
                            }}
                          >
                            <MapPointerIcon
                              fill={defaultLabelColors.main_blue}
                            />
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "16px",
                            }}
                          >
                            Destination
                            <br />
                            <span
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontWeight: "700",
                                wordBreak: "break-all",
                                fontSize: "15px",
                              }}
                            >
                              {" "}
                              {state?.jobDetails?.dropoff?.city ?? "City"},
                              {state?.jobDetails.dropoff?.country ?? "City"}
                            </span>
                          </p>
                        </div>

                        <div
                          style={{
                            borderTop: "1px solid lightgrey",
                            padding: "10px",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "18px",
                              fontWeight: 600,
                              flex: 1,
                            }}
                          >
                            Frais de livraison
                          </div>
                          <div
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "18px",
                              fontWeight: 600,
                            }}
                          >
                            {numberFormatWithoutCurrency(
                              state?.jobDetails?.ship_price
                            )}{" "}
                            €
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          background: "#DCDBFB",
                          borderRadius: "16px",
                          width: "94%",
                          padding: "10px",
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          marginTop: "20px",
                          fontSize: "16px",
                        }}
                      >
                        Vous avez fait une offre pour une montant de :{" "}
                        {numberFormatWithoutCurrency(state?.negotiated_price)} €
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={"outlined_button"}
                      value={"Retour"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={() => {
                        setState({ negotiate: false });
                      }}
                    />
                    <CustomButton
                      classesNames={"contained_button"}
                      value={"Terminer"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={() => {
                        setState({
                          openJobDialog: false,
                          messagingScreen: false,
                          negotiate: false,
                          disableNegotiate: false,
                          accepter_details: [],
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <div>
                    <div className="card-container">
                      <img
                        src={
                          state?.jobDetails?.shipment_items[0]?.prod_images[0]
                        }
                        className="card-image"
                        alt="prod_img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 20 }}>
                          {handleLocalize({
                            en: state?.jobDetails?.shipment_items[0]
                              ?.item_name_en,
                            fr: state?.jobDetails?.shipment_items[0]
                              ?.item_name_fr,
                          }) ??
                            state?.jobDetails?.shipment_items[0]?.prod_name ??
                            ""}
                        </div>

                        <div
                          className="card-quantity"
                          style={{
                            color: defaultLabelColors.main_orange,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#080626",
                              font: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            Demande négociation :{" "}
                          </p>
                          <span
                            style={{
                              fontSize: "26px",
                              color: defaultLabelColors.main_orange,
                              fontWeight: 600,
                              paddingLeft: "10px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.ship_price} €
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "1px",
                      marginTop: "20px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "32px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Insérez le montant de votre offre
                    </p>
                   
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      cette limite permet aux membres de prendre le temps de les
                      passer en revue
                    </p>
                    <div className="display-flex">
                      <StyledTextField
                        name="negotiated_price"
                        onChange={onInputChange}
                        type="text"
                        placeholder="ex : 8 "
                        value={state.negotiated_price ?? ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{
                                fontSize: "52px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "700",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "53px",
                                  position: "absolute",
                                  left: "calc(100% - 42%)",
                                }}
                              >
                                €
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={"outlined_button"}
                      value={"Annuler"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "100%" }}
                      onClick={() => {
                        setState({ negotiate: false });
                      }}
                    />
                    <CustomButton
                      classesNames={"contained_button"}
                      value={"Négocier"}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "100%" }}
                      onClick={handleNegotiate}
                    />
                  </div>
                </div>
              )
            ) : (
              <>
                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: 20,
                    gap: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Détail de l’expéditeur
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Membre depuis &nbsp;
                      <span style={{ fontWeight: "600" }}>
                        {moment(
                          state?.jobDetails?.user_details?.created_at
                        ).format("YYYY") ?? "recently"}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "15%",
                        paddingTop: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: 100,
                          height: 100,
                          borderRadius: 100,
                          overflow: "hidden",
                          display: "flex",

                          boxShadow: 3,
                        }}
                      >
                        <img
                          src={
                            state?.jobDetails?.user_details?.acc_phone ??
                            "https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp"
                          } // replace with your image path
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            fontWeight: 600,
                          }}
                        >
                          {state?.jobDetails?.user_details?.cus_name ??
                            "Customer Name"}
                        </p>
                        <Rating
                          disableFillHover
                          initialValue={
                            state?.jobDetails?.user_details?.cus_ratings ?? 2
                          }
                          allowHover={false}
                          readonly={true}
                          size={40}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Type de livraison
                  </p>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                        fontSize: "24px",
                      }}
                    >
                      {state?.jobDetails?.ship_delivery_type ?? "Express"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Annonce créée le{" "}
                      {moment(state?.jobDetails?.created_at).format(
                        "DD/MMM/YYYY"
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                    padding: "0px 20px 20px 20px",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Products & dimension
                  </p>

                  <div>
                    {state?.jobDetails?.shipment_items?.map((i: any) => {
                      return (
                        <div className="card-container">
                          <img src={i?.prod_images[0]} className="card-image" alt="prod_img"/>
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {handleLocalize({
                                en: i?.item_name_en,
                                fr: i?.item_name_fr,
                              }) ??
                                i?.prod_name ??
                                ""}
                            </div>
                            <div className="card-dimensions">
                              {i?.prod_weight ?? ""}
                            </div>
                            <div className="card-quantity">
                              x{i?.prod_quantity}
                            </div>
                            {i?.prod_is_fragile ? <div className="card-quantity" style={{fontSize:'14px',fontFamily:"Raleway-Variable",fontWeight:'500',marginTop:5}}>
                                <span style={{fontWeight:600}}>Fragile</span> : Handle With Care
                              </div> : null}
                          </div>
                          <div
                            className="card-quantity"
                            style={{ paddingRight: "20px" }}
                          >
                            {i?.cat_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {state?.accepter_details?.[0]?.is_agree === null ? (
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        Frais de livraison négociés
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          color: "grey",
                          gap: 20,
                        }}
                      >
                        {numberFormatWithoutCurrency(
                          state?.jobDetails?.ship_price
                        )}{" "}
                        €
                        <span style={{ color: defaultLabelColors.main_blue }}>
                          {numberFormatWithoutCurrency(
                            state?.accepter_details[0]?.ship_price
                          )}{" "}
                          €
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "20px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        {"Frais de livraison"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          color: defaultLabelColors.main_blue,
                        }}
                      >
                        {numberFormatWithoutCurrency(
                          state?.jobDetails?.ship_price
                        )}{" "}
                        €
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    gap: "10px",
                    paddingTop: "20px",
                  }}
                >
                  <CustomButton
                    classesNames={
                      state?.disableNegotiate
                        ? "outlined_button_disable"
                        : "outlined_button"
                    }
                    value={strings?.negotiate}
                    disable={state?.disableNegotiate}
                    style={{ width: "200px" }}
                    onClick={() => {
                      setState({ negotiate: true });
                    }}
                  />
                  <CustomButton
                    classesNames={"contained_button"}
                    value={strings?.accept}
                    // disable={state?.items?.length > 0 ? true : false}
                    style={{ width: "200px" }}
                    onClick={handleAcceptJob}
                  />
                </div>
              </>
            )}
          </DialogContent>
        }
      />
    </>
  ) ;
}
export default GoogleMaps;
